import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  effect,
  forwardRef,
  inject,
  input,
  NgZone,
  TemplateRef,
  ViewChild
} from '@angular/core';

import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {Entity, Event} from '@cesium/engine';
import {toSignal} from '@angular/core/rxjs-interop';
import {InfoboxService} from '../infobox.service';
import {InfoboxComponent} from "../infobox/infobox.component";

/**
 * InfoboxContainerComponent is a component that displays infoboxes for entities in a Cesium viewer.
 *
 * @selector lib-infobox-container
 *
 * @example
 * <lib-infobox-container></lib-infobox-container>
 *
 * @export
 * @class InfoboxContainerComponent
 * @extends {AXCesiumWidget}
 */
@Component({
  selector: 'lib-infobox-container',
  templateUrl: './infobox-container.component.html',
  styleUrls: ['./infobox-container.component.css'],
  providers: [
    {provide: AXCesiumWidget, useExisting: forwardRef(() => InfoboxContainerComponent)}
  ],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    InfoboxComponent
  ],
})
export class InfoboxContainerComponent extends AXCesiumWidget {

  enableEntityPinning = input<boolean>(false);
  enableLiveUpdates = input<boolean>(false);
  enableEntityTracking = input<boolean>(false);
  infoBoxService = inject(InfoboxService, {optional: true});

  @ViewChild('widgetTemplate') widgetTemplate: TemplateRef<any> | undefined;
  private cesiumService = inject(CesiumService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private ngZone = inject(NgZone);
  private viewer$ = toSignal(this.cesiumService.watchViewerInit());

  /**
   * This is a sort of stack of entities that are pinned. It is used to retain the entities that are pinned when the user selects a new entity.
   * The currently selected entity is always the last element in the array. If no entity is selected, the last element is undefined.
   */
  displayedEntities: (Entity | undefined)[] = [
    undefined
  ];

  private removeCallback: Event.RemoveCallback | null = null;

  constructor() {
    super();
    effect(() => {
      if (!this.infoBoxService) {
        return;
      }
      const _infoboxes = this.infoBoxService.infoboxes$();
      this.changeDetectorRef.markForCheck();
    });
    effect(() => {
      const viewer = this.viewer$();
      if (this.removeCallback) {
        this.removeCallback();
        this.removeCallback = null;
      }
      if (!viewer) {
        return;
      }
      this.ngZone.runOutsideAngular(() => {
        viewer?.infoBox?.destroy();

      });
      this.removeCallback = viewer.selectedEntityChanged.addEventListener((entity: Entity | undefined) => {
        this.ngZone.run(() => {
          /**
           * Updates the last pinned entity in the list of pinned entities.
           * If the passed entity is already pinned, the last entity/selectedEntity is set to undefined.
           * Otherwise, it will replace the last pinned entity.
           */

          this.displayedEntities[this.displayedEntities.length - 1] = (entity && this.displayedEntities.indexOf(entity) !== -1) ? undefined : entity;

          this.changeDetectorRef.markForCheck();
        });
      });
    });
  }


  override get generalWidget(): TemplateRef<any> {
    if (!this.widgetTemplate) {
      throw new Error('Widget template not found');
    }
    return this.widgetTemplate;
  }

  onPinnedChange(entity: Entity | undefined, $event: boolean): void {
    if (!entity) {
      return;
    }
    if ($event) {
      this.displayedEntities.push(undefined);
      return;
    }

    const $index = this.displayedEntities.indexOf(entity);
    if ($index === -1) {
      return;
    }

    // If the last displayed entity is pinned and it's still the currently selected entity,
    // we shrink the array to place the selected entity in the "currently selected entity position".
    if ($index === this.displayedEntities.length - 2 && this.viewer$()?.selectedEntity === entity) {
      this.displayedEntities.pop();
    } else {
      // If the entity is pinned, we remove it from the list of pinned entities
      this.displayedEntities.splice($index, 1);
    }

  }

  onClose(entity: Entity | undefined): void {
    if (!entity) {
      return;
    }
    const viewer = this.viewer$();
    if (viewer && entity === viewer.selectedEntity) {
      viewer.selectedEntity = undefined;
    }

    const i = this.displayedEntities.indexOf(entity);
    if (i === -1) {
      return;
    }

    this.displayedEntities.splice(i, 1);
  }
}
