import {Component, effect, inject, input, OnDestroy} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {CzmlDataSource} from '@cesium/engine';
import {takeUntilDestroyed, toObservable, toSignal} from '@angular/core/rxjs-interop';
import {switchMap} from 'rxjs/operators';
import {CZMLPacket} from '@ax-uss-ui/common';

@Component({
  selector: 'czml-drawer',
  standalone: true,
  imports: [CommonModule],
  template: ''
})
export class CzmlDrawerComponent implements OnDestroy {
  czml$ = input.required<CZMLPacket[]>();

  private drawerService = inject(CesiumService);
  private viewer$ = toSignal(this.drawerService.watchViewerInit());

  private datasource = (() => {
    const ds = new CzmlDataSource();
    ds.process({
      id: "document",
      name: "UAS Facility Map",
      version: "1.0"
    }).then(() => {
    });
    return ds;
  })();

  constructor() {
    effect(() => {
      const viewer = this.viewer$();
      if (!viewer) {
        return;
      }
      viewer.infoBox.frame.removeAttribute('sandbox');
      viewer.infoBox.frame.src = 'about:blank';
      viewer.dataSources.add(this.datasource).then(() => {});
    });

    toObservable(this.czml$).pipe(
      switchMap((obs) => obs),
      takeUntilDestroyed())
      .subscribe(data => {
        if (!data) {
          return;
        }
        this.datasource.process(data).then(() => {});
      });
  }

  ngOnDestroy(): void {
    this.viewer$()?.dataSources?.remove(this.datasource);
  }
}
