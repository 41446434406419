import {
  BaseModel,
  BaseModelDeserializer,
  DEFAULT_LAANC_DENIAL_REASON_CODE_UTIL,
  DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL,
  DEFAULT_LAANC_SUBMISSION_STATE_UTIL,
  DEFAULT_LAANC_SUBMISSION_TYPE_UTIL,
  fail,
  isEmpty,
  isObject,
  LaancDenialReasonCode,
  LaancSubmissionCategory,
  LaancSubmissionState,
  LaancSubmissionType,
  Result,
  some
} from '@ax-uss-ui/common';
import {UserMessageData} from './UserMessageData';
import {TransportUserMessageData} from './TransportUserMessage';

export interface ILaancAlertMessage {
  readonly operationId: string;
  readonly laancReferenceCode: string;
  readonly submissionType: LaancSubmissionType;
  readonly submissionCategory: LaancSubmissionCategory;
  readonly submissionState: LaancSubmissionState;
  readonly requiresAck?: boolean;
  readonly denialReason?: string;
  readonly denialReasonCode?: LaancDenialReasonCode;
  readonly denialReasonCodeText?: string;
  readonly deleted?: boolean;
}

export interface TLaancAlertMessage extends TransportUserMessageData {
  readonly operationId: string;
  readonly laancReferenceCode: string;
  readonly submissionType: LaancSubmissionType;
  readonly submissionCategory: LaancSubmissionCategory;
  readonly submissionState: LaancSubmissionState;
  readonly requiresAck?: boolean;
  readonly denialReason?: string;
  readonly denialReasonCode?: string;
  readonly denialReasonCodeText?: string;
  readonly deleted?: boolean;
}

/**
 * User message for changes to laanc submission state
 *
 * @param operationId internal operation ID
 * @param laancReferenceCode full laanc reference cod
 * @param submissionType     laanc submission type
 * @param submissionCategory laanc submission category
 * @param submissionState new submission state
 * @param requiresAck if this alert requires Operator acknowledgement
 * @param denialReason         reason for denial
 * @param denialReasonCode     reason received code for denial
 * @param denialReasonCodeText denialReasonCode in Displayable text
 */
export class LaancAlertMessage extends UserMessageData implements ILaancAlertMessage, BaseModel {
  readonly operationId: string;
  readonly laancReferenceCode: string;
  readonly submissionType: LaancSubmissionType;
  readonly submissionCategory: LaancSubmissionCategory;
  readonly submissionState: LaancSubmissionState;
  readonly requiresAck?: boolean;
  readonly denialReason?: string;
  readonly denialReasonCode?: LaancDenialReasonCode;
  readonly denialReasonCodeText?: string;
  readonly deleted?: boolean;

  constructor(values: ILaancAlertMessage) {
    super();

    this.operationId = values.operationId;
    this.submissionType = values.submissionType;
    this.submissionCategory = values.submissionCategory;
    this.laancReferenceCode = values.laancReferenceCode;
    this.submissionState = values.submissionState;
    this.requiresAck = values.requiresAck;
    this.denialReason = values.denialReason;
    this.denialReasonCode = values.denialReasonCode;
    this.denialReasonCodeText = values.denialReasonCodeText;
    this.deleted = values.deleted;
  }
}

export class LaancAlertMessageUtil implements BaseModelDeserializer<TLaancAlertMessage, LaancAlertMessage> {
  deserialize(raw: unknown): Result<LaancAlertMessage> {
    if (isEmpty(raw)) return fail('No data supplied for LAANC alert message');
    if (!isObject(raw)) return fail('Invalid data supplied for LAANC alert message');

    if (!('operationId' in raw) || isEmpty(raw.operationId)) return fail('No LAANC alert message operation ID value');
    if (typeof raw.operationId !== 'string') return fail('Invalid LAANC alert message operation ID value');

    if (!('laancReferenceCode' in raw) || isEmpty(raw.laancReferenceCode)) return fail('No LAANC alert message reference code value');
    if (typeof raw.laancReferenceCode !== 'string') return fail('Invalid LAANC alert message reference code value');

    if (!('submissionType' in raw) || isEmpty(raw.submissionType)) return fail('No LAANC alert message submission type value');
    const submissionType = DEFAULT_LAANC_SUBMISSION_TYPE_UTIL.deserialize(raw.submissionType);
    if (submissionType.type === 'error') return fail(submissionType.message);

    if (!('submissionCategory' in raw) || isEmpty(raw.submissionCategory)) return fail('No LAANC alert message submission category value');
    const submissionCategory = DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL.deserialize(raw.submissionCategory);
    if (submissionCategory.type === 'error') return fail(submissionCategory.message);

    if (!('submissionState' in raw) || isEmpty(raw.submissionState)) return fail('No LAANC alert message submission state value');
    const submissionState = DEFAULT_LAANC_SUBMISSION_STATE_UTIL.deserialize(raw.submissionState);
    if (submissionState.type === 'error') return fail(submissionState.message);

    let requiresAck: boolean | undefined = undefined;
    if (('requiresAck' in raw) && !isEmpty(raw.requiresAck)) {
      if (typeof raw.requiresAck !== 'boolean') return fail('Invalid LAANC alert message requires acknowledgment value');
      requiresAck = raw.requiresAck;
    }

    let denialReason: string | undefined = undefined;
    if (('denialReason' in raw) && !isEmpty(raw.denialReason)) {
      if (typeof raw.denialReason !== 'string') return fail('Invalid LAANC alert message denial reason value');
      denialReason = raw.denialReason;
    }

    let denialReasonCode: LaancDenialReasonCode | undefined = undefined;
    if (('denialReasonCode' in raw) && !isEmpty(raw.denialReasonCode)) {
      const denialReasonCodeResult = DEFAULT_LAANC_DENIAL_REASON_CODE_UTIL.deserialize(raw.denialReasonCode);
      if (denialReasonCodeResult.type === 'error') return fail(denialReasonCodeResult.message);
      denialReasonCode = denialReasonCodeResult.value;
    }

    let denialReasonCodeText: string | undefined = undefined;
    if (('denialReasonCodeText' in raw) && !isEmpty(raw.denialReasonCodeText)) {
      if (typeof raw.denialReasonCodeText !== 'string') return fail('Invalid LAANC alert message denial reason code text value');
      denialReason = raw.denialReasonCodeText;
    }

    let deleted: boolean | undefined = undefined;
    if (('deleted' in raw) && !isEmpty(raw.deleted)) {
      if (typeof raw.deleted !== 'boolean') return fail('Invalid LAANC alert message deleted value');
      deleted = raw.deleted;
    }

    return some(new LaancAlertMessage({
      operationId: raw.operationId,
      laancReferenceCode: raw.laancReferenceCode,
      submissionType: submissionType.value,
      submissionCategory: submissionCategory.value,
      submissionState: submissionState.value,
      requiresAck,
      denialReason,
      denialReasonCode,
      denialReasonCodeText,
      deleted
    }));
  }
}

export const DEFAULT_LAANC_ALERT_MESSAGE_UTIL = new LaancAlertMessageUtil();
