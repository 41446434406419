import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CZMLPacket} from '@ax-uss-ui/common';

export type EnvelopeBounds = [number, number, number, number];


@Injectable({
  providedIn: 'root'
})
export abstract class AirspaceService {
  abstract getUasFacilityMap(envelope: EnvelopeBounds): Observable<CZMLPacket[]>;

  abstract getClassAirspace(envelope: EnvelopeBounds): Observable<CZMLPacket[]>;

  abstract getSpecialUseAirspace(envelope: EnvelopeBounds): Observable<CZMLPacket[]>;

  abstract getAirports(envelope: EnvelopeBounds): Observable<CZMLPacket[]>;

  abstract getStadiums(envelope: EnvelopeBounds): Observable<CZMLPacket[]>;

  abstract getNsufrs(envelope: EnvelopeBounds): Observable<CZMLPacket[]>;

  abstract getWashingtonDcFrz(envelope: EnvelopeBounds): Observable<CZMLPacket[]>;

  abstract getTfrs(envelope: EnvelopeBounds): Observable<CZMLPacket[]>;

}
