import {Component, effect, inject, input, OnDestroy} from '@angular/core';
import {LaancVolumePreview} from '../../model/laanc/LaancVolumePreview';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {combineLatest, Observable} from 'rxjs';
import {ColorService} from '../../services/color.service';
import {switchMap} from 'rxjs/operators';
import {CustomDataSource, Entity, Event} from '@cesium/engine';
import {CesiumDrawerUtilService} from '../common/cesium-drawer-util.service';
import {DEFAULT_LAANC_SUBMISSION_TYPE_UTIL} from '@ax-uss-ui/common';

@Component({
  selector: 'laanc-volume-preview-drawer',
  standalone: true,
  imports: [],
  template: ''
})
export class LaancVolumePreviewDrawerComponent implements OnDestroy {
  volumes$ = input.required<LaancVolumePreview[]>();

  private drawerService = inject(CesiumService);
  private colorService = inject(ColorService);
  private cesiumDrawerUtilService = inject(CesiumDrawerUtilService);

  private viewer$ = toSignal(this.drawerService.watchViewerInit());
  private color = this.colorService.getColorForIdImmediate('operation', true);
  private dataSource: CustomDataSource = new CustomDataSource('constraint-volume-drawer');
  private terrainTileListenerRemove: Event.RemoveCallback;
  private humanizedLaancSubmissionTypes = DEFAULT_LAANC_SUBMISSION_TYPE_UTIL.humanized;

  private entities$ = toSignal(toObservable(this.volumes$).pipe(
    switchMap((volumes) => {
        const ret: Observable<Entity>[] = [];

        volumes.forEach((vol, i) => {
          const description = `
            <table class="cesium-infoBox-defaultTable">
              <tr><th scope="row" style="text-align: left;">Volume Number</th><td>${i + 1}</td></tr>
              <tr><th scope="row" style="text-align: left;">Type</th><td>${this.humanizedLaancSubmissionTypes[vol.type]}</td></tr>
              <tr><th scope="row" style="text-align: left;">Maximum Altitude</th><td>${vol.maximumAltitudeAgl} FT AGL</td></tr>
              <tr><th scope="row" style="text-align: left;">Start Time</th><td>${vol.volume.effective_time_begin}</td></tr>
              <tr><th scope="row" style="text-align: left;">End Time</th><td>${vol.volume.effective_time_end}</td></tr>
              <tr><th scope="row" style="text-align: left;">FAA Facility Code</th><td>${vol.faaFacilityCode}</td></tr>
              <tr><th scope="row" style="text-align: left;">Airspace Classes</th><td>${vol.airspaceClasses.join(', ')}</td></tr>
            </table>`;

          ret.push(this.cesiumDrawerUtilService.getConvertedEntityVolume4DCesiumEntity(vol.volume, this.color, 'LAANC Submission Volume', description));
        });

        return combineLatest(ret);
      }
    )
  ));

  constructor() {
    effect(() => {
      if (this.viewer$() && this.entities$()?.length) {
        const viewer = this.viewer$();
        viewer.dataSources.remove(this.dataSource);
        this.dataSource.entities.removeAll();
        this.entities$().forEach(entity => this.dataSource.entities.add(entity));
        viewer.dataSources.add(this.dataSource).then(() => {
          if (viewer.scene.globe.tilesLoaded) {
            viewer.zoomTo(this.dataSource).then(() => {
            });
          } else if (!this.terrainTileListenerRemove) {
            this.terrainTileListenerRemove = viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
              if (viewer.scene.globe.tilesLoaded) {
                viewer.zoomTo(this.dataSource).then(() => {
                });
                this.terrainTileListenerRemove();
              }
            });
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    const viewer = this.viewer$();
    viewer?.dataSources.remove(this.dataSource);
  }
}
