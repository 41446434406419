import {Injectable, Type} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {ImportExportType, ImportService} from '../../../../../import-export/import-export.service';
import {ConfigComponent} from '../../../../../import-export/config.component';
import {GeoCircle, polygon_type, units_of_measure, vertical_reference} from '../../../../../model/gen/utm';
import {FeatureCollection, GeometryCollection, LineString, Point} from 'geojson';

import {IOpGeoSubmissionFG} from '../../../../../components/operation/create-operation/create-operation.component';
import {
  IOpVolumeSubmissionFG
} from '../../../../../components/operation/operation-geometry-editor/operation-geometry-editor.component';
import {DateTime} from 'luxon';
import {TimeRange} from '../../../../../model/TimeRange';
import {UserSettings, UserSettingsService} from '../../../../user-settings.service';
import {isNil} from 'lodash';
import {
  GeojsonVolumeImportConfigComponent
} from '../../geojson-volume-import-config/geojson-volume-import-config.component';
import {
  BaseImportConfigType
} from '../../../../../import-export/components/base-import-config/base-import-config.component';
import {Errorable, fail, some} from "../../../../../utils/optional";
import {AltitudeRange} from "../../../../../model/gen/utm/altitude-range-model";
import {LatLngPoint} from "../../../../../model/WaypointParser";

interface OperationProperties {
  beginTime: string;
  endTime: string;
  min_altitude: number;
  max_altitude: number;
  altitude_vertical_reference: string;
  altitude_units: string;
  radius: number;
  radiusUnits: string;
}


@Injectable({
  providedIn: 'root'
})
export class GeojsonOperationVolumeImportService extends ImportService<Errorable<IOpGeoSubmissionFG>> {
  private userSettings: UserSettings;
  private userSettingsSub: Subscription;

  constructor(private userSettingsService: UserSettingsService) {
    super();

    this.userSettingsSub = userSettingsService.getRawSettings().subscribe(rawSettings => {
      this.userSettings = rawSettings;
    });
  }

  doImport(config: any): Observable<Errorable<IOpGeoSubmissionFG>> {
    return this.readFileJson(config.file).pipe(map((value: FeatureCollection) => {
      if (value.type !== 'FeatureCollection' || !value.features?.length) {
        return fail('Invalid GeoJSON');
      }

      let featuresError: string = null;
      const volumes: IOpVolumeSubmissionFG[] = [];
      let points: LatLngPoint[] = [];
      let controllerLocation: LatLngPoint;
      let takeOffLocation: LatLngPoint;

      value.features.forEach(feature => {
        if (!featuresError) {
          switch (feature.properties.id) {
            case 'operation_volumes':
              const geometryCollection = feature.geometry as GeometryCollection;
              const properties = feature.properties as OperationProperties;

              // Volume error handling
              if (!geometryCollection || !geometryCollection.geometries?.length) {
                featuresError = 'Invalid geometry';
                break;
              } else if (isNil(properties.min_altitude) || isNil(properties.max_altitude) || !properties.altitude_vertical_reference ||
                !properties.altitude_units) {
                featuresError = 'Invalid altitude';
                break;
              }

              volumes.push({
                geography: geometryCollection.geometries[0].type === 'Polygon' ? {
                  type: polygon_type.Polygon,
                  coordinates: geometryCollection.geometries[0].coordinates
                } : undefined,
                circle: geometryCollection.geometries[0].type === 'Point' ? new GeoCircle({
                  latitude: geometryCollection.geometries[0].coordinates[1],
                  longitude: geometryCollection.geometries[0].coordinates[0],
                  radius: properties.radius,
                  units: units_of_measure[properties.radiusUnits]
                }) : undefined,
                altitudeRange: new AltitudeRange({
                  min_altitude: properties.min_altitude,
                  max_altitude: properties.max_altitude,
                  altitude_vertical_reference: vertical_reference[properties.altitude_vertical_reference],
                  altitude_units: units_of_measure[properties.altitude_units]
                }),
                timeRange: new TimeRange(DateTime.fromISO(properties.beginTime), DateTime.fromISO(properties.endTime))
              } as IOpVolumeSubmissionFG);
              break;
            case 'takeoff_location':
              const takeOffCoords = (feature.geometry as Point).coordinates;
              takeOffLocation = {
                lng: takeOffCoords[0],
                lat: takeOffCoords[1],
                alt: takeOffCoords.length > 2 ? takeOffCoords[2] : -1
              };
              break;
            case 'controller_location':
              const controllerCoords = (feature.geometry as Point).coordinates;
              controllerLocation = {
                lng: controllerCoords[0],
                lat: controllerCoords[1],
                alt: controllerCoords.length > 2 ? controllerCoords[2] : -1
              };
              break;
            case 'waypoints':
              points = (feature.geometry as LineString).coordinates.map(p => ({
                lng: p[0], lat: p[1], alt: p.length > 2 ? p[2] : -1
              } as LatLngPoint));
              break;
            default:
          }
        }
      });
      if (featuresError) {
        return fail(featuresError);
      } else if (!volumes.length && !points.length) {
        return fail('Invalid geometry');
      }
      return some({
        volumes,
        points,
        controllerLocation,
        takeOffLocation
      } as IOpGeoSubmissionFG);
    }));
  }

  getFormatName(): string {
    return 'GeoJSON';
  }

  getType(): ImportExportType {
    return ImportExportType.OPERATIONVOLUME;
  }

  getFileExtensions(): string[] {
    return ['.json', '.geojson'];
  }

  getConfigComponent(): Type<ConfigComponent> {
    return GeojsonVolumeImportConfigComponent;
  }

  getDefaultConfig(): any {
    return {file: null} as BaseImportConfigType;
  }
}
