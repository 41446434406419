import {Component, computed, input, output} from '@angular/core';
import {LaancCheckResponse} from '../../../model/laanc/LaancCheckResponse';
import {
  DEFAULT_LAANC_FR_RULES_UTIL,
  DEFAULT_LAANC_REQUIREMENT_UTIL,
  InfoboxContainerComponent,
  LaancFrRules,
  LaancRequirement,
  LaancSubmissionCategory
} from '@ax-uss-ui/common';
import {CesiumModule} from '../../../cesium/cesium.module';
import {NgClass, NgIf} from '@angular/common';
import {CesiumComponentsModule} from '@ax/ax-angular-map-cesium';
import {CzmlDrawerComponent} from '../../../cesium/czml-drawer/czml-drawer.component';
import {CdsCardModule, CdsIconModule} from '@cds/angular';
import {ClrCheckboxModule, ClrTreeViewModule} from '@clr/angular';
import {FormsModule} from '@angular/forms';
import {EntityVolume4d} from '../../../model/gen/utm';
import {LaancVolumePreviewsComponent} from '../laanc-volume-previews/laanc-volume-previews.component';
import {
  LaancVolumePreviewDrawerComponent
} from '../../../cesium/laanc-volume-preview-drawer/laanc-volume-preview-drawer.component';

interface ILaancFrRulesSet {
  rule: LaancFrRules,
  humanizedRule: string
}

@Component({
  selector: 'laanc-check-response',
  standalone: true,
  imports: [
    CesiumModule,
    NgIf,
    CesiumComponentsModule,
    CzmlDrawerComponent,
    CdsCardModule,
    CdsIconModule,
    NgClass,
    ClrTreeViewModule,
    ClrCheckboxModule,
    FormsModule,
    InfoboxContainerComponent,
    LaancVolumePreviewsComponent,
    LaancVolumePreviewDrawerComponent
  ],
  templateUrl: './laanc-check-response.component.html',
  styleUrl: './laanc-check-response.component.scss'
})
export class LaancCheckResponseComponent {
  laancResponse$ = input.required<LaancCheckResponse>();
  laancCategory$ = input.required<LaancSubmissionCategory>();
  submitOperation$ = output<boolean>();

  displayConsentCheckbox$ = computed<boolean>(() => (
    this.laancResponse$().requirement === LaancRequirement.REQUIRE_AUTO_APPROVAL ||
    this.laancResponse$().requirement === LaancRequirement.REQUIRE_FURTHER_COORDINATION
  ));
  volumes$ = computed(() => {
    if (this.laancResponse$()?.volumes?.length) {
      return this.laancResponse$().volumes.map(volume => volume.volume);
    } else {
      return [] as EntityVolume4d[];
    }
  })

  laancRequirement = LaancRequirement;
  laancFrRules = LaancFrRules;
  humanizedLaancRequirements = DEFAULT_LAANC_REQUIREMENT_UTIL.humanized;
  humanizedFrRules = DEFAULT_LAANC_FR_RULES_UTIL.humanized;
  submissionConsentGiven = false;
}
