import {BaseEnumSerializerDeserializer} from '../../common';
import {fail, Result, some} from '../../../utils';

export enum LaancSubmissionState {
  /** Internal state before submission */
  STAGING = 'STAGING',
  /** Internal state indicating submission either never occurred or failed most likely due to LAANC API error */
  STAGING_CANCELLED = 'STAGING_CANCELLED',
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  /**
   * Cancelled by operator. Before operation starts.
   * Indicating the operation is no longer planned.
   * Since cancellation indicates that an operation will not be flown (in the future), it can occur only
   * prior to the operation start time.
   */
  OPERATOR_CANCELLED = 'OPERATOR_CANCELLED',
  /**
   * Closed by operator. Indicating the operation
   * is complete prior to the submitted end time of the authorization.
   */
  OPERATOR_CLOSED = 'OPERATOR_CLOSED',
  /**
   * LAANC data deleted by request of operator.
   */
  OPERATOR_DELETED = 'OPERATOR_DELETED',
  /**
   * Auto Cancelled. this automatic request cancellation is initiated by the USS,
   * rather than the operator.
   */
  AUTOMATICALLY_CANCELLED = 'AUTOMATICALLY_CANCELLED',
  /**
   * Invalid Cancel. Operator needs to cancel.
   */
  INVALIDATED = 'INVALIDATED',
  /**
   * Authorization rescinded by FAA and awaiting operator acknowledgement.
   */
  RESCINDED_AWAITING = 'RESCINDED_AWAITING',
  /**
   * Authorization rescinded by FAA and acknowledged by Operator.
   */
  RESCINDED_ACKNOWLEDGED = 'RESCINDED_ACKNOWLEDGED',
  /**
   * Denied by LAANC
   */
  DENIED = 'DENIED',
  EXPIRED = 'EXPIRED',
  COMPLETED = 'COMPLETED'
}

export class LaancSubmissionStateUtil implements BaseEnumSerializerDeserializer<LaancSubmissionState, LaancSubmissionState> {
  humanized: {[key in LaancSubmissionState]: string } = {
    [LaancSubmissionState.STAGING]: 'Staging',
    [LaancSubmissionState.STAGING_CANCELLED]: 'Staging Cancelled',
    [LaancSubmissionState.PENDING]: 'Pending',
    [LaancSubmissionState.AUTHORIZED]: 'Authorized',
    [LaancSubmissionState.OPERATOR_CANCELLED]: 'Operator Cancelled',
    [LaancSubmissionState.OPERATOR_CLOSED]: 'Operator Closed',
    [LaancSubmissionState.OPERATOR_DELETED]: 'Operator Deleted',
    [LaancSubmissionState.AUTOMATICALLY_CANCELLED]: 'Automatically Cancelled',
    [LaancSubmissionState.INVALIDATED]: 'Invalidated',
    [LaancSubmissionState.RESCINDED_AWAITING]: 'Rescinded Awaiting',
    [LaancSubmissionState.RESCINDED_ACKNOWLEDGED]: 'Rescinded Acknowledged',
    [LaancSubmissionState.DENIED]: 'Denied',
    [LaancSubmissionState.EXPIRED]: 'Expired',
    [LaancSubmissionState.COMPLETED]: 'Completed'
  }

  deserialize(raw: unknown): Result<LaancSubmissionState> {
    if (typeof raw !== 'string') return fail('Invalid type for LAANC submission state');

    switch (raw.toUpperCase()) {
      case 'STAGING':
        return some(LaancSubmissionState.STAGING);
      case 'STAGING_CANCELLED':
        return some(LaancSubmissionState.STAGING_CANCELLED);
      case 'PENDING':
        return some(LaancSubmissionState.PENDING);
      case 'AUTHORIZED':
        return some(LaancSubmissionState.AUTHORIZED);
      case 'OPERATOR_CANCELLED':
        return some(LaancSubmissionState.OPERATOR_CANCELLED);
      case 'OPERATOR_CLOSED':
        return some(LaancSubmissionState.OPERATOR_CLOSED);
      case 'OPERATOR_DELETED':
        return some(LaancSubmissionState.OPERATOR_DELETED);
      case 'AUTOMATICALLY_CANCELLED':
        return some(LaancSubmissionState.AUTOMATICALLY_CANCELLED);
      case 'INVALIDATED':
        return some(LaancSubmissionState.INVALIDATED);
      case 'RESCINDED_AWAITING':
        return some(LaancSubmissionState.RESCINDED_AWAITING);
      case 'RESCINDED_ACKNOWLEDGED':
        return some(LaancSubmissionState.RESCINDED_ACKNOWLEDGED);
      case 'DENIED':
        return some(LaancSubmissionState.DENIED);
      case 'EXPIRED':
        return some(LaancSubmissionState.EXPIRED);
      case 'COMPLETED':
        return some(LaancSubmissionState.COMPLETED);
      default:
        return fail(`Invalid LAANC submission state: ${raw}`);
    }
  }

  deserializeArray(raw: unknown): Result<LaancSubmissionState[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for LAANC submission state array');
    const results: LaancSubmissionState[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }

  serialize(obj: LaancSubmissionState): LaancSubmissionState {
    return obj;
  }
}

export const DEFAULT_LAANC_SUBMISSION_STATE_UTIL = new LaancSubmissionStateUtil();
