import {concat, Observable, of} from 'rxjs';
import {OperationGeometry} from '../../../../../model/operation.geometry';
import {delay, map, shareReplay, startWith, switchMap} from 'rxjs/operators';
import {Component, computed, effect, inject, input, signal, Type} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MeasurementSystemType} from '../../../../../model/MeasurementSystem';

import {UserSettingsService} from '../../../../user-settings.service';
import {UTMFlightPlanCustomizer, UTMFlightPlanCustomizerComponent} from './common';
import {SwiftFlightPlanFormGroup, SwiftUTMFlightPlan} from './formats/SwiftFlightPlan';
import {takeOffIcon} from '../../../../../utils/leaflet-color-markers';

import {AxAngularMapLeafletModule, LeafletComponentsModule} from '@ax/ax-angular-map-leaflet';
import {LeafletModule} from '../../../../../leaflet/leaflet.module';
import * as L from 'leaflet';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {BufferStepCustomizerComponent, BufferStepCustomizerFormGroup} from './BufferStepCustomizer';
import {
  LeafletPointCreatorComponent
} from '../../../../../components/leaflet/leaflet-point-creator/leaflet-point-creator.component';


@Component({
  selector: 'app-swift-flight-plan-customizer',
  styles: `
    .map {
      height: 400px;
      display: block;
    }
  `,
  template: `@defer (on timer(0.1s)) {
    <label class="clr-control-label">Adjust takeoff location:</label>
    <ax-leaflet-container class="map">
      <leaflet-expose-map (mapInit)="handleMapInit($event)"></leaflet-expose-map>
      @if (homeLocationControl$()) {

        <app-leaflet-point-creator
          [name]="'TakeOffLocation'"
          [title]="'Take Off Location'"
          [controlClassName]="'drone-icon'"
          [icon]="takeOffIcon"
          [formControl]="homeLocationControl$()">
        </app-leaflet-point-creator>
      }
    </ax-leaflet-container>
  }
  @if (tmpFormGroup$()) {
    <app-flight-plan-buffer-step-customizer [formGroup]="tmpFormGroup$()"></app-flight-plan-buffer-step-customizer>
  }
  `,

  imports: [
      ReactiveFormsModule,
      LeafletPointCreatorComponent,
      AxAngularMapLeafletModule,
      LeafletModule,
      BufferStepCustomizerComponent
  ],


  standalone: true
})
class SwiftFlightPlanCustomizerComponent implements UTMFlightPlanCustomizerComponent {
  protected readonly measurementSystemType = MeasurementSystemType;
  userSettingsService = inject(UserSettingsService);
  private mapSrc = signal<L.Map>(null);
  private map$ =
    toSignal(
      toObservable(this.mapSrc).pipe(delay(0.1))
    );

  formGroup = input.required<SwiftFlightPlanFormGroup>();
  tmpFormGroup$ = computed(() => this.formGroup() as unknown as  BufferStepCustomizerFormGroup);
  homeLocationControl$ = computed(() => this.formGroup().controls.homePosition);
  homeLocation$ = toSignal(
    toObservable(this.homeLocationControl$).pipe(
      switchMap(c => c ? concat(of(c.value), c.valueChanges) : of(null)),
      delay(0.1)
    )
  );

  protected readonly takeOffIcon = takeOffIcon;


  handleMapInit(map: L.Map) {
    // console.log($event);
    this.mapSrc.set(map);


    const customTranslation = {
      buttonTitles: {
        editButton: 'Edit Item',
        dragButton: 'Drag Item',
        deleteButton: 'Remove Item'
      }
    };

    map.pm.setLang('en', customTranslation, 'en');

    map.pm.addControls({
      position: 'topleft',
      drawControls: true,
      drawCircle: false,
      drawMarker: false,
      drawRectangle: false,
      drawCircleMarker: false,
      drawPolygon: false,
      drawText: false,
      drawPolyline: false,
      editControls: true,
      cutPolygon: false,
      pinningOption: false,
      snappingOption: false,
      optionsControls: false,
      customControls: true,
      oneBlock: false
    });

    // map.on('pm:globaleditmodetoggled', () => {
    //   this.emitChange();
    // });
    // map.on('pm:globaldrawmodetoggled', () => {
    //   this.emitChange();
    // });
    // map.on('moveend', () => {
    //   this.emitAreaOfInterestChange();
    // });

  }

  constructor() {
    effect(() => {
      const homeLocation = this.homeLocation$();
      const map = this.map$();
      if (homeLocation && map) {
        // map.setView([homeLocation.lat, homeLocation.lng], 15);
        map.flyTo([homeLocation.lat, homeLocation.lng], map.getZoom() || 15, {
          animate: false
        });
      }


    });
  }
}

export class SwiftFlightPlanCustomizer extends UTMFlightPlanCustomizer {
  status$: Observable<boolean>;

  constructor(private flightPlan: SwiftUTMFlightPlan) {
    super();
    this.status$ = this.flightPlan.options.statusChanges.pipe(
      map(() => this.flightPlan.options.valid),
      startWith(this.flightPlan.options.valid),
      shareReplay(1)
    );
  }




  static fromText(text: string): SwiftFlightPlanCustomizer {
    return new SwiftFlightPlanCustomizer(SwiftUTMFlightPlan.fromText(text));
  }

  getConfigurationComponent(): { component: Type<any>; inputs: any } {
    return {
      component: SwiftFlightPlanCustomizerComponent as Type<any>,
      inputs: {
        formGroup: this.flightPlan.options
      }
    };
  }

  toOperationGeometry(): Observable<OperationGeometry> {
    return this.flightPlan.toOperationGeometry();
  }


}
