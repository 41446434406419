import {inject, Injectable, Type} from '@angular/core';
import {Observable} from 'rxjs';
import {
  FlightplanOperationVolumeImportConfigComponent
} from './flightplan-operation-volume-import-config/flightplan-operation-volume-import-config.component';
import {IOpGeoSubmissionFG} from '../../../../../components/operation/create-operation/create-operation.component';
import {UTMFlightPlanCustomizer} from './common';
import {map} from 'rxjs/operators';
import {AltitudeRange} from '../../../../../model/gen/utm/altitude-range-model';
import {TimeRange} from '../../../../../model/TimeRange';
import {MeasurementSystemPreferenceService} from '../../../../measurement-system-preference.service';
import {MeasurementSystemType} from '../../../../../model/MeasurementSystem';
import {units_of_measure} from '../../../../../model/gen/utm';
import {MeasurementsUtil} from '../../../../../utils/MeasurementsUtil';
import {Errorable, some} from "../../../../../utils/optional";
import {ImportExportType, ImportService} from "../../../../../import-export/import-export.service";
import {ConfigComponent} from "../../../../../import-export/config.component";

@Injectable({
  providedIn: 'root'
})
export class FlightPlanOperationVolumeImportService extends ImportService<Errorable<IOpGeoSubmissionFG>> {

  measurementSystem$ = inject(MeasurementSystemPreferenceService).measurementSystem$;

  constructor() {
    super();
  }

  doImport(flightPlan: UTMFlightPlanCustomizer): Observable<Errorable<IOpGeoSubmissionFG>> {

    // const volumes = flightPlan.toOperationVolumes();
    const geom = flightPlan.toOperationGeometry();
    const measurementSystem = this.measurementSystem$();

    return geom.pipe(
      map(geometry => {
        const vols = geometry.getVolumes().map(v => {
          const targetUnits = measurementSystem === MeasurementSystemType.IMPERIAL ? units_of_measure.FT : units_of_measure.M;
          return {
            geography: v.geography,
            altitudeRange: new AltitudeRange({
              altitude_vertical_reference: v.min_altitude.vertical_reference,
              altitude_units: targetUnits,
              min_altitude: MeasurementsUtil.convertUnits(v.min_altitude.units_of_measure, targetUnits, v.min_altitude.altitude_value),
              max_altitude: MeasurementsUtil.convertUnits(v.max_altitude.units_of_measure, targetUnits, v.max_altitude.altitude_value),
            }),
            timeRange: new TimeRange(v.effective_time_begin, v.effective_time_end)
            };
          });


        return some({
            volumes: vols,
            controllerLocation: undefined,
            takeOffLocation: geometry.getTakeOffLocation(),

          } as IOpGeoSubmissionFG);
        }
      )
    );

  }

  getFormatName(): string {
    return 'Flight Plan File';
  }

  getType(): ImportExportType {
    return ImportExportType.OPERATIONVOLUME;
  }

  getFileExtensions(): string[] {
    return ['.waypoints', '.fp', '.mission', '.json', '.plan'];
  }


  getPriority(): number {
    return 0;
  }

  getConfigComponent(): Type<ConfigComponent> {
    return FlightplanOperationVolumeImportConfigComponent as Type<ConfigComponent>;
  }

  getDefaultConfig(): UTMFlightPlanCustomizer {
    return null;
  }
}
