<form [formGroup]="fg" class="clr-row">
  <clr-control-container class="clr-col-md-6 clr-col-12">
    <app-datetime-picker clrControl [label]="'Start Time'" formControlName="startTime"
                         [placeholder]="!fg.controls.startTime.value ? 'ASAP' : ''"></app-datetime-picker>
    <clr-control-error *clrIfError="'required'">Start time is required.</clr-control-error>
    <clr-control-error *clrIfError="'minTimeOffset'">{{fg.controls.startTime.errors.minTimeOffset}}</clr-control-error>
    <clr-control-error *clrIfError="'maxTimeOffset'">{{fg.controls.startTime.errors.maxTimeOffset}}</clr-control-error>
  </clr-control-container>
  <clr-control-container class="clr-col-md-6 clr-col-12">
    <app-datetime-picker clrControl [label]="'End Time'" formControlName="endTime" [required]="true"></app-datetime-picker>
    <clr-control-error *clrIfError="'required'">End time is required.</clr-control-error>
    <clr-control-error *clrIfError="'minTimeOffset'">{{fg.controls.endTime.errors.minTimeOffset}}</clr-control-error>
    <clr-control-error *clrIfError="'maxTimeOffset'">{{fg.controls.endTime.errors.maxTimeOffset}}</clr-control-error>
  </clr-control-container>
  <error-container [control]="fg" [displayAlways]="true">
    <error-message [has]="'duration'"></error-message>
  </error-container>
</form>
