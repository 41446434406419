import {BaseEnumDeserializer} from '../../common';
import {fail, Result, some} from '../../../utils';

export enum LaancRequirement {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  BLOCKED = 'BLOCKED',
  REQUIRE_AUTO_APPROVAL = 'REQUIRE_AUTO_APPROVAL',
  REQUIRE_FURTHER_COORDINATION = 'REQUIRE_FURTHER_COORDINATION'
}

export class LaancRequirementUtil implements BaseEnumDeserializer<LaancRequirement, LaancRequirement> {
  humanized: {[key in LaancRequirement]: string } = {
    [LaancRequirement.NOT_APPLICABLE] : 'Not Applicable',
    [LaancRequirement.BLOCKED] : 'Blocked',
    [LaancRequirement.REQUIRE_AUTO_APPROVAL] : 'Eligible For Auto-Approval',
    [LaancRequirement.REQUIRE_FURTHER_COORDINATION] : 'Requires Further Coordination',
  }

  deserialize(raw: unknown): Result<LaancRequirement> {
    if (typeof raw !== 'string') return fail('Invalid type for LAANC requirement');

    switch (raw.toUpperCase()) {
      case 'NOT_APPLICABLE':
        return some(LaancRequirement.NOT_APPLICABLE);
      case 'BLOCKED':
        return some(LaancRequirement.BLOCKED);
      case 'REQUIRE_AUTO_APPROVAL':
        return some(LaancRequirement.REQUIRE_AUTO_APPROVAL);
      case 'REQUIRE_FURTHER_COORDINATION':
        return some(LaancRequirement.REQUIRE_FURTHER_COORDINATION);
      default:
        return fail(`Invalid laanc requirement: ${raw}`);
    }
  }
}

export const DEFAULT_LAANC_REQUIREMENT_UTIL = new LaancRequirementUtil();
