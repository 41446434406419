import {BaseEnumSerializerDeserializer} from '../../common';
import {fail, Result, some} from '../../../utils';

export enum LaancSubmissionType {
  AUTO_APPROVAL = 'AUTO_APPROVAL',
  FURTHER_COORDINATION = 'FURTHER_COORDINATION',
}

export class LaancSubmissionTypeUtil implements BaseEnumSerializerDeserializer<LaancSubmissionType, LaancSubmissionType> {
  humanized: {[key in LaancSubmissionType]: string} = {
    [LaancSubmissionType.AUTO_APPROVAL]: 'Auto-Approval',
    [LaancSubmissionType.FURTHER_COORDINATION]: 'Further Coordination',
  };

  deserialize(raw: unknown): Result<LaancSubmissionType> {
    if (typeof raw !== 'string') return fail('Invalid type for LAANC submission type');

    switch (raw.toUpperCase()) {
      case 'AUTO_APPROVAL':
        return some(LaancSubmissionType.AUTO_APPROVAL);
      case 'FURTHER_COORDINATION':
        return some(LaancSubmissionType.FURTHER_COORDINATION);
      default:
        return fail(`Invalid LAANC submission type: ${raw}`);
    }
  }

  deserializeArray(raw: unknown): Result<LaancSubmissionType[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for LAANC submission type array');
    const results: LaancSubmissionType[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }

  serialize(obj: LaancSubmissionType): LaancSubmissionType {
    return obj;
  }
}

export const DEFAULT_LAANC_SUBMISSION_TYPE_UTIL = new LaancSubmissionTypeUtil();
