import {BaseEnumSerializerDeserializer} from '../../common';
import {fail, Result, some} from '../../../utils';

export enum LaancSubmissionCategory {
  PART_107= 'PART_107',
  SECTION_44809 = 'SECTION_44809'
}

export class LaancSubmissionCategoryUtil implements BaseEnumSerializerDeserializer<LaancSubmissionCategory, LaancSubmissionCategory> {
  humanized: {[key in LaancSubmissionCategory]: string } = {
    [LaancSubmissionCategory.PART_107]: 'Part 107',
    [LaancSubmissionCategory.SECTION_44809]: 'Section 44809'
  };

  deserialize(raw: unknown): Result<LaancSubmissionCategory> {
    if (typeof raw !== 'string') return fail('Invalid type for LAANC submission category');

    switch (raw.toUpperCase()) {
      case 'PART_107':
        return some(LaancSubmissionCategory.PART_107);
      case 'SECTION_44809':
        return some(LaancSubmissionCategory.SECTION_44809);
      default:
        return fail(`Invalid LAANC submission category: ${raw}`);
    }
  }

  deserializeArray(raw: unknown): Result<LaancSubmissionCategory[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for LAANC submission category array');
    const results: LaancSubmissionCategory[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }

  serialize(obj: LaancSubmissionCategory): LaancSubmissionCategory {
    return obj;
  }
}

export const DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL = new LaancSubmissionCategoryUtil();
