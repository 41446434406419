import {
  Component,
  ContentChildren,
  forwardRef,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import {Viewer} from "@cesium/widgets";
import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {NgForOf, NgTemplateOutlet} from "@angular/common";
import {CesiumComponent} from '../safire-button/CesiumComponent';

export abstract class CesiumToolbarButton {
  abstract get button(): TemplateRef<any>|null;
  get generalWidget(): TemplateRef<any>|null {
    return null;
  }
}

@Component({
  standalone: true,
  selector: 'lib-toolbar-extra-button-container',
  templateUrl: './toolbar-extra-button-container.component.html',
  imports: [
    NgTemplateOutlet,
    NgForOf
  ],
  providers: [
    {provide: AXCesiumWidget, useExisting: forwardRef(() => ToolbarExtraButtonContainerComponent)},
  ]
})
export class ToolbarExtraButtonContainerComponent extends CesiumComponent {
  @ContentChildren(CesiumToolbarButton, {descendants: true}) buttons: QueryList<CesiumToolbarButton>|undefined;

  @ViewChild("toolbarTemplate") toolbarTemplate: TemplateRef<any> | undefined;
  @ViewChild("generalTemplate") generalTemplate: TemplateRef<any> | undefined;

  override get generalWidget(): TemplateRef<any> {
    const generalTemplate = this.generalTemplate;
    if(!generalTemplate) {
      throw new Error('General template not found');
    }
    return generalTemplate;
  }


  private viewer: Viewer | undefined;
  constructor(cesiumService:CesiumService, private viewContainerRef: ViewContainerRef) {
    super(cesiumService);
  }

  onViewerInit(viewer: Viewer): void {

    this.viewer = viewer;
    const viewerContainer = this.viewer.container
    const cesiumToolbar = viewerContainer.getElementsByClassName('cesium-viewer-toolbar')[0];
    const toolbarContainers = cesiumToolbar.getElementsByClassName('cesium-toolbar');
    if(toolbarContainers.length !== 0) {
      for(const toolbarContainer of Array.from(toolbarContainers)) {
        viewerContainer.removeChild(toolbarContainer);
      }
    }

    if(!this.toolbarTemplate){
      return;
    }

    const embeddedViewRef = this.viewContainerRef.createEmbeddedView( this.toolbarTemplate );
    embeddedViewRef.detectChanges();
    for (const child of embeddedViewRef.rootNodes) {
      cesiumToolbar.appendChild(child);
    }




  }

}
