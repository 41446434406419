import {fail, Result, some} from '../../../utils';
import {BaseEnumDeserializer} from '../../common';

export enum LaancDenialReasonCode {
  /** Altitude – Resubmit 50 ft Lower */
  DENY_ALT_RSBMT_50FT = 'DENY_ALT_RSBMT_50FT',
  /** Altitude – Resubmit 100 ft Lower */
  DENY_ALT_RSBMT_100FT = 'DENY_ALT_RSBMT_100FT',
  /** Altitude – Resubmit 150 ft Lower */
  DENY_ALT_RSBMT_150FT = 'DENY_ALT_RSBMT_150FT',
  /** Altitude – Resubmit 200 ft Lower */
  DENY_ALT_RSBMT_200FT = 'DENY_ALT_RSBMT_200FT',
  /** Altitude – Resubmit 250 ft Lower */
  DENY_ALT_RSBMT_250FT = 'DENY_ALT_RSBMT_250FT',
  /** Altitude – Resubmit 300 ft Lower */
  DENY_ALT_RSBMT_300FT = 'DENY_ALT_RSBMT_300FT',
  /** Altitude – Resubmit 350 ft Lower */
  DENY_ALT_RSBMT_350FT = 'DENY_ALT_RSBMT_350FT',
  /** Duplicate Request */
  DENY_DUPLICATE_REQ = 'DENY_DUPLICATE_REQ',
  /** Location */
  DENY_LOCATION = 'DENY_LOCATION',
  /** Re-Submit via DroneZone - Mitigations Needed to Approve */
  DENY_RSBMT_VIA_DZ = 'DENY_RSBMT_VIA_DZ',
  /** Time of Day */
  DENY_TIME_OF_DAY = 'DENY_TIME_OF_DAY',
  DENY_NO_RSN_SPCFY = 'DENY_NO_RSN_SPCFY',
}

export class LaancDenialReasonCodeUtil implements BaseEnumDeserializer<LaancDenialReasonCode, LaancDenialReasonCode> {
  humanized: {[key in LaancDenialReasonCode]: string} = {
    [LaancDenialReasonCode.DENY_ALT_RSBMT_50FT]: 'Altitude – Resubmit 50 ft Lower',
    [LaancDenialReasonCode.DENY_ALT_RSBMT_100FT] : 'Altitude – Resubmit 100 ft Lower',
    [LaancDenialReasonCode.DENY_ALT_RSBMT_150FT] : 'Altitude – Resubmit 150 ft Lower',
    [LaancDenialReasonCode.DENY_ALT_RSBMT_200FT] : 'Altitude – Resubmit 200 ft Lower',
    [LaancDenialReasonCode.DENY_ALT_RSBMT_250FT] : 'Altitude – Resubmit 250 ft Lower',
    [LaancDenialReasonCode.DENY_ALT_RSBMT_300FT] : 'Altitude – Resubmit 300 ft Lower',
    [LaancDenialReasonCode.DENY_ALT_RSBMT_350FT] : 'Altitude – Resubmit 350 ft Lower',
    [LaancDenialReasonCode.DENY_DUPLICATE_REQ] : 'Duplicate Request',
    [LaancDenialReasonCode.DENY_LOCATION] : 'Location',
    [LaancDenialReasonCode.DENY_RSBMT_VIA_DZ] : 'Re-Submit via DroneZone - Mitigations Needed to Approve',
    [LaancDenialReasonCode.DENY_TIME_OF_DAY] : 'Time of Day',
    [LaancDenialReasonCode.DENY_NO_RSN_SPCFY] : 'No Reason Specified',
  }

  deserialize(raw: unknown): Result<LaancDenialReasonCode> {
    if (typeof raw !== 'string') return fail('Invalid type for LAANC denial reason code');

    switch (raw.toUpperCase()) {
      case 'DENY_ALT_RSBMT_50FT':
        return some(LaancDenialReasonCode.DENY_ALT_RSBMT_50FT);
      case 'DENY_ALT_RSBMT_100FT':
        return some(LaancDenialReasonCode.DENY_ALT_RSBMT_100FT);
      case 'DENY_ALT_RSBMT_150FT':
        return some(LaancDenialReasonCode.DENY_ALT_RSBMT_150FT);
      case 'DENY_ALT_RSBMT_200FT':
        return some(LaancDenialReasonCode.DENY_ALT_RSBMT_200FT);
      case 'DENY_ALT_RSBMT_250FT':
        return some(LaancDenialReasonCode.DENY_ALT_RSBMT_250FT);
      case 'DENY_ALT_RSBMT_300FT':
        return some(LaancDenialReasonCode.DENY_ALT_RSBMT_300FT);
      case 'DENY_ALT_RSBMT_350FT':
        return some(LaancDenialReasonCode.DENY_ALT_RSBMT_350FT);
      case 'DENY_DUPLICATE_REQ':
        return some(LaancDenialReasonCode.DENY_DUPLICATE_REQ);
      case 'DENY_LOCATION':
        return some(LaancDenialReasonCode.DENY_LOCATION);
      case 'DENY_RSBMT_VIA_DZ':
        return some(LaancDenialReasonCode.DENY_RSBMT_VIA_DZ);
      case 'DENY_TIME_OF_DAY':
        return some(LaancDenialReasonCode.DENY_TIME_OF_DAY);
      case 'DENY_NO_RSN_SPCFY':
        return some(LaancDenialReasonCode.DENY_NO_RSN_SPCFY);
      default:
        return fail(`Invalid LAANC denial reason code: ${raw}`);
    }
  }
}

export const DEFAULT_LAANC_DENIAL_REASON_CODE_UTIL = new LaancDenialReasonCodeUtil();
