@if(this.entity$() || this.content()){
  <div #infoBoxContainer class="cesium-infoBox cesium-infoBox-visible info-drag">
  <div class="cesium-infoBox-title" (mousedown)="dragMouseDown($event)">
    <ng-container *ngTemplateOutlet="trackingIcon"/>
    <span class="title">{{ title$() }}</span>
    <div class="actions">
      @if (this.entity$()) {
        @if(enableLiveUpdates()){
        <img class="live-icon title-bar-icon" [width]="20" [height]="20" ngSrc="{{liveUpdatesSvgPath$()}}"
             (click)="toggleLiveUpdates()" alt="Live infobox"/>
        }
          @if(enablePinning()){
          <img class="pin-icon title-bar-icon" [width]="20" [height]="20" ngSrc="{{pinnedSvgPath$()}}" (click)="togglePin()"
             alt="Pin infobox"/>
          }
      }
    </div>
    <div class="close-icon title-bar-icon" (click)="closeInfoBox()">×</div>
  </div>
  @if (entity$()) {
    <div class="cesium-infoBox-description" #infoBoxDescription></div>
  }
  @if (content()) {
    <div class="cesium-infoBox-description description-content">
      <ng-container *ngTemplateOutlet="content()!"></ng-container>
    </div>
  }
</div>
}

<ng-template #trackingIcon>
  @if (entity$() && enableEntityTracking()) {
    <ng-container *ngIf="isTrackedEntity$(); else nonTracking">
      <svg class="camera-icon title-bar-icon" viewBox="0 0 32 32" width="20" height="20" (click)="disableTracking()">
        <g stroke="white">
          <path
            d="M 13.84375 7.03125 C 11.412798 7.03125 9.46875 8.975298 9.46875 11.40625 L 9.46875 11.59375 L 2.53125 7.21875 L 2.53125 24.0625 L 9.46875 19.6875 C 9.4853444 22.104033 11.423165 24.0625 13.84375 24.0625 L 25.875 24.0625 C 28.305952 24.0625 30.28125 22.087202 30.28125 19.65625 L 30.28125 11.40625 C 30.28125 8.975298 28.305952 7.03125 25.875 7.03125 L 13.84375 7.03125 z"
            color="white"></path>
        </g>
      </svg>
    </ng-container>
    <ng-template #nonTracking>
      <svg class="camera-icon title-bar-icon" viewBox="0 0 32 32" width="20" height="20" (click)="enableTracking()">
        <g stroke="white">
          <path
            d="M 27.34375 1.65625 L 5.28125 27.9375 L 8.09375 30.3125 L 30.15625 4.03125 L 27.34375 1.65625 z M 13.84375 7.03125 C 11.412798 7.03125 9.46875 8.975298 9.46875 11.40625 L 9.46875 11.59375 L 2.53125 7.21875 L 2.53125 24.0625 L 9.46875 19.6875 C 9.4724893 20.232036 9.5676108 20.7379 9.75 21.21875 L 21.65625 7.03125 L 13.84375 7.03125 z M 28.21875 7.71875 L 14.53125 24.0625 L 25.875 24.0625 C 28.305952 24.0625 30.28125 22.087202 30.28125 19.65625 L 30.28125 11.40625 C 30.28125 9.8371439 29.456025 8.4902779 28.21875 7.71875 z"
            color="white"></path>
        </g>
      </svg>
    </ng-template>
  }
</ng-template>
