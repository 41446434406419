<!-- Error message -->
@if (error) {
  <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
    <clr-alert-item>
      <span class="alert-text">{{error}}</span>
    </clr-alert-item>
  </clr-alert>
} @else if (!error && !displayedSubmission$()) {
<!--  Loading spinner -->
  <div class="spinner-wrapper">
    <span class="spinner">Loading LAANC Submission...</span>
    <p>Loading LAANC Submission...</p>
  </div>
}

<!-- LAANC Submission Details -->
@if (displayedSubmission$()) {
  <div class="cesium-wrapper">
    <ax-cesium-container [timeline]="false"
                         [navigationInstructionsInitiallyVisible]="false"
                         [toolbarZIndex]="'999'"
                         [sceneModePicker]="false">
      <lib-infobox-container/>
      <app-entity-volume4d-drawer [volumes$]="[displayedSubmission$().volume]" [entityType$]="'laanc-submission'">
      </app-entity-volume4d-drawer>
    </ax-cesium-container>
  </div>

  @if (displayedSubmission$().type === laancSubmissionType.FURTHER_COORDINATION && displayedSubmission$().state === laancSubmissionState.DENIED) {
    <clr-alert [clrAlertType]="'warning'" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">The request for further coordination was denied by the controlling facility. The
          operation was denied for reasons that may include any of the following: altitude, date, time, or duration of
          the requested operation. Re-submitting the same request through LAANC will result in a denial. To continue
          pursuit of an authorization for the operation, consider altering part, or all, of the request and re-submitting
          either through LAANC or through FAA DroneZone. Re-submission through LAANC and/or FAA DroneZone does not
          guarantee the operation request will be approved.
          <br><br>Denial Reason: {{denialReason$() || 'Not Provided'}}</span>
      </clr-alert-item>
    </clr-alert>
  }

  @if (displayedSubmission$().type === laancSubmissionType.FURTHER_COORDINATION && displayedSubmission$().state === laancSubmissionState.PENDING) {
    <clr-alert [clrAlertType]="'info'" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">This further coordination request is pending. Please do not follow-up with Air Traffic
          by phone as it may result in the request being denied by the FAA.<br/><br/>
          Further coordination is a manual process with commensurate timelines. The FAA’s goal is to respond to Further
          coordination requests in less than 30 days, but it may take up to 90 days to either approve or deny and, in
          some cases, requests may expire without resolution.</span>
      </clr-alert-item>
    </clr-alert>
  }

  <table class="table">
    <tbody>
      <tr>
        <th scope="row">Operation Name</th>
        <td>{{displayedSubmission$().operationName}}</td>
      </tr>
      <tr>
        <th scope="row">LAANC Reference Code</th>
        <td>{{displayedSubmission$().referenceCode}}</td>
      </tr>
      <tr>
        <th scope="row">State</th>
        <td>{{humanizedLaancSubmissionStates[displayedSubmission$().state]}}</td>
      </tr>
      @if (denialReason$()) {
        <tr>
          <th scope="row">Denial Reason</th>
          <td>{{denialReason$() || 'Not Provided'}}</td>
        </tr>
      }
      <tr>
        <th scope="row">Category</th>
        <td>{{humanizedLaancSubmissionCategories[displayedSubmission$().category]}}</td>
      </tr>
      <tr>
        <th scope="row">Type</th>
        <td>{{humanizedLaancSubmissionTypes[displayedSubmission$().type]}}</td>
      </tr>
      <tr>
        <th scope="row">Airspace Classes</th>
        <td>
          @for (airspace of displayedSubmission$().airspaceClasses; track $index) {
            {{airspace + ($index !== displayedSubmission$().airspaceClasses.length - 1 ? ', ' : '')}}
          }
        </td>
      </tr>
      <tr>
        <th scope="row">Controlling Facility</th>
        <td>{{displayedSubmission$().faaFacilityCode}}</td>
      </tr>
      <tr>
        <th scope="row">Maximum Altitude</th>
        <td>{{displayedSubmission$().maximumAltitudeAgl}} FT AGL</td>
      </tr>
      <tr>
        <th scope="row">Start Time</th>
        <td>{{displayedSubmission$().volume.effective_time_begin}}</td>
      </tr>
      <tr>
        <th scope="row">End Time</th>
        <td>{{displayedSubmission$().volume.effective_time_end}}</td>
      </tr>
      <tr>
        <th scope="row">Operator Name</th>
        <td>{{displayedSubmission$().poc.firstName}} {{displayedSubmission$().poc.lastName}}</td>
      </tr>
      <tr>
        <th scope="row">Operator Phone Number</th>
        <td>{{displayedSubmission$().poc.pocPhoneNumber}}</td>
      </tr>
        @if (displayedSubmission$().registrationNumber) {
          <tr>
            <th scope="row">Registration Number</th>
            <td>{{displayedSubmission$().registrationNumber}}</td>
          </tr>
        }
      <tr>
        <th scope="row">Time Submitted</th>
        <td>{{displayedSubmission$().timeSubmitted | date:'yyyy-MM-ddTHH:mm:ss.SSSZ'}}</td>
      </tr>
      <tr>
        <th scope="row">Time Updated</th>
        <td>{{displayedSubmission$().timeUpdated}}</td>
      </tr>
      <tr>
        <th scope="row">Expiration Time</th>
        <td>{{displayedSubmission$().expiresAt}}</td>
      </tr>
    </tbody>
  </table>

  @if (displayedSubmission$().state === laancSubmissionState.AUTHORIZED) {
    @if (displayedSubmission$().category === laancSubmissionCategory.PART_107) {
      <p class="authorization-text" cds-text="body">{{displayedSubmission$().referenceCode}} | {{displayedSubmission$().faaFacilityCode}},
        {{displayedSubmission$().volume.effective_time_begin}} – {{displayedSubmission$().volume.effective_time_end}}, {{displayedSubmission$().maximumAltitudeAgl}}
        Ft: In accordance with Title 14 CFR Part 107.41, your operation is authorized within the designated airspace and
        timeframe constraints. Altitude limits are absolute values above ground level which shall not be added to the
        height of any structures. This Authorization is subject to cancellation at any time upon notice by the FAA
        Administrator or his/her authorized representative. This Authorization does not constitute a waiver of any State
        law or local ordinance. {{displayedSubmission$().poc.firstName}} {{displayedSubmission$().poc.lastName}} is the person designated as responsible for the overall
        safety of UAS operations under this Authorization. During UAS operations for on-site communication/recall,
        {{displayedSubmission$().poc.firstName}} {{displayedSubmission$().poc.lastName}} shall be continuously available for direct contact at
        {{displayedSubmission$().poc.pocPhoneNumber}} by Air Traffic. Remote pilots are responsible to check the airspace
        they are operating in and comply with all restrictions that may be present in accordance with 14 CFR 107.45 and
        107.49 (a)(2), such as restricted and Prohibited Airspace, Temporary Flight Restrictions, etc. Remote pilots are
        also responsible for complying with the operating requirements in 14 CFR 107.29(a) when operating at night.
        Operations are not authorized in Class E airspace when there is a weather ceiling less than 1,000 feet AGL. If
        the UAS loses communications or loses its GPS signal, it must return to a predetermined location within the
        operating area and land. The remote pilot in command must abort the flight in the event of unpredicted obstacles
        or emergencies. This certificate shall be presented for inspection upon the request of any authorized
        representative of the Federal Aviation Administration, or of any State or municipal official charged with the
        duty of enforcing local laws or regulations.</p>
    } @else if (displayedSubmission$().category === laancSubmissionCategory.SECTION_44809) {
      <p class="authorization-text" cds-text="body">{{displayedSubmission$().referenceCode}} | {{displayedSubmission$().faaFacilityCode}},
        {{displayedSubmission$().volume.effective_time_begin}} – {{displayedSubmission$().volume.effective_time_end}}, {{displayedSubmission$().maximumAltitudeAgl}}
        Ft: In accordance with 49 U.S.C. § 44809(a)(5), your operation is authorized within the designated airspace and
        timeframe constraints. Altitude limits are absolute values above ground level which shall not be added to the
        height of any structures. This Authorization is subject to cancellation at any time upon notice by the FAA
        Administrator or his/her authorized representative. This Authorization does not constitute a waiver of any State
        law or local ordinance. {{displayedSubmission$().poc.firstName}} {{displayedSubmission$().poc.lastName}} is the person designated as responsible for the overall
        safety of UAS operations under this Authorization. During UAS operations for on-site communication/recall,
        {{displayedSubmission$().poc.firstName}} {{displayedSubmission$().poc.lastName}} shall be continuously available for direct contact at
        {{displayedSubmission$().poc.pocPhoneNumber}} by Air Traffic. {{displayedSubmission$().poc.firstName}} {{displayedSubmission$().poc.lastName}} is responsible to
        check the airspace in which the UAS will be operated and comply with all restrictions that may be present in
        accordance with § 44809(a)(5), such as restricted and prohibited airspace, night operations, temporary flight
        restrictions, etc. This authorization is subject to the following conditions: (1) operations are not authorized
        in Class E surface area airspace when there is a weather ceiling less than 1,000 feet AGL; (2) if the UAS loses
        communications or loses its GPS signal, it must return to a predetermined location within the operating area and
        land; (3) night operations are only permitted if the operator has completed FAA training/testing and lighted
        their sUAS with anti-collision lighting visible for at least 3 statute miles that has a flash rate sufficient to
        avoid a collision. and (4) the person manipulating the controls of the UAS must abort the flight in the event of
        unpredicted obstacles or emergencies. This certificate shall be presented for inspection upon the request of any
        authorized representative of the Federal Aviation Administration, or of any State or municipal official charged
        with the duty of enforcing local laws or regulations.</p>
    }
  }
}
