import {DateTime} from 'luxon';
import {BaseModel} from './base-model';
import {IPolygon, Polygon} from './polygon.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Altitude, IAltitude} from './altitude.model';
import {GeoCircle, IGeoCircle, IRadius} from '../../Circle.model';

export type Entity4DType = 'constraint' | 'laanc-submission' | 'laanc-exclusion';

export interface IEntityVolume4d {
  effective_time_begin: DateTime;
  effective_time_end: DateTime;
  min_altitude: IAltitude;
  max_altitude: IAltitude;
  submitted_min_altitude?: IAltitude;
  submitted_max_altitude?: IAltitude;
  geography: IPolygon;
  circle?: IGeoCircle;
  submitted_radius?: IRadius;
}

export class EntityVolume4d extends BaseModel implements IEntityVolume4d {
  static effectiveTimeBeginFieldName = 'effective_time_begin';
  static effectiveTimeEndFieldName = 'effective_time_end';
  static minAltitudeFieldName = 'min_altitude';
  static maxAltitudeFieldName = 'max_altitude';
  static verticalReferenceFieldName = 'altitude_vertical_reference';
  static unitsOfMeasureFieldName = 'altitude_units';
  static geographyFieldName = 'geography';
  static circleFieldName = 'circle';
  static submittedRadiusFieldName = 'submitted_radius';

  /** Earliest time the constraint will use the constraint volume. It must be less than effectiveTimeEnd.
   * effectiveTimeBegin < effectiveTimeEnd MUST be true.
   */
  effective_time_begin: DateTime;

  /** Latest time the constraint will use the constraint volume. It must be greater than effectiveTimeBegin.
   * effectiveTimeBegin < effectiveTimeEnd MUST be true.
   */
  effective_time_end: DateTime;

  /** The minimum altitude for this constraint volume.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: min_altitude.altitude_value < max_altitude.altitude_value
   */
  min_altitude: Altitude;

  /** The maximum altitude for this constraint volume.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: min_altitude.altitude_value < max_altitude.altitude_value
   */
  max_altitude: Altitude;

  /**
   * The minimum altitude for this constraint volume, using the originally supplied vertical reference and unit of measure.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: submitted_min_altitude.altitude_value < submitted_max_altitude.altitude_value
   */
  submitted_min_altitude?: Altitude;

  /**
   * The maximum altitude for this constraint volume, using the originally supplied vertical reference and unit of measure.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: submitted_min_altitude.altitude_value < submitted_max_altitude.altitude_value
   */
  submitted_max_altitude?: Altitude;

  geography: Polygon;

  /**
   * A circular geometry consisting of a center pair of lat/lon coordinates and a radius in meters.
   */
  circle?: GeoCircle;

  /**
   * User submitted radius value with units of measure
   */
  submitted_radius?: IRadius;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @param useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IEntityVolume4d>, useFormGroupValuesToModel = false) {
    super();
    this.min_altitude = new Altitude();
    this.max_altitude = new Altitude();
    this.submitted_min_altitude = new Altitude();
    this.submitted_max_altitude = new Altitude();
    this.geography = new Polygon();
    this.circle = null;

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   *
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IEntityVolume4d>, useFormGroupValuestoModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuestoModel);
      this.effective_time_begin = this.getValue<DateTime>(rawValues, EntityVolume4d.effectiveTimeBeginFieldName);
      this.effective_time_end = this.getValue<DateTime>(rawValues, EntityVolume4d.effectiveTimeEndFieldName);
      this.min_altitude.setValues(rawValues.min_altitude, useFormGroupValuestoModel);
      this.max_altitude.setValues(rawValues.max_altitude, useFormGroupValuestoModel);
      this.submitted_min_altitude.setValues(rawValues.submitted_min_altitude, useFormGroupValuestoModel);
      this.submitted_max_altitude.setValues(rawValues.submitted_max_altitude, useFormGroupValuestoModel);
      this.geography.setValues(rawValues.geography, useFormGroupValuestoModel);
      this.circle = this.getValue<GeoCircle>(rawValues, EntityVolume4d.circleFieldName);
      this.submitted_radius = this.getValue<IRadius>(rawValues, EntityVolume4d.submittedRadiusFieldName);
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuestoModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[EntityVolume4d.effectiveTimeBeginFieldName].setValue(this.effective_time_begin);
    this.$formGroup.controls[EntityVolume4d.effectiveTimeEndFieldName].setValue(this.effective_time_end);
    this.min_altitude.setFormGroupValues();
    this.max_altitude.setFormGroupValues();
    this.submitted_min_altitude.setFormGroupValues();
    this.submitted_max_altitude.setFormGroupValues();
    this.geography.setFormGroupValues();
    this.circle?.setFormGroupValues();
    this.$formGroup.controls[EntityVolume4d.submittedRadiusFieldName].setValue(this.submitted_radius);
    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): FormGroup {
    if (!this._formGroup) {
      this._formGroup = new FormGroup({
        effectiveTimeBegin: new FormControl(this.effective_time_begin, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24),
          Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$')]),
        effectiveTimeEnd: new FormControl(this.effective_time_end, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24),
          Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$')]),
        minAltitude: this.min_altitude.$formGroup,
        maxAltitude: this.max_altitude.$formGroup,
        submittedMinAltitude: this.submitted_min_altitude.$formGroup,
        submittedMaxAltitude: this.submitted_max_altitude.$formGroup,
        geography: this.geography.$formGroup,
        submittedRadius: new FormControl(this.submitted_radius),
      });
      if (this.circle) {
        this._formGroup.addControl(EntityVolume4d.circleFieldName, this.circle.$formGroup);
      }
    }
    return this._formGroup;
  }
}
