import {Component, input} from '@angular/core';
import {LaancVolumePreview} from '../../../model/laanc/LaancVolumePreview';
import {ClrDatagridModule, ClrIconModule} from '@clr/angular';
import {DatePipe, NgForOf} from '@angular/common';
import {LaancSubmissionSummaryComponent} from '../laanc-submission-summary/laanc-submission-summary.component';
import {DEFAULT_LAANC_SUBMISSION_TYPE_UTIL, InfoboxContainerComponent} from '@ax-uss-ui/common';
import {CesiumComponentsModule} from '@ax/ax-angular-map-cesium';
import {CesiumModule} from '../../../cesium/cesium.module';
import {CdsIconModule} from '@cds/angular';
import {
  LaancVolumePreviewDrawerComponent
} from '../../../cesium/laanc-volume-preview-drawer/laanc-volume-preview-drawer.component';

@Component({
  selector: 'laanc-volume-previews',
  standalone: true,
  imports: [
    ClrDatagridModule,
    DatePipe,
    LaancSubmissionSummaryComponent,
    CesiumComponentsModule,
    CesiumModule,
    InfoboxContainerComponent,
    NgForOf,
    CdsIconModule,
    ClrIconModule,
    LaancVolumePreviewDrawerComponent
  ],
  templateUrl: './laanc-volume-previews.component.html',
  styleUrl: './laanc-volume-previews.component.scss'
})
export class LaancVolumePreviewsComponent {
  volumes$ = input.required<LaancVolumePreview[]>();

  humanizedLaancSubmissionTypes = DEFAULT_LAANC_SUBMISSION_TYPE_UTIL.humanized;
}
