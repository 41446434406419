import {IUserMessageData, UserMessageData} from './UserMessageData';
import {BaseModel} from '../gen/utm/base-model';
import {ConformanceAlertMessage, IConformanceAlertMessage} from './ConformanceAlertMessage';
import {IOperatorIntentResult, OperatorIntentResult} from './OperatorIntentResult';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ConstraintAlertMessage, IConstraintAlertMessage} from './ConstraintAlertMessage';
import {IOperationConflictAlertMessage, OperationConflictAlertMessage} from './OperationConflictAlertMessage';
import {IUserDirectMessage, UserDirectMessage} from './UserDirectMessage';
import {ApprovalAlertMessage, IApprovalAlertMessage} from './ApprovalAlertMessage';
import {ILaancAlertMessage, LaancAlertMessage} from './LaancAlertMessage';

export type IUserMessageDataType = IUserMessageData |  IOperatorIntentResult | IOperationConflictAlertMessage |
  IConformanceAlertMessage | IConstraintAlertMessage | IUserDirectMessage | IApprovalAlertMessage | ILaancAlertMessage; // | IOperationDraftMessage;

export type IUserMessageType = 'OPERATOR_INTENT_RESULT' | 'OPERATION_CONFLICT_ALERT' | 'CONFORMANCE_ALERT' |
  'CONSTRAINT_ALERT' | 'USER_DIRECT' | 'APPROVAL_ALERT' | 'LAANC_ALERT' | 'OPERATION_DRAFT_MESSAGE';

export interface IUserMessage {
  title: string;
  type: IUserMessageType;
  body: string;
  data?: IUserMessageDataType;
}

export type UserMessageDataType = UserMessageData | OperatorIntentResult | OperationConflictAlertMessage |
  ConformanceAlertMessage | ConstraintAlertMessage | UserDirectMessage | ApprovalAlertMessage | LaancAlertMessage; // | OperationDraftMessage;

export class UserMessage extends BaseModel implements IUserMessage {
  /* eslint-disable @typescript-eslint/naming-convention */
  static TITLE_FIELD_NAME = 'title';
  static TYPE_FIELD_NAME = 'type';
  static BODY_FIELD_NAME = 'body';
  static DATA_FIELD_NAME = 'data';
  /* eslint-disable @typescript-eslint/naming-convention */
  title: string;
  type: IUserMessageType;
  body: string;
  data: UserMessageDataType;

  constructor(values?: Partial<IUserMessage>, useFormGroupValuesToModel = false) {
    super();

    this.data = new UserMessageData();

    if (values) {
      this.initData(values);
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IUserMessage>, useFormGroupValuesToModel = false): void {
    if (values) {

      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.title = this.getValue<string>(rawValues, UserMessage.TITLE_FIELD_NAME);

      this.body = this.getValue<string>(rawValues, UserMessage.BODY_FIELD_NAME);
      if (this.type !== values.type) {
        this.initData(values);
        // eslint-disable-next-line no-underscore-dangle
        if (this._formGroup) {
          this.$formGroup.controls.data = this.data.$formGroup;
        }
        this.setFormGroupValues();
      }
      this.type = this.getValue<IUserMessageType>(rawValues, UserMessage.TYPE_FIELD_NAME);
      this.data.setValues(rawValues.data, useFormGroupValuesToModel);

      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[UserMessage.TITLE_FIELD_NAME].setValue(this.title);
    this.$formGroup.controls[UserMessage.TYPE_FIELD_NAME].setValue(this.type);
    this.$formGroup.controls[UserMessage.BODY_FIELD_NAME].setValue(this.body);
    this.data.setFormGroupValues();

    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): UntypedFormGroup {
    /* eslint-disable no-underscore-dangle */
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        title: new UntypedFormControl(this.title, [Validators.required]),
        type: new UntypedFormControl(this.type, [Validators.required]),
        body: new UntypedFormControl(this.body, [Validators.required]),
        data: this.data.$formGroup
      });
    }
    return this._formGroup;
    /* eslint-enable no-underscore-dangle */
  }

  private initData(values: Partial<IUserMessage>) {
    if (values.data) {
      switch (values.type) {
        case 'OPERATOR_INTENT_RESULT':
          this.data = new OperatorIntentResult(values.data as IOperatorIntentResult);
          break;
        case 'OPERATION_CONFLICT_ALERT':
          this.data = new OperationConflictAlertMessage(values.data as IOperationConflictAlertMessage);
          break;
        case 'CONFORMANCE_ALERT':
          this.data = new ConformanceAlertMessage(values.data as IConformanceAlertMessage);
          break;
        case 'CONSTRAINT_ALERT':
          this.data = new ConstraintAlertMessage(values.data as IConstraintAlertMessage);
          break;
        case 'USER_DIRECT':
          this.data = new UserDirectMessage(values.data as IUserDirectMessage);
          break;
        case 'APPROVAL_ALERT':
          this.data = new ApprovalAlertMessage(values.data as IApprovalAlertMessage);
          break;
        case 'LAANC_ALERT':
          this.data = new LaancAlertMessage(values.data as ILaancAlertMessage);
          break;
        // case 'OPERATION_DRAFT_MESSAGE':
        //   this.data = new OperationDraftMessage(values.data as IOperationDraftMessage);
        //   break;
      }
    }
  }

}



