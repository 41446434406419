import {Camera, Math} from "@cesium/engine";
import {EnvelopeBounds} from "../services/airspace.service";

export function getBoundingBox(camera: Camera): EnvelopeBounds {
  const rectRadians = camera.computeViewRectangle();
  const rectDegrees = {
    north: Math.toDegrees(rectRadians.north),
    south: Math.toDegrees(rectRadians.south),
    east: Math.toDegrees(rectRadians.east),
    west: Math.toDegrees(rectRadians.west)
  };

  return [rectDegrees.west, rectDegrees.north, rectDegrees.east, rectDegrees.south];
}
