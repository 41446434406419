import {units_of_measure, vertical_reference} from '../gen/utm';
import {IOperationVolumeSubmission} from '../OperationSubmission';
import {IOperationVolumeSubmission as TransportOperationVolume} from '../gen/transport/utm';
import {BaseModel, BaseModelSerializer, LaancSubmissionCategory} from '@ax-uss-ui/common';
import {UTMSerializer} from '../UTMSerializer';

export interface ILaancCheckRequest {
  vertical_reference: vertical_reference;
  units: units_of_measure;
  volumes: IOperationVolumeSubmission[];
  category: LaancSubmissionCategory;
}

export interface TLaancCheckRequest {
  altitude_vertical_reference: vertical_reference;
  altitude_units: units_of_measure;
  volumes: TransportOperationVolume[];
  category: LaancSubmissionCategory;
}

export class LaancCheckRequest implements ILaancCheckRequest, BaseModel {
  vertical_reference: vertical_reference;
  units: units_of_measure;
  volumes: IOperationVolumeSubmission[];
  category: LaancSubmissionCategory;

  constructor(values: ILaancCheckRequest) {
    this.vertical_reference = values.vertical_reference;
    this.units = values.units;
    this.volumes = values.volumes;
    this.category = values.category;
  }
}

export class LaancCheckRequestUtil implements BaseModelSerializer<TLaancCheckRequest, LaancCheckRequest> {
  serialize(obj: LaancCheckRequest): TLaancCheckRequest {
    return {
      altitude_vertical_reference: obj.vertical_reference,
      altitude_units: obj.units,
      volumes: (obj.volumes || []).map(vol => UTMSerializer.serializeOperationVolume(vol)),
      category: obj.category
    };
  }
}

export const DEFAULT_LAANC_CHECK_REQUEST_UTIL = new LaancCheckRequestUtil();
