import {BaseEnumDeserializer} from '../../common';
import {fail, Result, some} from '../../../utils';

export enum OperationLaancStatus {
  PENDING = 'PENDING',
  AUTHORIZED = 'AUTHORIZED',
  DENIED = 'DENIED',
  /** Temp compat */
  OTHER = 'OTHER'
}

export class OperationLaancStatusUtil implements BaseEnumDeserializer<OperationLaancStatus, OperationLaancStatus> {
  deserialize(raw: unknown): Result<OperationLaancStatus> {
    if (typeof raw !== 'string') return fail('Invalid type for operation LAANC status');

    switch (raw.toUpperCase()) {
      case 'PENDING':
        return some(OperationLaancStatus.PENDING);
      case 'AUTHORIZED':
        return some(OperationLaancStatus.AUTHORIZED);
      case 'DENIED':
        return some(OperationLaancStatus.DENIED);
      case 'OTHER':
        return some(OperationLaancStatus.OTHER);
      default:
        return fail(`Invalid operation LAANC status: ${raw}`);
    }
  }
}

export const DEFAULT_OPERATION_LAANC_STATUS_UTIL = new OperationLaancStatusUtil();
