@if (laancResponse$()) {
  <h2 cds-text="section"><strong>LAANC Status: {{humanizedLaancRequirements[laancResponse$().requirement] || 'Unknown'}}</strong></h2>

  @if (laancResponse$().requirement !== laancRequirement.NOT_APPLICABLE) {
    <div class="disclaimers">
      @if (laancResponse$().requirement === laancRequirement.REQUIRE_FURTHER_COORDINATION) {
        <p cds-text="secondary regular">Further coordination is a manual process with commensurate timelines. The FAA’s
          goal is to respond to further coordination requests in less than 30 days, but it may take up to 90 days to
          either approve or deny and, in some cases, requests may expire without resolution.</p>
      }
      <p cds-text="secondary regular">AX Enterprize LAANC USS cannot ensure the completion or accuracy of all displayed
        TFR data. Remote pilots are responsible for checking the airspace they are operating in per Part 107.49 and
        complying with all restrictions that may be present such as restricted and prohibited airspace, temporary flight
        restrictions, etc. To obtain the most accurate and up to date information operators should check
        <a href="https://tfr.faa.gov/tfr2/list.html" target="_blank">tfr.faa.gov</a> and ensure their operation(s) do
        not overlap with any active TFRs or other restricted airspaces prior to launch.</p>
    </div>
  }

  <div class="submission-controls">
    <!-- Display submission confirmation checkbox for auto-approval and further coordination operations  -->
    @if (displayConsentCheckbox$()) {
      <clr-checkbox-wrapper>
        @if (laancResponse$().requirement === laancRequirement.REQUIRE_AUTO_APPROVAL) {
          <label>I agree to submit this operation for automatic approval</label>
        } @else if (laancResponse$().requirement === laancRequirement.REQUIRE_FURTHER_COORDINATION) {
          <label>I agree to submit this operation for further coordination</label>
        }
        <input autocomplete="off" type="checkbox" clrCheckbox [(ngModel)]="submissionConsentGiven"/>
      </clr-checkbox-wrapper>
    }

    @if (laancResponse$().requirement !== laancRequirement.BLOCKED) {
      <button class="btn btn-primary"
              [disabled]="displayConsentCheckbox$() && !submissionConsentGiven"
              (click)="submitOperation$.emit(laancRequirement)">Submit Operation</button>
    }
  </div>

  @if (laancResponse$().volumes?.length) {
    <div class="cesium-wrapper">
      <ax-cesium-container [timeline]="false"
                           [navigationInstructionsInitiallyVisible]="false"
                           [toolbarZIndex]="'999'"
                           [sceneModePicker]="false">
        <lib-infobox-container/>
        @if (laancResponse$().uasfms?.length) {
          <czml-drawer [czml$]="laancResponse$().uasfms"></czml-drawer>
        }
        <laanc-volume-preview-drawer [volumes$]="laancResponse$().volumes"></laanc-volume-preview-drawer>
      </ax-cesium-container>
    </div>

    <div class="card card-neutral">
      <div class="card-header">Submission Volumes</div>
      <div class="card-block">
        <div class="card-text">
          <clr-tree>
            <clr-tree-node>
              Expand Details
              <clr-tree-node>
                <laanc-volume-previews [volumes$]="laancResponse$().volumes"></laanc-volume-previews>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree>
        </div>
      </div>
    </div>
  }

  @for (blocker of laancResponse$().blocking; track $index) {
    <div class="card card-danger">
      <div class="card-header">
        <cds-icon shape="exclamation-circle" [solid]="true" status="danger" size="md"></cds-icon>{{humanizedFrRules[blocker]}}
      </div>
        @switch (blocker) {
          @case (laancFrRules.LAANC_DOWN_PROTRACTED) {
            <div class="card-block">
              <div class="card-text">
                The FAA LAANC System is currently unavailable and unable to process new or modify airspace authorization
                requests. Previously approved airspace authorizations (those issued with an FAA reference number) remain
                valid unless you are informed otherwise by [USS] or the FAA. The FAA's DroneZone portal is an alternative
                source to make new airspace authorization requests. Processing times may vary, and you must receive an
                authorization approval (issued with an FAA reference number) before you can fly. Visit
                <a href="https://faadronezone.faa.gov/" target="_blank">https://faadronezone.faa.gov/</a> for additional
                details. Updates about the current LAANC outage including return to service information may be available
                on the FAA’s website or social media channels.
              </div>
            </div>
          }
          @case (laancFrRules.UASFM_NOT_ENABLED) {
            <div class="card-block">
              <div class="card-text">
                Please submit this operation through the FAA's DroneZone portal. Visit
                <a href="https://faadronezone.faa.gov/" target="_blank">https://faadronezone.faa.gov/</a> for additional
                details.
              </div>
            </div>
          }
        }
    </div>
  }

  @for (restriction of laancResponse$().nsufrsFullTime; track $index) {
    <div class="card card-danger">
      <div class="card-header">
        <cds-icon shape="exclamation-circle" [solid]="true" status="danger" size="md"></cds-icon>National Security UAS Flight Restriction
      </div>
      <div class="card-block">
        <div class="card-text">
          <clr-tree>
            <clr-tree-node>
              Expand Details
              <clr-tree-node>
                <div class="treenode-content-wrapper">
                  <table class="table table-vertical table-compact table-noborder">
                    <tbody>
                      @if (restriction.proponent) {
                        <tr>
                          <th scope="row">Proponent</th>
                          <td>{{restriction.proponent}}</td>
                        </tr>
                      }
                      @if (restriction.branch) {
                        <tr>
                          <th scope="row">Branch</th>
                          <td>{{restriction.branch}}</td>
                        </tr>
                      }
                      @if (restriction.base) {
                        <tr>
                          <th scope="row">Base</th>
                          <td>{{restriction.base}}</td>
                        </tr>
                      }
                      @if (restriction.airspace) {
                        <tr>
                          <th scope="row">Airspace</th>
                          <td>{{restriction.airspace}}</td>
                        </tr>
                      }
                      @if (restriction.faaId) {
                        <tr>
                          <th scope="row">FAA ID</th>
                          <td>{{restriction.faaId}}</td>
                        </tr>
                      }
                      @if (restriction.poc) {
                        <tr>
                          <th scope="row">POC</th>
                          <td>{{restriction.poc}}</td>
                        </tr>
                      }
                      @if (restriction.reason) {
                        <tr>
                          <th scope="row">Reason</th>
                          <td>{{restriction.reason}}</td>
                        </tr>
                      }
                      @if (restriction.county) {
                        <tr>
                          <th scope="row">County</th>
                          <td>{{restriction.county}}</td>
                        </tr>
                      }
                      @if (restriction.state) {
                        <tr>
                          <th scope="row">State</th>
                          <td>{{restriction.state}}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree>
        </div>
      </div>
    </div>
  }

  @for (restriction of laancResponse$().nsufrsPartTime; track $index) {
    <div class="card card-danger">
      <div class="card-header">
        <cds-icon shape="exclamation-circle" [solid]="true" status="danger" size="md"></cds-icon>Part-Time National Security
        UAS Flight Restriction
      </div>
      <div class="card-block">
        <div class="card-text">
          <clr-tree>
            <clr-tree-node>
              Expand Details
              <clr-tree-node>
                <div class="treenode-content-wrapper">
                  <table class="table table-vertical table-compact table-noborder">
                    <tbody>
                      @if (restriction.proponent) {
                        <tr>
                          <th scope="row">Proponent</th>
                          <td>{{restriction.proponent}}</td>
                        </tr>
                      }
                      @if (restriction.branch) {
                        <tr>
                          <th scope="row">Branch</th>
                          <td>{{restriction.branch}}</td>
                        </tr>
                      }
                      @if (restriction.base) {
                        <tr>
                          <th scope="row">Base</th>
                          <td>{{restriction.base}}</td>
                        </tr>
                      }
                      @if (restriction.airspace) {
                        <tr>
                          <th scope="row">Airspace</th>
                          <td>{{restriction.airspace}}</td>
                        </tr>
                      }
                      @if (restriction.faaId) {
                        <tr>
                          <th scope="row">FAA ID</th>
                          <td>{{restriction.faaId}}</td>
                        </tr>
                      }
                      @if (restriction.poc) {
                        <tr>
                          <th scope="row">POC</th>
                          <td>{{restriction.poc}}</td>
                        </tr>
                      }
                      @if (restriction.reason) {
                        <tr>
                          <th scope="row">Reason</th>
                          <td>{{restriction.reason}}</td>
                        </tr>
                      }
                      @if (restriction.county) {
                        <tr>
                          <th scope="row">County</th>
                          <td>{{restriction.county}}</td>
                        </tr>
                      }
                      @if (restriction.state) {
                        <tr>
                          <th scope="row">State</th>
                          <td>{{restriction.state}}</td>
                        </tr>
                      }
                      @if (restriction.activeTime) {
                        <tr>
                          <th scope="row">Active Time</th>
                          <td>{{restriction.activeTime}}</td>
                        </tr>
                      }
                      @if (restriction.endTime) {
                        <tr>
                          <th scope="row">End Time</th>
                          <td>{{restriction.endTime}}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree>
        </div>
      </div>
    </div>
  }

  @for (tfr of laancResponse$().tfrs; track $index) {
    <div class="card card-danger">
      <div class="card-header">
        <cds-icon shape="exclamation-circle" [solid]="true" status="danger" size="md"></cds-icon>Temporary Flight Restriction
      </div>
      <div class="card-block">
        <div class="card-text">
          <clr-tree>
            <clr-tree-node>
              Expand Details
              <clr-tree-node>
                <div class="treenode-content-wrapper">
                  <div class="disclaimers">
                    <p cds-text="secondary regular">AX Enterprize LAANC USS cannot ensure the completion or accuracy of all displayed
                      TFR data. Remote pilots are responsible for checking the airspace they are operating in per Part 107.49 and
                      complying with all restrictions that may be present such as restricted and prohibited airspace, temporary flight
                      restrictions, etc. To obtain the most accurate and up to date information operators should check
                      <a href="https://tfr.faa.gov/tfr2/list.html" target="_blank">tfr.faa.gov</a> and ensure their operation(s) do
                      not overlap with any active TFRs or other restricted airspaces prior to launch.</p>
                  </div>
                  <table class="table table-vertical table-compact table-noborder">
                    <tbody>
                      @if (tfr.id) {
                        <tr>
                          <th scope="row">ID</th>
                          <td>{{tfr.id}}</td>
                        </tr>
                      }
                      @if (tfr.url) {
                        <tr>
                          <th scope="row">URL</th>
                          <td><a href="{{tfr.url}}" target="_blank">{{tfr.url}}</a></td>
                        </tr>
                      }
                      @if (tfr.date) {
                        <tr>
                          <th scope="row">Date</th>
                          <td>{{tfr.date}}</td>
                        </tr>
                      }
                      @if (tfr.type) {
                        <tr>
                          <th scope="row">Type</th>
                          <td>{{tfr.type}}</td>
                        </tr>
                      }
                      @if (tfr.description) {
                        <tr>
                          <th scope="row">Description</th>
                          <td>{{tfr.description}}</td>
                        </tr>
                      }
                      @if (tfr.timeRange?.timeIssued) {
                        <tr>
                          <th scope="row">Time Issued</th>
                          <td>{{tfr.timeRange.timeIssued}}</td>
                        </tr>
                      }
                      @if (tfr.timeRange?.timeEffective) {
                        <tr>
                          <th scope="row">Time Effective</th>
                          <td>{{tfr.timeRange.timeEffective}}</td>
                        </tr>
                      }
                      @if (tfr.timeRange?.timeExpires) {
                        <tr>
                          <th scope="row">Time Expires</th>
                          <td>{{tfr.timeRange.timeExpires}}</td>
                        </tr>
                      }
                      @if (tfr.state) {
                        <tr>
                          <th scope="row">State</th>
                          <td>{{tfr.state}}</td>
                        </tr>
                      }
                      @if (tfr.facility) {
                        <tr>
                          <th scope="row">Facility</th>
                          <td>{{tfr.facility}}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree>
        </div>
      </div>
    </div>
  }

  @for (sua of laancResponse$().specialUseAirspaces; track $index) {
    <div class="card" [ngClass]="{'card-danger': sua.restricted, 'card-warning': !sua.restricted}">
      <div class="card-header">
        @if (sua.restricted) {
          <cds-icon shape="exclamation-circle" [solid]="true" status="danger" size="md"></cds-icon>Restricted Special Use Airspace: {{sua.name}}
        } @else {
          <cds-icon shape="warning-standard" [solid]="true"></cds-icon>Special Use Airspace: {{sua.name}}
        }
      </div>
      <div class="card-block">
        <div class="card-text">
          <clr-tree>
            <clr-tree-node>
              Expand Details
              <clr-tree-node>
                <div class="treenode-content-wrapper">
                  <a href="https://sua.faa.gov/sua/siteFrame.app" target="_blank">FAA SUA Search</a>
                  <table class="table table-vertical table-compact table-noborder">
                    <tbody>
                      @if (sua.name) {
                        <tr>
                          <th scope="row">Name</th>
                          <td>{{sua.name}}</td>
                        </tr>
                      }
                      @if (sua.restricted) {
                        <tr>
                          <th scope="row">Restricted</th>
                          <td>{{sua.restricted}}</td>
                        </tr>
                      }
                      @if (sua.typeCode) {
                        <tr>
                          <th scope="row">Type Code</th>
                          <td>{{sua.typeCode}}</td>
                        </tr>
                      }
                      @if (sua.timesOfUse) {
                        <tr>
                          <th scope="row">Times of Use</th>
                          <td>{{sua.timesOfUse}}</td>
                        </tr>
                      }
                      @if (sua.class) {
                        <tr>
                          <th scope="row">Class</th>
                          <td>{{sua.class}}</td>
                        </tr>
                      }
                      @if (sua.city) {
                        <tr>
                          <th scope="row">City</th>
                          <td>{{sua.city}}</td>
                        </tr>
                      }
                      @if (sua.state) {
                        <tr>
                          <th scope="row">State</th>
                          <td>{{sua.state}}</td>
                        </tr>
                      }
                      @if (sua.country) {
                        <tr>
                          <th scope="row">Country</th>
                          <td>{{sua.country}}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree>
        </div>
      </div>
    </div>
  }

  @for (advisory of laancResponse$().advisories; track $index) {
    <div class="card card-warning">
      <div class="card-header">
        <cds-icon shape="warning-standard" [solid]="true"></cds-icon>{{humanizedFrRules[advisory]}}
      </div>
      @switch (advisory) {
        @case (laancFrRules.AT_NIGHT_PART_107) {
         <div class="card-block">
           <div class="card-text">
             Operating a sUAS at night is only permitted if: (1) the operator has completed the required FAA training/testing,
             and (2) the sUAS is lighted with anti-collision lighting visible for at least 3 statute miles that has a flash
             rate sufficient to avoid a collision.
           </div>
         </div>
        }
        @case (laancFrRules.AT_NIGHT_44809) {
          <div class="card-block">
            <div class="card-text">
              When operating a sUAS at night, it is recommended that sUAS should be lit with anti-collision lighting
              visible for at least 3 statute miles away and arrange lights on the UA in such a way that allows recreational
              flyers to determine the orientation and flight path of the aircraft. For additional guidance, refer to “Night
              Flight” in
              <a href="https://www.faa.gov/regulations_policies/advisory_circulars/index.cfm/go/document.information/documentID/1041362"
                 target="_blank">Advisory Circular 91-57.</a>
            </div>
          </div>
        }
        @case (laancFrRules.AT_CIVIL_TWILIGHT) {
          <div class="card-block">
            <div class="card-text">
              During civil twilight periods, operating a sUAS is only permitted if the sUAS is lighted with anticollision
              lighting visible for at least 3 statute miles that has a flash rate sufficient to avoid a collision.
            </div>
          </div>
        }
        @case (laancFrRules.EXISTING_OPERATION) {
          <div class="card-block">
            <div class="card-text">
              There are one or more operations for this operator and time period. The operator should cancel all operations
              that will not be used before the operation start time.
            </div>
          </div>
        }
        @case (laancFrRules.WITHIN_3NM_OF_STADIUM) {
          <div class="card-block">
            <div class="card-text">
              This operation is located within 3 NM of an FAA-designated stadium or sporting venue and is prohibited from
              occurring 1 hour before the scheduled start time until 1 hour after the end of a qualifying event.
            </div>
          </div>
        }
        @case (laancFrRules.CLASS_E_AIRSPACE) {
          <div class="card-block">
            <div class="card-text">
              This operation is located within Class E airspace. When the weather ceiling is less than 1,000 ft, approvals
              obtained via LAANC are not valid in Class E Surface Area airspace. It is strongly advised that operators check
              <a href="https://aviationweather.gov/" target="_blank">aviationweather.gov</a> for the latest weather data
              including Meteorological Aerodrome Reports (METARs) and Terminal Aerodrome Forecast (TAF) reports.
            </div>
          </div>
        }
      }
    </div>
  }

  @for (airspace of laancResponse$().classAirspaces; track $index) {
    <div class="card card-neutral">
      <div class="card-header">
        <cds-icon shape="info-standard"></cds-icon>Class Airspace: {{airspace.name}}
      </div>
      <div class="card-block">
        <div class="card-text">
          <clr-tree>
            <clr-tree-node>
              Expand Details
              <clr-tree-node>
                <div class="treenode-content-wrapper">
                  <table class="table table-vertical table-compact table-noborder">
                    <tbody>
                      @if (airspace.name) {
                        <tr>
                          <th scope="row">Name</th>
                          <td>{{airspace.name}}</td>
                        </tr>
                      }
                      @if (airspace.class) {
                        <tr>
                          <th scope="row">Class</th>
                          <td>{{airspace.class}}</td>
                        </tr>
                      }
                      @if (airspace.city) {
                        <tr>
                          <th scope="row">City</th>
                          <td>{{airspace.city}}</td>
                        </tr>
                      }
                      @if (airspace.state) {
                        <tr>
                          <th scope="row">State</th>
                          <td>{{airspace.state}}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree>
        </div>
      </div>
    </div>
  }

  @for (airport of laancResponse$().airports; track $index) {
    <div class="card card-neutral">
      <div class="card-header">
        <cds-icon shape="info-standard"></cds-icon>Airport: {{airport.name}}
      </div>
      <div class="card-block">
        <div class="card-text">
          <clr-tree>
            <clr-tree-node>
              Expand Details
              <clr-tree-node>
                <div class="treenode-content-wrapper">
                  <table class="table table-vertical table-compact table-noborder">
                    <tbody>
                      @if (airport.name) {
                        <tr>
                          <th scope="row">Name</th>
                          <td>{{airport.name}}</td>
                        </tr>
                      }
                      @if (airport.servCity) {
                        <tr>
                          <th scope="row">City</th>
                          <td>{{airport.servCity}}</td>
                        </tr>
                      }
                      @if (airport.state) {
                        <tr>
                          <th scope="row">State</th>
                          <td>{{airport.state}}</td>
                        </tr>
                      }
                      @if (airport.country) {
                        <tr>
                          <th scope="row">Country</th>
                          <td>{{airport.country}}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree>
        </div>
      </div>
    </div>
  }

  @for (stadium of laancResponse$().stadiums; track $index) {
    <div class="card card-neutral">
      <div class="card-header">
        <cds-icon shape="info-standard"></cds-icon>Stadium: {{stadium.name}}
      </div>
      <div class="card-block">
        <div class="card-text">
          <clr-tree>
            <clr-tree-node>
              Expand Details
              <clr-tree-node>
                <div class="treenode-content-wrapper">
                  <p>The proposed operation is located within 3NM of an FAA designated stadium or sporting venue and
                    is prohibited from flying 1hr before the scheduled start time until 1hr after the end of a
                    qualifying event.</p>
                  <table class="table table-vertical table-compact table-noborder">
                    <tbody>
                      @if (stadium.name) {
                        <tr>
                          <th scope="row">Name</th>
                          <td>{{stadium.name}}</td>
                        </tr>
                      }
                      @if (stadium.city) {
                        <tr>
                          <th scope="row">City</th>
                          <td>{{stadium.city}}</td>
                        </tr>
                      }
                      @if (stadium.state) {
                        <tr>
                          <th scope="row">State</th>
                          <td>{{stadium.state}}</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                </div>
              </clr-tree-node>
            </clr-tree-node>
          </clr-tree>
        </div>
      </div>
    </div>
  }
}
