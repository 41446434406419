<form [formGroup]="fg">
  <!-- Small screens: Show geometry editor & Cesium preview in modal -->
  @switch (deviceSize$()) {
    @case ('sm') {
      <button type="button" class="btn btn-icon btn-draw-operation" (click)="showGeometryMap = true">
        <cds-icon shape="pencil"></cds-icon>
        Draw Operation
      </button>
      <div class="volumes-section">
        <h3 cds-text="section">Volume Details</h3>
        <ng-container *ngTemplateOutlet="volumesDetails"></ng-container>
      </div>
    }
    @case ('md') {
      <!--Medium screens: Show geometry editor & Cesium preview in tabbed interface-->
      <div class="volumes-section">
        <ng-container *ngTemplateOutlet="tabbedMapsLayout"></ng-container>
        <ng-container *ngTemplateOutlet="volumesDetails"></ng-container>
      </div>
    }
    @case ('lg') {
      <!--Large screens: Show geometry editor & Cesium preview side-by-side-->
      <div class="clr-row full-editor volumes-section">
        <div class="clr-col-md-6">
          <h4 class="map-heading">
            <cds-icon shape="note"></cds-icon>
            Edit
          </h4>
          <ng-container *ngTemplateOutlet="leafletContainer"></ng-container>
        </div>
        <div class="clr-col-md-6">
          <h4 class="map-heading">
            <cds-icon shape="eye"></cds-icon>
            Preview
          </h4>
          <ng-container *ngTemplateOutlet="cesiumContainer"></ng-container>
        </div>
        <div class="clr-col-12">
          <ng-container *ngTemplateOutlet="volumesDetails"></ng-container>
        </div>
      </div>
    }
  }

  <!-- Template for tabbed maps layout -->
  <ng-template #tabbedMapsLayout>
    <clr-tabs class="tabbed-editor">
      <clr-tab>
        <button clrTabLink>Edit</button>
        <clr-tab-content *clrIfActive>
          <ng-container *ngTemplateOutlet="leafletContainer"></ng-container>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink [disabled]="fg.invalid">Preview</button>
        <clr-tab-content *clrIfActive>
          <ng-container *ngTemplateOutlet="cesiumContainer"></ng-container>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>
  </ng-template>

  <!-- Template for Leaflet map editor -->
  <ng-template #leafletContainer>
    <ax-leaflet-container>
      <lib-geocoder [geocoder]="geocoder$()" (bounds)="handleGeoCodeChange($event)"/>


      <app-import-export [type]="importExportType.OPERATIONVOLUME"
                         (importResult)="handleOperationVolumeImport($event)"
                         [disableExport]="fg.invalid"
                         [exportData]="currentGeometry"
                         (exportResult)="handleOperationVolumeExport($event)"
      ></app-import-export>

      <leaflet-expose-map (mapInit)="handleMapInit($event)"></leaflet-expose-map>

      <app-leaflet-volume-creator
        [name]="'OperationVolume'"
        [title]="'Draw Operation Volume'"
        [colorConfig]="colorConfig"
        (newVolume)="createVolume($event)">
      </app-leaflet-volume-creator>

      <app-leaflet-circle-creator
        [name]="'OperationVolumeCircle'"
        [title]="'Draw Operation Circle Volume'"
        [colorConfig]="colorConfig"
        [selectedUnits]="selectedUnits"
        (newVolume)="createVolume($event)">
      </app-leaflet-circle-creator>

      <app-leaflet-point-creator
        [name]="'ControllerLocation'"
        [title]="'Controller Location'"
        [controlClassName]="'fa fa-gamepad'"
        [icon]="controllerIcon"
        [formControlName]="'controllerLocation'">
      </app-leaflet-point-creator>

      <app-leaflet-point-creator
        [name]="'TakeOffLocation'"
        [title]="'Take Off Location'"
        [controlClassName]="'drone-icon'"
        [icon]="takeOffIcon"
        [formControlName]="'takeOffLocation'">
      </app-leaflet-point-creator>

      <app-leaflet-sitrep-drawer [enabled]="sitRepEnabled" [altRef]="selectedVertRef" [altUnits]="selectedUnits"
                                 (enabledChange)="this.updateSitMode($event)"></app-leaflet-sitrep-drawer>

      <ng-container *ngFor="let volumeForm of volumeFgArrayAsFormGroups; let i = index">
        <app-leaflet-polygon-editor
          *ngIf="volumeForm.controls.geography?.value"
          [colorConfig]="colorConfig"
          [formControl]="volumeForm.controls.geography"
          [index]="i">
        </app-leaflet-polygon-editor>

        <app-leaflet-circle-editor
          *ngIf="volumeForm.controls.circle?.value"
          [colorConfig]="colorConfig"
          [formControl]="volumeForm.controls.circle"
          [index]="i">
        </app-leaflet-circle-editor>
      </ng-container>

      <app-leaflet-polyline-drawer [points]="points" [color]="'#fcba03'"
                                   [fillColor]="'#fcba03'"></app-leaflet-polyline-drawer>
    </ax-leaflet-container>
  </ng-template>

  <!-- Template for 3D Cesium preview -->
  <ng-template #cesiumContainer>
    <div class="cesium-wrapper">
      <ax-cesium-container [timeline]="false"
                           [navigationInstructionsInitiallyVisible]="false"
                           [projectionPicker]="true"
                           [toolbarZIndex]="'999'"
                           [sceneModePicker]="false">
        <lib-expose-viewer (viewer)="previewViewer.set($event)" />
        <app-operation-geometry-drawer *ngIf="previewGeometry" [geometry]="previewGeometry" [colorConfig]="colorConfig">
        </app-operation-geometry-drawer>

        @if (sitRepEnabled) {
          <app-operation-sitrep-drawer [altRef]="selectedVertRef"
                                       [altUnits]="selectedUnits"></app-operation-sitrep-drawer>
          <app-constraint-sitrep-drawer [altRef]="selectedVertRef"
                                        [altUnits]="selectedUnits"></app-constraint-sitrep-drawer>
        }


        @if (ussSettings$()?.laanc?.enabled) {
          <app-laanc-drawer [type]="LaancCZMLEntityType.FACILITY_MAP" *ngIf="fasMapEnabled"/>
          <app-laanc-drawer [type]="LaancCZMLEntityType.CLASS_AIRSPACE" *ngIf="clsAirEnabled"/>
          <app-laanc-drawer [type]="LaancCZMLEntityType.SPECIAL_USE_AIRSPACE" *ngIf="suaEnabled"/>
          <app-laanc-drawer [type]="LaancCZMLEntityType.AIRPORTS" *ngIf="airportsEnabled"/>
          <app-laanc-drawer [type]="LaancCZMLEntityType.STADIUMS" *ngIf="stadiumsEnabled"/>
          <app-laanc-drawer [type]="LaancCZMLEntityType.NSUFRS" *ngIf="nsufrsEnabled"/>
          <app-laanc-drawer [type]="LaancCZMLEntityType.DC_FRZ" *ngIf="dcFrzEnabled"/>
          <app-laanc-drawer [type]="LaancCZMLEntityType.TFRS" *ngIf="tfrsEnabled"/>
        }
        <lib-infobox-container/>

        <lib-toolbar-extra-button-container>
          <lib-cesium-toolbar-dropdown [title]="'Overlays'" [cdsIconName]="'layers'">
            <ng-container clrForm clrLayout="vertical">
              <clr-toggle-container style="margin-top: 0px; min-width: 200px">
                <clr-toggle-wrapper>
                  <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="sitRepEnabled"/>
                  <label style="color: white!important">Situational Awareness</label>
                </clr-toggle-wrapper>
              </clr-toggle-container>
              @if (ussSettings$()?.laanc?.enabled) {
                <clr-toggle-container>
                  <label style="color: white!important">LAANC Overlays</label>
                  <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="fasMapEnabled"/>
                    <label style="color: white!important">UAS Facility Maps</label>
                  </clr-toggle-wrapper>
                  <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="clsAirEnabled"/>
                    <label style="color: white!important">Class Airspace</label>
                  </clr-toggle-wrapper>
                  <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="suaEnabled"/>
                    <label style="color: white!important">Special Use Airspace</label>
                  </clr-toggle-wrapper>
                  <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}"
                           [(ngModel)]="airportsEnabled"/>
                    <label style="color: white!important">Airports</label>
                  </clr-toggle-wrapper>
                  <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}"
                           [(ngModel)]="stadiumsEnabled"/>
                    <label style="color: white!important">Stadiums</label>
                  </clr-toggle-wrapper>
                  <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="nsufrsEnabled"/>
                    <label style="color: white!important">NSUFRs</label>
                  </clr-toggle-wrapper>
                  <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="dcFrzEnabled"/>
                    <label style="color: white!important">Washington DC FRZ</label>
                  </clr-toggle-wrapper>
                  <clr-toggle-wrapper>
                    <input type="checkbox" clrToggle [ngModelOptions]="{standalone:true}" [(ngModel)]="tfrsEnabled"/>
                    <label style="color: white!important">TFRs</label>
                  </clr-toggle-wrapper>
                </clr-toggle-container>
              }
            </ng-container>
          </lib-cesium-toolbar-dropdown>

        </lib-toolbar-extra-button-container>
      </ax-cesium-container>
    </div>
  </ng-template>

  <!-- Template for volumes details section -->
  <ng-template #volumesDetails>
    <error-container [control]="fg.controls.volumes">
      <error-group [prefix]="'volume_' + (i+1)" *ngFor="let volume of fg.controls.volumes.value; let i = index">
        <error-message [has]="'geometry_missing'"></error-message>
        <error-message [has]="'coordinates_missing'"></error-message>
        <error-message [has]="'radius_missing'"></error-message>
        <error-message [has]="'min_radius'"></error-message>
        <error-message [has]="'max_radius'"></error-message>
        <error-message [has]="'geometry_continuity_fail'"></error-message>
        <error-message [has]="'altitude_continuity_fail'"></error-message>
        <error-message [has]="'time_continuity_fail'"></error-message>
        <error-message [has]="'self_intersecting'"></error-message>
      </error-group>
    </error-container>

    <error-container [control]="fg.controls.volumes">
      <error-message [has]="'required'">Volumes missing</error-message>
    </error-container>
    <error-container [control]="fg.controls.controllerLocation">
      <error-message [has]="'required'">Controller location missing</error-message>
    </error-container>
    <error-container [control]="fg.controls.takeOffLocation">
      <error-message [has]="'required'">Take off location missing</error-message>
    </error-container>

    <div class="clr-row clr-align-items-center utility-actions-row">
      @if (deviceSize$() !== 'sm') {
        <div class="clr-col-auto">
          <button type="button" class="btn btn-sm btn-icon" aria-label="plus" (click)="showManualCoordsEditor()">
            <cds-icon shape="plus"></cds-icon>
            New Volume From Coordinates
          </button>
        </div>
      }
      @if (volumeArray.controls.length > 1) {
        <div class="clr-col-auto">
          <clr-dropdown class="merge-dropdown">
            <button type="button" class="btn btn-sm btn-icon btn-outline-primary" clrDropdownTrigger>
              Merge Volumes
              <cds-icon shape="angle" direction="down"></cds-icon>
            </button>
            <clr-dropdown-menu clrPosition="bottom-left" *clrIfOpen>
              <button clrDropdownItem (click)="mergeOperationVolumes(MergeMode.SINGLE)">Single Volume</button>
              <button clrDropdownItem (click)="mergeOperationVolumes(MergeMode.SMART)">Smart Merge</button>
            </clr-dropdown-menu>
          </clr-dropdown>
          <clr-signpost class="simplify-dropdown-signpost">
            <clr-signpost-content *clrIfOpen>
              <p class="merge-mode-text"><span class="merge-mode-header">Single Volume:</span>
                Merge all volumes into a single volume
              </p>
              <p class="merge-mode-text"><span class="merge-mode-header">Smart Merge:</span>
                Reduce the number of volumes by merging adjacent volumes with similar properties
              </p>
            </clr-signpost-content>
          </clr-signpost>
        </div>
        <div class="clr-col-auto">
          <clr-checkbox-container clrInline style="margin: auto 0 auto 0" class="duplicate-settings-checkbox">
            <label>Duplicate first volume's:</label>
            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="simpleVolDetails" [ngModel]="simpleVolDetails.simpleTimeRange"
                     (ngModelChange)="handleSimpleVolTimeRangeChange($event)" [ngModelOptions]="{standalone: true}"/>
              <label>Time Range</label>
            </clr-checkbox-wrapper>

            <clr-checkbox-wrapper>
              <input type="checkbox" clrCheckbox name="simpleVolDetails"
                     [ngModel]="simpleVolDetails.simpleAltitudeRange"
                     (ngModelChange)="handleSimpleVolAltitudeRangeChange($event)"
                     [ngModelOptions]="{standalone: true}"/>
              <label>Altitude Range</label>
            </clr-checkbox-wrapper>
          </clr-checkbox-container>
        </div>
      }
    </div>

    @if (deviceSize$() === 'sm') {
      <ng-container *ngTemplateOutlet="volumesTableSimple"></ng-container>
    } @else {
      <ng-container *ngTemplateOutlet="volumesTableFull"></ng-container>
    }

    <cds-accordion class="manual-locations-editor">
      <cds-accordion-panel [expanded]="manualLocationExpanded">
        <cds-accordion-header (click)="manualLocationExpanded = !manualLocationExpanded">Manual Locations
        </cds-accordion-header>
        <cds-accordion-content>
          <div class="clr-row">
            <div class="clr-col-md-6 clr-form-control">
              <label class="clr-control-label">Controller/GCS Location</label>
              <div class="clr-control-container">
                <ax-latlngpoint-input formControlName="manualControllerLocation"></ax-latlngpoint-input>
              </div>
            </div>
            <div class="clr-col-md-6 clr-form-control">
              <label class="clr-control-label">Take Off Location</label>
              <div class="clr-control-container">
                <ax-latlngpoint-input formControlName="manualTakeOffLocation"></ax-latlngpoint-input>
              </div>
            </div>
          </div>
        </cds-accordion-content>
      </cds-accordion-panel>
    </cds-accordion>
  </ng-template>
</form>

<!-- Template for volume details form desktop view -->
<ng-template #volumesTableFull>
  <clr-datagrid (clrDgRefresh)="refresh($event)" class="vol-details-table">
    <clr-dg-column>Volume Number</clr-dg-column>
    <clr-dg-column>Time Range ({{ timeZone }})</clr-dg-column>
    <clr-dg-column>Altitude Range</clr-dg-column>
    <clr-dg-column>Edit Points</clr-dg-column>

    @for (volumeForm of volumeFgArray; track volumeForm; let i = $index) {
      <clr-dg-row [clrDgItem]="volumeForm"
                  [ngClass]="{'hideRow': !(i >= pagination.firstItem && i <=pagination.lastItem)}">
        <ng-container [formGroup]="volumeForm">
          <clr-dg-cell class="clr-row ordinal-col">
            <div class="clr-col-4 remove-icon">
              <cds-icon shape="trash" size="sm" class="custom-btn-icon"
                        (click)="removeVolume(this.baseVolumeIndex$() + i)"></cds-icon>
            </div>
            <div class="clr-col-4 arrows">
                  <span class="arrow-icon"><cds-icon shape="arrow" size="sm" class="custom-btn-icon"
                                                     (click)="moveVolume(-1, (this.baseVolumeIndex$() + i))"></cds-icon></span>
              <span class="arrow-icon arrow-down"><cds-icon shape="arrow" size="sm" class="custom-btn-icon"
                                                            (click)="moveVolume(1, (this.baseVolumeIndex$() + i))"></cds-icon></span>
            </div>
            <div class="clr-col-4 ordinal">{{ this.baseVolumeIndex$() + i + 1 }}</div>
          </clr-dg-cell>
          <clr-dg-cell>
            <div (keydown)="stopMove($event)">
              <app-time-range-selector formControlName="timeRange"
                                       [startTimeOffsetLimits]="startTimeOffsetLimits"
                                       [endTimeOffsetLimits]="endTimeOffsetLimits"
                                       [durationLimits]="durationLimits"
                                       [startCheckInterval]="timeCheckInterval">
              </app-time-range-selector>
            </div>
          </clr-dg-cell>
          <clr-dg-cell>
            <div (keydown)="stopMove($event)">
              <app-altitude-range-selector formControlName="altitudeRange"
                                           [simpleRange]="true"
                                           [fgValidators]="altitudeFGValidators"
                                           [valueValidators]="altitudeValueValidators">
              </app-altitude-range-selector>
            </div>
          </clr-dg-cell>
          <clr-dg-cell class="clr-row ordinal-col">
            <div class="clr-col-4 ordinal">
              <cds-icon shape="pencil" size="sm" class="custom-btn-icon"
                        (click)="showManualCoordsEditor((this.baseVolumeIndex$() + i), volumeArray.controls[(this.baseVolumeIndex$() + i)].value)"></cds-icon>
            </div>
          </clr-dg-cell>
        </ng-container>
      </clr-dg-row>
    }
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize"
                         [clrDgTotalItems]="volumeFgArray.length"></clr-dg-pagination>
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ volumeFgArray.length }}
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Volumes per page</clr-dg-page-size>
    </clr-dg-footer>
  </clr-datagrid>
</ng-template>

<!-- Template for volumes details form mobile view -->
<ng-template #volumesTableSimple>
  <clr-datagrid class="vol-details-table">
    <clr-dg-column>Volume Number</clr-dg-column>
    @for (volumeForm of volumeFgArray; track volumeForm; let i = $index) {

      <clr-dg-row [clrDgItem]="volumeForm">
        <clr-dg-cell>{{ this.baseVolumeIndex$() + i + 1 }}</clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <div [formGroup]="volumeForm" class="clr-row clr-flex-column volumes-form">
            <app-time-range-selector formControlName="timeRange" class="clr-col-auto"
                                     [startTimeOffsetLimits]="startTimeOffsetLimits"
                                     [endTimeOffsetLimits]="endTimeOffsetLimits"
                                     [durationLimits]="durationLimits"
                                     [startCheckInterval]="timeCheckInterval">
            </app-time-range-selector>
            <app-altitude-range-selector class="clr-col-auto"
                                         formControlName="altitudeRange"
                                         [simpleRange]="true"
                                         [fgValidators]="altitudeFGValidators"
                                         [valueValidators]="altitudeValueValidators">
            </app-altitude-range-selector>
          </div>
        </clr-dg-row-detail>
      </clr-dg-row>
    }
    <clr-dg-placeholder>No volumes found. Use the Draw Operation button to draw or import operation volumes.
    </clr-dg-placeholder>
  </clr-datagrid>
</ng-template>

<!-- Modals -->
<!-- New Volume From Coordinates Modal -->
<clr-modal [(clrModalOpen)]="showNewManualCoordsEditor" *ngIf="showNewManualCoordsEditor"
           class="geometry-input-modal">
  <div class="modal-title">
    New Volume From Coordinates
  </div>
  <div class="modal-body">
    <form [formGroup]="manualGeometryTypeFG">
      <clr-select-container class="geometry-type-selector">
        <label>Geometry Type</label>
        <select clrSelect formControlName="geometryType">
          <option *ngFor="let type of geometryTypes" [value]="type.value">{{ type.label }}</option>
        </select>
      </clr-select-container>
    </form>
    @switch (manualGeometryTypeFG.controls.geometryType.value) {
      @case (geometryTypeEnum.POLYGON) {
        <form [formGroup]="manualPolygonFg" style="height:100%">
          <app-manual-polygon-input [formControlName]="'polygon'"></app-manual-polygon-input>
        </form>
      }
      @case (geometryTypeEnum.CIRCLE) {
        <form [formGroup]="manualCircleFg" style="height:100%">
          <app-manual-circle-input [formControlName]="'circle'"></app-manual-circle-input>
        </form>
      }
    }
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showNewManualCoordsEditor = false">Cancel</button>
    @if (manualGeometryTypeFG.controls.geometryType.value === geometryTypeEnum.POLYGON) {
      <button type="button" class="btn btn-primary" [disabled]="!manualPolygonFg.controls.polygon.value"
              (click)="saveGeometry(manualGeometryTypeFG.controls.geometryType.value)">Save
      </button>
    }
    @if (manualGeometryTypeFG.controls.geometryType.value === geometryTypeEnum.CIRCLE) {
      <button type="button" class="btn btn-primary" [disabled]="!manualCircleFg.controls.circle.value"
              (click)="saveGeometry(manualGeometryTypeFG.controls.geometryType.value)">Save
      </button>
    }
  </div>
</clr-modal>

<!-- Manual Polygon Editor Modal -->
<clr-modal [(clrModalOpen)]="showManualPolygonEditor" *ngIf="showManualPolygonEditor" class="geometry-input-modal">
  <div class="modal-title">
    Polygon Coordinates Editor
  </div>
  <div class="modal-body">
    <form [formGroup]="manualPolygonFg" style="height:100%">
      <app-manual-polygon-input [formControlName]="'polygon'"></app-manual-polygon-input>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showManualPolygonEditor = false">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!manualPolygonFg.controls.polygon.value"
            (click)="savePolygon()">Save
    </button>
  </div>
</clr-modal>

<!-- Manual Circle Editor Modal -->
<clr-modal [(clrModalOpen)]="showManualCircleEditor" *ngIf="showManualCircleEditor" class="geometry-input-modal">
  <div class="modal-title">
    Circle Coordinates Editor
  </div>
  <div class="modal-body">
    <form [formGroup]="manualCircleFg" style="height:100%">
      <app-manual-circle-input [formControlName]="'circle'"></app-manual-circle-input>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="showManualCircleEditor = false">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="!manualCircleFg.controls.circle.value"
            (click)="saveCircle()">Save
    </button>
  </div>
</clr-modal>

<!-- Mobile Geometry Map Modal -->
@if (showGeometryMap) {
  <clr-modal [(clrModalOpen)]="showGeometryMap" class="fullscreen-map-modal">
    <div class="modal-title">Draw Operation</div>
    <div class="modal-body">
      <ng-container *ngTemplateOutlet="tabbedMapsLayout"></ng-container>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="showGeometryMap = false">Done</button>
    </div>
  </clr-modal>
}
