import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {CustomDataSource, Entity, Event} from '@cesium/engine';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {CesiumDrawerUtilService} from '../common/cesium-drawer-util.service';
import {OperationExt} from '../../model/utm/OperationExt';
import {isNil} from 'lodash';
import {Viewer} from '@cesium/widgets';
import {ColorConfig, ColorService} from "../../services/color.service";

@Component({
  selector: 'app-operation-volume-drawer',
  template: ''
})
export class OperationVolumeDrawerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() operation: OperationExt;
  @Input() operationVolumeIndex: number;

  private operationSubject = new BehaviorSubject<OperationExt>(null);
  private entityObservable: Observable<Entity[]>;
  private viewer: Viewer;
  private dataSource: CustomDataSource = new CustomDataSource('operation-volume-drawer');
  private terrainTileListenerRemove: Event.RemoveCallback;
  private drawSub: Subscription;
  private stateColorObservable: Observable<ColorConfig>;

  constructor(private drawerService: CesiumService,
              private cesiumDrawerUtilService: CesiumDrawerUtilService,
              private colorService: ColorService) {
    this.stateColorObservable = this.operationSubject
      .pipe(switchMap(op => this.colorService.getColorForId(this.colorService.getIdForState(op.state), true)));

    this.entityObservable = combineLatest([
      this.operationSubject,
      this.stateColorObservable
    ]).pipe(switchMap(([op, colorConfig]) => {
        const ret: Observable<Entity>[] = [];

        const volumes = !isNil(this.operationVolumeIndex) ? [op.operation_volumes[this.operationVolumeIndex]] :
          op.operation_volumes;

        for (const vol of volumes) {
          const entity = this.cesiumDrawerUtilService.getConvertedOperationVolumeEntity(vol, colorConfig);
          ret.push(entity);
        }

        return combineLatest(ret);
      }));
  }

  ngOnInit(): void {
    this.drawSub = combineLatest([
      this.drawerService.watchViewerInit(),
      this.entityObservable
    ]).subscribe(([viewer, entities]) => {
      this.viewer = viewer;
      viewer.dataSources.remove(this.dataSource);
      this.dataSource.entities.removeAll();
      entities.forEach(entity => this.dataSource.entities.add(entity));
      viewer.dataSources.add(this.dataSource).then(() => {
        if (viewer.scene.globe.tilesLoaded) {
          viewer.zoomTo(this.dataSource).then(() => {});
        } else if (!this.terrainTileListenerRemove){
          this.terrainTileListenerRemove = viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
            if (viewer.scene.globe.tilesLoaded){
              viewer.zoomTo(this.dataSource).then(() => {});
              this.terrainTileListenerRemove();
            }
          });
        }
      });
    });

    if (this.operation) {
      this.operationSubject.next(this.operation);
    }
  }

  ngOnDestroy(): void {
    this.drawSub?.unsubscribe();
    this.viewer?.dataSources.remove(this.dataSource);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.operation) {
      this.operationSubject.next(this.operation);
    }
  }
}
