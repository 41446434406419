<ng-template #widgetTemplate>
  @for (currentEntity of displayedEntities; track currentEntity; let idx = $index) {
    <lib-infobox [entity]="currentEntity"
                 (pinnedChange)="onPinnedChange(currentEntity, $event)"
                 (closeChange)="onClose(currentEntity)"
                 [enableLiveUpdates]="enableLiveUpdates()"
                 [enablePinning]="enableEntityPinning()"
                 [enableEntityTracking]="enableEntityTracking()"
    ></lib-infobox>
  }

  @if (infoBoxService) {
    @for (box of infoBoxService.infoboxes$(); track box) {
      <lib-infobox
        [title]="box.title$()"
        [content]="box.content$()"

        (closeChange)="box.close()"
      ></lib-infobox>
    }
  }
</ng-template>
