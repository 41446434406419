import {BaseModel, BaseModelSerializer} from '../common';
import {LaancSubmissionCategory, LaancSubmissionType} from './enums';

export interface ILaancAuthParams {
  readonly category: LaancSubmissionCategory;
  readonly type: LaancSubmissionType;
}

export interface TLaancAuthParams {
  readonly category: LaancSubmissionCategory;
  readonly type: LaancSubmissionType;
}

export class LaancAuthParams implements ILaancAuthParams, BaseModel {
  readonly category: LaancSubmissionCategory;
  readonly type: LaancSubmissionType;

  constructor(values: ILaancAuthParams) {
    this.category = values.category;
    this.type = values.type;
  }
}

export class LaancAuthParamsUtil implements BaseModelSerializer<TLaancAuthParams, LaancAuthParams> {
  serialize(obj: LaancAuthParams): TLaancAuthParams {
    return {
      category: obj.category,
      type: obj.type
    };
  }
}

export const DEFAULT_LAANC_AUTH_PARAMS_UTIL = new LaancAuthParamsUtil();
