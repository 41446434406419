import {
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  OnDestroy,
  output,
  Signal,
  signal,
  TemplateRef,
  viewChild,
  ViewEncapsulation
} from '@angular/core';

import {Entity, Event, JulianDate} from '@cesium/engine';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {Viewer} from '@cesium/widgets';
import {concat, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';
import {NgIf, NgOptimizedImage, NgTemplateOutlet} from "@angular/common";


function fromEvent(e: Event): Observable<any[]> {
  return new Observable(observer => {
    const removeCallback = e.addEventListener((...args) => {
      observer.next(args);
    });
    return () => {
      removeCallback();
    };
  });
}

@Component({
  selector: 'lib-infobox',
  templateUrl: './infobox.component.html',
  encapsulation: ViewEncapsulation.ShadowDom,
  styleUrls: [
    './infobox.component.css'
  ],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgIf,
    NgOptimizedImage
  ]
})
export class InfoboxComponent implements OnDestroy {
  private cesiumViewer = inject(CesiumService);
  private cdrf = inject(ChangeDetectorRef);
  title = input<string>();
  content = input<TemplateRef<any>>();
  entity = input<Entity>();

  enablePinning = input<boolean>(false);
  enableLiveUpdates = input<boolean>(false);
  enableEntityTracking = input<boolean>(false);


  private pinned$ = signal(false);
  entity$ = this.entity;

  private liveUpdates$ = signal(false);

  pinnedChange = output<boolean>();
  closeChange = output<boolean>();

  private infoBoxContainer = viewChild<ElementRef>('infoBoxContainer');
  private infoBoxDescriptionElement$ = viewChild<ElementRef>('infoBoxDescription');

  private mouseDownY: number = 0;
  private mouseDragging: boolean | undefined;
  private description: string|undefined;
  private mouseDownX: number = 0;
  private topPx: number = 0;
  private leftPx: number = 0;
  pinnedSvgPath$ = computed(() => {
    return this.pinned$() ? '/assets/icons/lock-solid.svg' : '/assets/icons/lock-open-solid.svg';
  });

  liveUpdatesSvgPath$ = computed(() => {
    return this.liveUpdates$() ? '/assets/icons/fa/stop-solid.svg' : '/assets/icons/fa/play-solid.svg';
  });


  title$ = computed(() => {
    const title = this.title();
    const entity = this.entity();
    return title || entity?.name || '';
  });

  private viewer$ = toSignal<Viewer | null>(this.cesiumViewer.watchViewerInit(), {
    initialValue: null
  });

  private trackedEntity$ = toSignal(toObservable(this.viewer$).pipe(
    switchMap(viewer => {
      if (!viewer) {
        return of(undefined);
      }
      return concat(
        of(viewer.trackedEntity),
        fromEvent(viewer.trackedEntityChanged).pipe(map(([trackedEntity]) => trackedEntity as Entity))
      );
    })
  ));

  isTrackedEntity$ = computed(() => {
    return this.trackedEntity$() === this.entity();
  });


  private currentTime$: Signal<JulianDate | null> = toSignal(toObservable(this.viewer$).pipe(
    switchMap(viewer => {
      if (!viewer) {
        return of(null);
      }
      return concat(
        of(viewer.clock.currentTime),
        fromEvent(viewer.clock.onTick).pipe(map(([clock]) => clock.currentTime as JulianDate))
      );

    })
  ), {
    initialValue: null
  });

  private description$ = computed(() => {
    const entity = this.entity();
    const currentTime = this.currentTime$();
    // console.log('description$', entity, currentTime);
    if (!entity || !currentTime) {
      return '';
    }
    return entity?.description?.getValue(currentTime) || '';
  });




  constructor() {
    effect(() => {
      const description = this.description$();
      const liveUpdates = this.liveUpdates$();
      const infoBoxDescriptionElement = this.infoBoxDescriptionElement$();
      // console.log('descriptionEffect', [description, liveUpdates]);
      if (this.description && !liveUpdates) {
        return;
      }
      this.description = description;
      if (!infoBoxDescriptionElement?.nativeElement) {
        return;
      }
      infoBoxDescriptionElement.nativeElement.innerHTML = this.description;
      this.cdrf.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.dragMouseUp(null);
  }


  dragMouseDown($event: MouseEvent): void {
    if($event.button !== 0) {
      return;
    }

    this.mouseDownX = $event.clientX;
    this.mouseDownY = $event.clientY;
    this.mouseDragging = true;
    const infoBoxContainer = this.infoBoxContainer();
    if (!infoBoxContainer) {
      return;
    }
    const nativeElement = infoBoxContainer.nativeElement as HTMLElement;
    this.topPx = nativeElement?.offsetTop || 0;
    this.leftPx = nativeElement?.offsetLeft || 0;
    window.addEventListener('mousemove', this.mouseDrag);
    window.addEventListener('mouseup', () => {
      this.dragMouseUp($event);
    });
  }

  dragMouseUp($event: MouseEvent | null): void {
    this.mouseDragging = false;
    window.removeEventListener('mousemove', this.mouseDrag);
  }


  togglePin(): void {
    const pinned = !this.pinned$();

    this.pinned$.set(pinned);
    this.pinnedChange.emit(pinned);

  }

  toggleLiveUpdates(): void {
    this.liveUpdates$.update((liveUpdates) => !liveUpdates);
  }

  enableTracking(): void {
    const viewer = this.viewer$();
    const entity = this.entity();

    if (!entity || !viewer) {
      return;
    }
    viewer.trackedEntity = entity;
  }

  disableTracking(): void {
    const viewer = this.viewer$();
    const entity = this.entity();
    if (!entity || !viewer) {
      return;
    }
    if (viewer.trackedEntity !== entity) {
      return;
    }

    viewer.trackedEntity = undefined;
  }

  closeInfoBox(): void {
    this.closeChange.emit(true);
  }

  private mouseDrag = ($event: MouseEvent) => {
    $event.preventDefault();

    const infoBoxContainer = this.infoBoxContainer();
    if(!infoBoxContainer) {
      return;
    }

    const deltaX = $event.clientX - this.mouseDownX;
    const deltaY = $event.clientY - this.mouseDownY;

    this.mouseDownX = $event.clientX;
    this.mouseDownY = $event.clientY;


    const nativeElement = infoBoxContainer.nativeElement as HTMLElement;
    this.topPx = nativeElement.offsetTop + deltaY;
    this.leftPx = nativeElement.offsetLeft + deltaX;

    nativeElement.style.top = this.topPx + 'px';
    nativeElement.style.left = this.leftPx + 'px';


  }

}
