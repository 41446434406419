import {
  AdditionalOperationData,
  IAdditionalOperationData,
  SwaggerAdditionalOperationData
} from './AdditionalOperationData';
import {Operation} from './Operation';
import {IOperation} from '../gen/utm';
import {IOperation as SwaggerOperation} from '../gen/transport/utm';
import {UntypedFormGroup} from '@angular/forms';
import {IOperationLaancInfo, OperationLaancInfo, TOperationLaancInfo} from '@ax-uss-ui/common';

export interface SwaggerOperationExt extends SwaggerOperation{
  additional_data?: SwaggerAdditionalOperationData;
  laanc?: TOperationLaancInfo;
}

export interface IOperationExt extends IOperation {
  additional_data?: IAdditionalOperationData;
  laanc?: IOperationLaancInfo;
}

export class OperationExt extends Operation {

  static ADDITIONALDATA_FIELD_NAME = 'additional_data';
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  additional_data: AdditionalOperationData;
  laanc?: OperationLaancInfo;

  constructor(values?: Partial<IOperationExt>, useFormGroupValuesToModel = false) {
    super();
    this.additional_data = new AdditionalOperationData();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IOperationExt>, useFormGroupValuesToModel = false): void {
    super.setValues(values, useFormGroupValuesToModel);
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.additional_data.setValues(rawValues.additional_data, useFormGroupValuesToModel);
      if (rawValues.laanc) {
        this.laanc = new OperationLaancInfo(rawValues.laanc);
      }
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {

      this._formGroup = super.getFormGroup();
      this._formGroup.addControl('additional_data', this.additional_data.$formGroup);
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    super.setFormGroupValues();
    this.additional_data.setFormGroupValues();
    super.setFormGroupValuesInAddedFormControls();
  }




}
