import {
  Component,
  computed,
  effect,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  signal,
  SimpleChanges
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators
} from '@angular/forms';
import * as L from 'leaflet';
import {LatLng, LatLngBounds} from 'leaflet';
import {LatLngPoint} from '../../../model/WaypointParser';
import {GeoCircle, GeometryTypeEnum, Polygon, units_of_measure, vertical_reference} from '../../../model/gen/utm';
import {controllerIcon, takeOffIcon} from '../../../utils/leaflet-color-markers';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {IFormArray, IFormBuilder, IFormGroup} from '@rxweb/types';
import {LeafletMapService} from '@ax/ax-angular-map-leaflet';
import {ColorConfig} from '../../../services/color.service';
import {downloadBlob} from '../../../utils/download';
import * as _ from 'lodash';
import {cloneDeep} from 'lodash';
import {
  geometryRequiredValidator,
  geometrySelfIntersectionValidator,
  isLatLngPoint,
  minVolHeightValidator,
  validateMaxTime,
  validateMaxTimeDuration,
  validateMinTime,
  validateMinTimeDuration,
  volumeRadiusRangeValidator
} from '../../../utils/Validators';
import {operationVolumeContinuityValidator} from './validators';
import {SituationalAwarenessService} from '../../../services/situational-awareness.service';
import {Volume3dQuery, Volume4dQuery} from '../../../models/Volume4dQuery';
import {DateTime, Duration} from 'luxon';
import {AltitudeRange} from '../../../model/gen/utm/altitude-range-model';
import {IOpGeoSubmissionFG} from '../create-operation/create-operation.component';
import {TimeRange} from '../../../model/TimeRange';
import {debounceTime} from 'rxjs/operators';
import {circle, coordReduce, distance, featureCollection, point, polygon, polygon as turfPolygon} from '@turf/turf';
import bbox from '@turf/bbox';
import {Converter} from '../../../utils/convert-units';
import {AltitudeService, IAltitudeConversionParameters} from '../../../services/altitude.service';
import {AltitudeUtilService} from '../../../model/utm/altitude-util.service';
import {UserSettings} from '../../../services/user-settings.service';
import {OPERATION_DURATION_LIMITS, OPERATION_START_OFFSET_LIMITS} from '../../../constants';
import {TimeRangeSelectorFG} from '../../time-range-selector/time-range-selector.component';
import {OperationExt} from '../../../model/utm/OperationExt';
import {ImportExportType} from '../../../import-export/import-export.service';
import envelope from "@turf/envelope";
import {SelectOption} from "../../../select-option";
import {toSignal} from "@angular/core/rxjs-interop";
import {ClrDatagridStateInterface} from "@clr/angular";
import {ResponsiveScreenService} from "../../../services/responsive-screen.service";
import {MergeMode, mergeOperationVolumes} from "./utils";
import {ButtonState, CesiumProvider} from '@ax-uss-ui/common';
import {SettingsService} from "../../../services/settings.service";
import {LaancCZMLEntityType} from "../../../cesium/laanc-drawer/laanc-drawer.component";
import {CesiumIonLayerConfig} from "@ax/ax-angular-map-common";
import {CreateOperationMode} from "../../../../fuss/operations/new-operations/new-operations.component";
import {Rectangle, Viewer, Math as CMath} from "@cesium/engine";


export interface IOpVolumeSubmissionFG {
  geography: Polygon;
  circle: GeoCircle;
  altitudeRange: AltitudeRange;
  timeRange: TimeRange;
  offNominal?: boolean;
}

// eslint-disable-next-line no-shadow,@typescript-eslint/naming-convention
type changeFnType = (OperationGeometry) => void;

interface SimpleVolDetails {
  simpleTimeRange: boolean;
  simpleAltitudeRange: boolean;
}

@Component({
  selector: 'app-operation-geometry-editor',
  templateUrl: './operation-geometry-editor.component.html',
  styleUrls: ['./operation-geometry-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => OperationGeometryEditorComponent),
    }
  ]
})
export class OperationGeometryEditorComponent implements ControlValueAccessor, OnChanges, OnDestroy {
  @Input() selectedVertRef: vertical_reference;
  @Input() selectedUnits: units_of_measure;
  @Input() colorConfig: ColorConfig;
  @Input() opModificationType: CreateOperationMode;
  @Input() userSettings: UserSettings;
  @Input() sourceOperation: OperationExt;
  @Output() spatialAreaOfInterestChange: EventEmitter<Volume3dQuery> = new EventEmitter<Volume3dQuery>();
  @Output() sitrepStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() altRefChange: EventEmitter<vertical_reference> = new EventEmitter<vertical_reference>();
  @Output() altUnitsChange: EventEmitter<units_of_measure> = new EventEmitter<units_of_measure>();

  currentPageSize = 10;
  fg: IFormGroup<IOpGeoSubmissionFG>;
  manualLocationExpanded = false;
  currentGeometry: IOpGeoSubmissionFG;
  previewGeometry: IOpGeoSubmissionFG;
  previewViewer = signal<Viewer|undefined>(undefined);
  controllerIcon = controllerIcon;
  takeOffIcon = takeOffIcon;
  simpleVolDetails: SimpleVolDetails = {
    simpleTimeRange: true,
    simpleAltitudeRange: true
  };
  spatialQuerySubject: BehaviorSubject<Volume3dQuery> = new BehaviorSubject<Volume3dQuery>(null);
  queryTimeRange: TimeRange = new TimeRange(DateTime.now(), null);

  LaancCZMLEntityType = LaancCZMLEntityType;
  sitRepEnabled = true;
  fasMapEnabled = true;
  clsAirEnabled = true;
  suaEnabled = true;
  stadiumsEnabled = true;
  airportsEnabled = true;
  nsufrsEnabled = true;
  dcFrzEnabled = true;
  tfrsEnabled = true;

  ON = ButtonState.ON;
  // MIXED: ButtonState = ButtonState.MIXED;
  OFF = ButtonState.OFF;
  startTimeOffsetLimits: { min: Duration | DateTime; max: Duration } = {
    min: Duration.fromObject({minutes: OPERATION_START_OFFSET_LIMITS.min}),
    max: Duration.fromObject({minutes: OPERATION_START_OFFSET_LIMITS.max})
  };
  endTimeOffsetLimits: { min: Duration | DateTime; max: Duration } = {min: null, max: null};
  durationLimits: { min: Duration; max: Duration } = {
    min: Duration.fromObject({minutes: OPERATION_DURATION_LIMITS.min}),
    max: Duration.fromObject({minutes: OPERATION_DURATION_LIMITS.max})
  };
  timeCheckInterval: Duration = Duration.fromMillis(1000);
  points: LatLngPoint[] = [];
  timeZone: string;
  importExportType = ImportExportType;
  manualGeometryIndex: number;
  showManualPolygonEditor: boolean;
  showManualCircleEditor: boolean;
  showNewManualCoordsEditor: boolean;
  showGeometryMap: boolean;
  altitudeFGValidators: ValidatorFn[] = [minVolHeightValidator({height: 5, units: units_of_measure.M})];
  altitudeValueValidators: ValidatorFn[] = [Validators.min(-1000), Validators.max(8000)];
  geometryTypeEnum = GeometryTypeEnum;
  geometryTypes: SelectOption[] = Object.keys(GeometryTypeEnum).map(key => {
    return {label: GeometryTypeEnum[key], value: key};
  });
  MergeMode = {
    SINGLE: MergeMode.SINGLE,
    SMART: MergeMode.SMART
  };

  manualGeometryTypeFG = new FormGroup({
    geometryType: new FormControl<GeometryTypeEnum>(GeometryTypeEnum.POLYGON)
  });

  manualPolygonFg = new FormGroup({
    polygon: new FormControl<Polygon>(null, [Validators.required]),
  });

  manualCircleFg = new FormGroup({
    circle: new FormControl<GeoCircle>(null, [Validators.required]),
  });

  selectedGeometryType$ = toSignal(this.manualGeometryTypeFG.controls.geometryType.valueChanges);
  baseVolumeIndex$ = signal<number>(0);
  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;
  ussSettings$ = toSignal(inject(SettingsService).getRawSettings());

  geocoder$ = computed(() => {
    const mapConfig = this.ussSettings$()?.mapConfig;
    if (!mapConfig) {
      return undefined;
    }
    const layers = mapConfig.getLayerConfigs();
    const layer = layers.find(layer => layer instanceof CesiumIonLayerConfig) as CesiumIonLayerConfig|undefined;
    return layer ? new CesiumProvider(layer) : undefined;
  });
  private onChange: changeFnType;
  private onTouched: () => void;
  private fb: IFormBuilder;
  private isDisabled: boolean;
  private leafletMap: L.Map & { pm };
  private altRefSubject = new BehaviorSubject(null);
  private altUnitsSubject = new BehaviorSubject(null);
  private sourceOperationSubject: BehaviorSubject<OperationExt> = new BehaviorSubject<OperationExt>(null);
  private firstVolFGSub: Subscription;
  private timeSubscription: Subscription;
  private opTypeSub: Subscription;
  private constraintTypeSub: Subscription;
  private sitRepSub: Subscription;
  private fgValueChangesSub: Subscription;
  private altitudeConversionsSub: Subscription;
  private sourceOperationSubscription: Subscription;
  private altitudeConversionSubs: Subscription[] = [];
  private manualLocationsSubs: Subscription[] = [];
  private firstVolFGAltSub: Subscription;
  private firstVolFGTimeSub: Subscription;

  constructor(private leafletMapService: LeafletMapService,
              fb: UntypedFormBuilder,
              private situationalAwarenessService: SituationalAwarenessService,
              private altitudeService: AltitudeService,
              private altitudeUtilService: AltitudeUtilService) {
    this.fb = fb;
    this.fg = this.fb.group<IOpGeoSubmissionFG>({
      controllerLocation: this.fb.control<LatLngPoint>(null, isLatLngPoint),
      takeOffLocation: this.fb.control<LatLngPoint>(null, isLatLngPoint),
      manualControllerLocation: this.fb.control<LatLngPoint>(null, isLatLngPoint),
      manualTakeOffLocation: this.fb.control<LatLngPoint>(null, isLatLngPoint),
      volumes: this.fb.array<IOpVolumeSubmissionFG>([], [Validators.required,
        operationVolumeContinuityValidator(), geometryRequiredValidator(), volumeRadiusRangeValidator(),
        geometrySelfIntersectionValidator()])
    });

    this.timeZone = this.getTimeZone();

    this.fgValueChangesSub = this.fg.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.cleanUpEmptyVolumes();
      this.emitAreaOfInterestChange();
      this.emitChange();

      // Update Cesium preview
      const previewGeometry = cloneDeep(value);
      previewGeometry.volumes = this.getVolumeValues(true);
      this.previewGeometry = previewGeometry;
    });

    // Situational Awareness Query
    this.sitRepSub = combineLatest([
      this.fg.controls.volumes.valueChanges as Observable<IOpVolumeSubmissionFG[]>,
      this.spatialQuerySubject
    ]).subscribe(([volumes, spatial]) => {
      const range = this.getTimeWindow(volumes);
      const start = range.start || DateTime.now();
      const end = range.end || DateTime.now();

      const query: Volume4dQuery = {
        timeWindow: {
          timeEndAfter: start,
          timeBeginBefore: (end && end.diff(start).as('milliseconds') > 0) ? end : undefined
        },
        spatial: spatial || undefined
      };

      this.situationalAwarenessService.setVolume4dQuery(query);
    });

    // Altitude conversion subscriptions
    this.altitudeConversionsSub = combineLatest([this.altRefSubject, this.altUnitsSubject]).pipe(debounceTime(50))
      .subscribe(([altRef, altUnits]) => {
        this.convertAltitudes(altRef, altUnits);
      });

    // Sync manual locations fields w/Leaflet fields
    this.manualLocationsSubs.push(this.fg.controls.controllerLocation.valueChanges.subscribe(controllerLocation => {
      if (controllerLocation !== this.fg.controls.manualControllerLocation.value) {
        this.fg.controls.manualControllerLocation.setValue(controllerLocation, {emitEvent: false});
      }
    }));

    this.manualLocationsSubs.push(this.fg.controls.takeOffLocation.valueChanges.subscribe(takeOffLocation => {
      if (takeOffLocation !== this.fg.controls.manualTakeOffLocation.value) {
        this.fg.controls.manualTakeOffLocation.setValue(takeOffLocation, {emitEvent: false});
      }
    }));

    this.manualLocationsSubs.push(this.fg.controls.manualControllerLocation.valueChanges.subscribe(manualControllerLocation => {
      if (manualControllerLocation !== this.fg.controls.controllerLocation.value) {
        this.fg.controls.controllerLocation.setValue(manualControllerLocation);
      }
    }));

    this.manualLocationsSubs.push(this.fg.controls.manualTakeOffLocation.valueChanges.subscribe(manualTakeOffLocation => {
      if (manualTakeOffLocation !== this.fg.controls.takeOffLocation.value) {
        this.fg.controls.takeOffLocation.setValue(manualTakeOffLocation);
      }
    }));

    // When creating a new volume via the manual coordinates editor, clear the coordinates editor forms whenever the
    // selected geometry type changes.
    // If the selected geometry type is circle, set the form's units field to the selected units for the operation
    effect(() => {
      this.manualPolygonFg.reset();
      this.manualCircleFg.reset();
      if (this.selectedGeometryType$() === GeometryTypeEnum.CIRCLE) {
        const circle = new GeoCircle({units: this.selectedUnits, latitude: null, longitude: null, radius: null});
        this.manualCircleFg.controls.circle.patchValue(circle);
      }
    });

    // When LAANC is enabled, operations cannot be planned more than 90 days in advance
    effect(() => {
      if (this.ussSettings$()?.laanc?.enabled) {
        const duration90Days = Duration.fromObject({days: 90});
        this.startTimeOffsetLimits = {
          min: this.startTimeOffsetLimits.min,
          max: duration90Days
        };
        this.endTimeOffsetLimits = {
          min: this.endTimeOffsetLimits.min,
          max: duration90Days
        };
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedUnits && !!changes.selectedUnits.previousValue && changes.selectedUnits.previousValue !== changes.selectedUnits.currentValue) {
      this.altUnitsSubject.next(changes.selectedUnits.currentValue);
    }
    if (changes.selectedVertRef && !!changes.selectedVertRef.previousValue && changes.selectedVertRef.previousValue !== changes.selectedVertRef.currentValue) {
      this.altRefSubject.next(changes.selectedVertRef.currentValue);
    }
    if (changes.sourceOperation && changes.sourceOperation.currentValue) {
      this.sourceOperationSubject.next(changes.sourceOperation.currentValue);
    }
  }

  get volumeArray(): IFormArray<IOpVolumeSubmissionFG> {
    return this.fg.controls.volumes as IFormArray<IOpVolumeSubmissionFG>;
  }

  get volumeFgArray(): IFormGroup<IOpVolumeSubmissionFG>[] {
    return this.volumeArray.controls as IFormGroup<IOpVolumeSubmissionFG>[];
  }

  get volumeFgArrayAsFormGroups(): UntypedFormGroup[] {
    return this.volumeFgArray as UntypedFormGroup[];
  }

  ngOnDestroy(): void {
    this.firstVolFGSub?.unsubscribe();
    this.firstVolFGTimeSub?.unsubscribe();
    this.firstVolFGAltSub?.unsubscribe();
    this.timeSubscription?.unsubscribe();
    this.opTypeSub?.unsubscribe();
    this.constraintTypeSub?.unsubscribe();
    this.sitRepSub?.unsubscribe();
    this.fgValueChangesSub?.unsubscribe();
    this.altitudeConversionsSub?.unsubscribe();
    this.sourceOperationSubscription?.unsubscribe();
    this.manualLocationsSubs.forEach(sub => sub?.unsubscribe());
    this.altitudeConversionSubs.forEach(sub => sub?.unsubscribe());
    this.volumeArray.clear();
  }

  registerOnChange(fn: changeFnType): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: IOpGeoSubmissionFG): void {
    if (!obj) {
      this.reset();
      return;
    }
    this.points = obj.points || [];

    if (this.opModificationType === CreateOperationMode.rerouteActive &&
      (!this.sourceOperation || this.startTimeOffsetLimits.min !== this.sourceOperation.effective_time_begin)) {
      // If rerouting an active operation, set the min start time to the source operation's start time.
      // This needs to happen before addVolumes runs, since that will overwrite any invalid time ranges w/default values.
      this.sourceOperationSubscription?.unsubscribe();
      this.sourceOperationSubscription = this.sourceOperationSubject.subscribe(sourceOperation => {
        if (!sourceOperation) {
          return;
        }
        this.startTimeOffsetLimits = {
          min: sourceOperation.effective_time_begin,
          max: this.startTimeOffsetLimits.max
        };
        this.setValues(obj);
      });
    } else {
      this.setValues(obj);
    }
  }

  handleOperationVolumeImport($event: any) {
    if ($event instanceof L.Layer) {
      this.leafletMap.addLayer($event);
      const bounds = ($event as any).getBounds();
      this.leafletMap.fitBounds(bounds);
    } else {
      this.writeValue($event);
      this.fg.updateValueAndValidity();
      this.altRefChange.emit($event.volumes[0].altitudeRange.altitude_vertical_reference);
      this.altUnitsChange.emit($event.volumes[0].altitudeRange.altitude_units);
      this.emitChange();
    }
  }

  handleOperationVolumeExport($event: Blob | null) {
    if (!$event) {
      return;
    }
    switch ($event.type) {
      case 'application/geo+json':
        downloadBlob($event, 'OperationGeometry.geojson');
        break;
      case 'application/vnd.google-earth.kml+xml':
        downloadBlob($event, 'OperationGeometry.kml');
        break;
      default:
        console.error('Unknown file type: ' + $event.type);
    }
  }

  handleMapInit(map: L.Map & { pm }) {
    this.leafletMap = map;
    this.zoomToVolumes();

    if (!this.isDisabled) {

      const customTranslation = {
        buttonTitles: {
          editButton: 'Edit Item',
          dragButton: 'Drag Item',
          deleteButton: 'Remove Item'
        }
      };

      map.pm.setLang('customEn', customTranslation, 'en');

      map.pm.addControls({
        position: 'topleft',
        drawControls: true,
        drawCircle: false,
        drawMarker: false,
        drawRectangle: false,
        drawCircleMarker: false,
        drawPolygon: false,
        drawText: false,
        drawPolyline: false,
        editControls: true,
        cutPolygon: false,
        pinningOption: false,
        snappingOption: false,
        optionsControls: false,
        customControls: true,
        oneBlock: false
      });

      map.on('pm:globaleditmodetoggled', () => {
        this.emitChange();
      });
      map.on('pm:globaldrawmodetoggled', () => {
        this.emitChange();
      });
      map.on('moveend', () => {
        this.emitAreaOfInterestChange();
      });
    }
    this.emitAreaOfInterestChange();
  }

  updateSitMode($event: boolean) {
    this.sitRepEnabled = $event;
  }

  createVolume($event: Polygon | GeoCircle): void {
    const timeRange = this.getDefaultTimeRange();

    const newVolume: IOpVolumeSubmissionFG = {
      geography: ($event instanceof Polygon) ? $event : undefined,
      circle: ($event instanceof GeoCircle) ? $event : undefined,
      altitudeRange: new AltitudeRange({
        min_altitude: 0,
        max_altitude: 100,
        altitude_vertical_reference: this.selectedVertRef,
        altitude_units: this.selectedUnits
      }),
      timeRange
    };
    if (this.volumeFgArray.length >= 1) {
      if (this.simpleVolDetails.simpleTimeRange) {
        newVolume.timeRange = this.volumeFgArray[0].controls.timeRange.value;
      }
      if (this.simpleVolDetails.simpleAltitudeRange) {
        newVolume.altitudeRange = this.volumeFgArray[0].controls.altitudeRange.value;
      }
    }

    this.addVolumes([newVolume], true);
    this.handleSimpleVolDetailsChange(this.simpleVolDetails);
    this.touched();
  }

  showManualCoordsEditor(i: number | null = null, volume: IOpVolumeSubmissionFG | null = null): void {
    if (volume?.circle) {
      this.manualGeometryIndex = i;
      this.manualCircleFg.setValue({
        circle: volume.circle
      });
      this.showManualCircleEditor = true;
    } else if (volume?.geography) {
      this.manualGeometryIndex = i;
      this.manualPolygonFg.setValue({
        polygon: volume.geography
      });
      this.showManualPolygonEditor = true;
    } else {
      this.manualCircleFg.setValue({
        circle: new GeoCircle({units: this.selectedUnits, latitude: null, longitude: null, radius: null})
      });
      this.manualPolygonFg.reset();
      this.manualGeometryIndex = null;
      this.showNewManualCoordsEditor = true;
    }
  }

  /**
   * Passes the geometry from the manual coordinates editor to the appropriate save method for volume creation.
   * @param type The type of geometry to save
   */
  saveGeometry(type: GeometryTypeEnum): void {
    if (type === GeometryTypeEnum.POLYGON) {
      this.savePolygon();
    } else if (type === GeometryTypeEnum.CIRCLE) {
      this.saveCircle();
    }
    this.showNewManualCoordsEditor = false;
  }

  /**
   * Creates a volume from the manual polygon editor's form values.
   * If the manualGeometryIndex field is not null or undefined and an operation volume exists at that index, the
   * respective operation volume will be updated with the polygon's values.
   */
  savePolygon(): void {
    const polygon = this.manualPolygonFg.controls.polygon.value;
    if (!_.isNil(this.manualGeometryIndex)) {
      this.volumeFgArray[this.manualGeometryIndex].controls.geography.setValue(polygon);
      this.handleSimpleVolDetailsChange(this.simpleVolDetails);
    } else {
      this.createVolume(polygon);
    }

    this.showManualPolygonEditor = false;
    this.manualGeometryIndex = null;
    this.zoomToVolumes();
  }

  /**
   * Creates a volume from the manual circle editor's form values.
   * If the manualGeometryIndex field is not null or undefined and an operation volume exists at that index, the
   * respective operation volume will be updated with the polygon's values.
   */
  saveCircle(): void {
    let circle = this.manualCircleFg.controls.circle.value;
    if (!_.isNil(this.manualGeometryIndex)) {
      this.volumeFgArray[this.manualGeometryIndex].controls.circle.setValue(circle);
      this.handleSimpleVolDetailsChange(this.simpleVolDetails);
    } else {
      this.createVolume(circle);
    }

    this.showManualCircleEditor = false;
    this.zoomToVolumes();
  }

  refresh($event: ClrDatagridStateInterface) {
    const offset = $event.page.from === -1 ? 0 : $event.page.from;
    // this.baseVolumeIndex$.set(offset);
    this.baseVolumeIndex$.set(0);
  }

  handleSimpleVolDetailsChange(simpleVolDetails: SimpleVolDetails) {
    this.simpleVolDetails = simpleVolDetails;
    this.firstVolFGTimeSub?.unsubscribe();
    this.firstVolFGAltSub?.unsubscribe();

    if (this.simpleVolDetails.simpleTimeRange && this.volumeFgArray.length > 1) {
      this.setAllVolumeRowsTime(this.volumeFgArray[0].getRawValue());
      this.volumeFgArray[0].controls.timeRange.enable();
      this.firstVolFGTimeSub = this.volumeFgArray[0].valueChanges.subscribe(firstVolFG => {
        this.setAllVolumeRowsTime(firstVolFG);
      });
    } else {
      this.volumeFgArray.forEach(volFG => {
        volFG.controls.timeRange.enable();
      });
    }

    if (this.simpleVolDetails.simpleAltitudeRange && this.volumeFgArray.length > 1) {
      this.setAllVolumeRowsAlt(this.volumeFgArray[0].getRawValue());
      this.volumeFgArray[0].controls.altitudeRange.enable();
      this.firstVolFGAltSub = this.volumeFgArray[0].valueChanges.subscribe(firstVolFG => {
        this.setAllVolumeRowsAlt(firstVolFG);
      });
    } else {
      this.volumeFgArray.forEach(volFG => {
        volFG.controls.altitudeRange.enable();
      });
    }
  }

  stopMove($event: KeyboardEvent) {
    $event.stopImmediatePropagation();
  }

  moveVolume(increment: number, index: number) {
    if (this.volumeArray.length > 1) {
      const currentVol = this.volumeArray.at(index);
      let newIndex = index + increment;

      if (newIndex === -1) {
        // If moving the volume beyond the top, move it to the end of the array
        newIndex = this.volumeArray.length - 1;
      } else if (newIndex === this.volumeArray.length) {
        // If moving the volume beyond the end, move it to the beginning of the array
        newIndex = 0;
      }

      // If simpleVolDetails is enabled and the volume is moving to or from the first index,
      // populate the values of the previously disabled time and altitude fields
      if (index === 0 || newIndex === 0) {
        const simpleVolValue = this.volumeArray.at(0).value;
        if (this.simpleVolDetails.simpleTimeRange) {
          currentVol.patchValue({
            timeRange: simpleVolValue.timeRange,
          });
        }
        if (this.simpleVolDetails.simpleAltitudeRange) {
          currentVol.patchValue({
            altitudeRange: simpleVolValue.altitudeRange,
          });
        }
      }

      this.volumeArray.removeAt(index);
      this.volumeArray.insert(newIndex, currentVol);
      this.handleSimpleVolDetailsChange(this.simpleVolDetails);
    }
  }

  removeVolume(index: number) {
    this.volumeArray.removeAt(index);
    this.handleSimpleVolDetailsChange(this.simpleVolDetails);
  }

  private setValues(obj: IOpGeoSubmissionFG): void {
    this.addVolumes(obj.volumes, true, {addToFormArray: false, newFormArray: true});
    this.fg.patchValue({
      controllerLocation: obj.controllerLocation?.lat && obj.controllerLocation?.lng ? obj.controllerLocation : null,
      takeOffLocation: obj.takeOffLocation?.lat && obj.takeOffLocation?.lng ? obj.takeOffLocation : null,
      points: obj.points || null
    }, {emitEvent: true});
    this.fg.updateValueAndValidity();

    this.zoomToVolumes();
  }

  private getTimeWindow(volumes: IOpVolumeSubmissionFG[]): TimeRange {
    const ret = new TimeRange();
    for (const volume of volumes) {
      if (!ret.start || (volume?.timeRange?.start && volume.timeRange.start < ret.start)) {
        ret.start = volume?.timeRange?.start;
      }
      if (!ret.end || (volume?.timeRange?.end && volume.timeRange.end > ret.end)) {
        ret.end = volume?.timeRange?.end;
      }

    }
    return ret;
  }

  private getTimeZone(): string {
    const date = DateTime.now();
    const offsetMinutesStr = (Math.abs(date.offset) % 60).toString().padStart(2, '0');
    const offsetHours = Math.trunc(date.offset / 60);
    return `${date.offsetNameShort}/${offsetHours >= 0 ? '+' + offsetHours : offsetHours}:${offsetMinutesStr}`;
  }

  private getDefaultTimeRange(): TimeRange | null {
    if (this.userSettings) {
      const startTime = DateTime.now().set({
        second: 0,
        millisecond: 0
      }).plus({minutes: this.userSettings.operationStartOffset});
      return new TimeRange(
        startTime,
        startTime.plus({minutes: this.userSettings.operationDuration})
      );
    } else {
      return null;
    }
  }

  private timeRangeValid(timeRange: TimeRange): boolean {
    if (!timeRange?.start || !timeRange?.end) {
      return false;
    }

    const timeRangeFG = {
      startTime: timeRange.start,
      endTime: timeRange.end
    } as TimeRangeSelectorFG;

    return this.startTimeOffsetLimits.min && validateMinTime(this.startTimeOffsetLimits.min, timeRangeFG.startTime) === null &&
      this.startTimeOffsetLimits.max && validateMaxTime(this.startTimeOffsetLimits.max, timeRangeFG.startTime) === null &&
      this.durationLimits.min && validateMinTimeDuration(this.durationLimits.min, timeRangeFG) === null &&
      this.durationLimits.max && validateMaxTimeDuration(this.durationLimits.max, timeRangeFG) === null;
  }

  private addVolumes(volumes: IOpVolumeSubmissionFG[] | null, emitEvent: boolean = false, options: {
    addToFormArray: boolean;
    newFormArray: boolean
  } = {
    addToFormArray: true,
    newFormArray: false
  }): void {
    const formGroups: IFormGroup<IOpVolumeSubmissionFG>[] = [];

    for (const volume of volumes) {
      // Do not include off nominal volumes when cloning/rerouting/replanning
      if (volume.offNominal) {
        continue;
      }

      const fg = this.fb.group<IOpVolumeSubmissionFG>({
        geography: [volume?.geography || null],
        circle: [volume?.circle || null],
        altitudeRange: [volume?.altitudeRange || null, [Validators.required]],
        timeRange: [this.timeRangeValid(volume.timeRange) ? volume?.timeRange : this.getDefaultTimeRange(), [Validators.required]]
      });
      formGroups.push(fg);

      fg.controls.geography.markAsDirty();
      fg.controls.altitudeRange.markAsDirty();
      fg.controls.timeRange.markAsDirty();
    }

    if (options.addToFormArray) {
      for (const fg of formGroups) {
        this.volumeArray.push(fg, {emitEvent: false});
      }
      if (emitEvent) {
        this.volumeArray.updateValueAndValidity();
      }
    } else {
      this.volumeArray.clear();
      for (const fg of formGroups) {
        this.volumeArray.push(fg, {emitEvent: false});
      }
      if (emitEvent) {
        this.volumeArray.updateValueAndValidity();
      }
      this.calculateSimpleVolDetails(volumes);
    }
  }

  private calculateSimpleVolDetails(volumes: IOpVolumeSubmissionFG[]) {
    // If all volumes are the same, check the simple details box
    this.simpleVolDetails = {
      simpleTimeRange: volumes.length < 2 || volumes.every(vol => TimeRange.equals(vol.timeRange, volumes[0].timeRange)),
      simpleAltitudeRange: volumes.length < 2 || volumes.every(vol => AltitudeRange.equals(vol.altitudeRange, volumes[0].altitudeRange))
    };

    this.handleSimpleVolDetailsChange(this.simpleVolDetails);
  }

  private convertAltitudes(altRef: vertical_reference, altUnits: units_of_measure) {
    if (this.volumeFgArray.length) {
      // If simple volume details is enabled, only convert the first volume's value
      const volsToConvert: IFormGroup<IOpVolumeSubmissionFG>[] = this.simpleVolDetails.simpleAltitudeRange ? [this.volumeFgArray[0]] : this.volumeFgArray;

      for (const [ordinal, vol] of volsToConvert.entries()) {
        // Convert vertical reference if needed
        if (altRef && vol.value.altitudeRange.altitude_vertical_reference !== altRef) {
          let convertedRange = _.cloneDeep(vol.value.altitudeRange);
          // Unsubscribe from any existing altitude conversion subscriptions
          if (ordinal === 0) {
            this.altitudeConversionSubs.forEach(sub => sub?.unsubscribe());
          }

          // Convert units to meters before using the reference conversion endpoint
          if (vol.value.altitudeRange.altitude_units !== units_of_measure.M) {
            convertedRange = this.convertRangeUnits(vol.value.altitudeRange, units_of_measure.M);
          }

          let coordinates: LatLng;
          if (vol.value.circle) {
            coordinates = new LatLng(vol.value.circle.latitude, vol.value.circle.longitude);
          } else if (vol.value.geography?.coordinates?.length) {
            coordinates = new LatLng(vol.value.geography.coordinates[0][0][1], vol.value.geography.coordinates[0][0][0]);
          } else {
            console.error('No geography supplied for altitude conversion');
          }

          // Prepare altitude conversion parameters
          const minAlt: IAltitudeConversionParameters = {
            lat: coordinates.lat,
            lon: coordinates.lng,
            altitude: convertedRange.min_altitude,
            input_reference: convertedRange.altitude_vertical_reference,
            output_reference: altRef
          };

          // Convert vertical reference
          this.altitudeConversionSubs.push(this.altitudeService.convertAltitude(minAlt).subscribe((min) => {
            const maxOffset = convertedRange.max_altitude - convertedRange.min_altitude;
            // Set form values
            convertedRange = new AltitudeRange({
              min_altitude: min.altitude,
              max_altitude: min.altitude + maxOffset,
              altitude_units: convertedRange.altitude_units,
              altitude_vertical_reference: min.reference,
              source: convertedRange.source
            });

            // Convert to selected units or back to the original units if altUnits is undefined
            if (altUnits && convertedRange.altitude_units !== altUnits) {
              convertedRange = this.convertRangeUnits(convertedRange, altUnits);
            } else if (!altUnits) {
              convertedRange = this.convertRangeUnits(convertedRange, vol.value.altitudeRange.altitude_units);
            }

            convertedRange.min_altitude = _.round(convertedRange.min_altitude, 2);
            convertedRange.max_altitude = _.round(convertedRange.max_altitude, 2);
            vol.controls.altitudeRange.patchValue(convertedRange);
          }));
        } else if (altUnits) {
          // If only converting units, convert the units
          vol.controls.altitudeRange.patchValue(this.convertRangeUnits(vol.value.altitudeRange, altUnits, true));
        }
      }
    }
  }

  private convertRangeUnits(range: AltitudeRange, destinationUnits: units_of_measure, round = false): AltitudeRange {
    const convertFrom = this.altitudeUtilService.parseUnitForConversion(range.altitude_units);
    const convertTo = this.altitudeUtilService.parseUnitForConversion(destinationUnits);

    return convertFrom !== convertTo ? new AltitudeRange({
      min_altitude: round ? _.round(new Converter(range.min_altitude).from(convertFrom).to(convertTo), 2) :
        new Converter(range.min_altitude).from(convertFrom).to(convertTo),
      max_altitude: round ? _.round(new Converter(range.max_altitude).from(convertFrom).to(convertTo), 2) :
        new Converter(range.max_altitude).from(convertFrom).to(convertTo),
      altitude_units: destinationUnits,
      altitude_vertical_reference: range.altitude_vertical_reference,
      source: range.source
    }) : range;
  }

  private zoomToVolumes() {
    const entities = this.volumeFgArray.map(group => {
      const circleVolume = group.controls.circle?.value;

      if (circleVolume) {
        return circle(point([circleVolume.longitude, circleVolume.latitude]), circleVolume.radiusMeters / 1000, {units: 'kilometers'});
      } else if (group.controls.geography.value?.coordinates) {
        return turfPolygon(group.controls.geography.value.coordinates);
      }
    });

    if (entities.length === 0 || !this.leafletMap) {
      return;
    }
    const features = featureCollection(entities);

    const box: number[] = bbox(envelope(features));
    const southWest = L.latLng(box[1], box[0]);
    const northEast = L.latLng(box[3], box[2]);
    const bounds = L.latLngBounds(southWest, northEast);
    this.leafletMap.fitBounds(bounds);
  }

  private setAllVolumeRows(sourceVol: IOpVolumeSubmissionFG) {
    this.volumeFgArray.forEach((volFG, i) => {
      if (i > 0) {
        volFG.patchValue({
          altitudeRange: sourceVol.altitudeRange,
          timeRange: sourceVol.timeRange
        }, {emitEvent: false});
        volFG.controls.altitudeRange.disable({emitEvent: false});
        volFG.controls.timeRange.disable({emitEvent: false});
      }
    });
  }

  private setAllVolumeRowsTime(sourceVol: IOpVolumeSubmissionFG) {
    this.volumeFgArray.forEach((volFG, i) => {
      if (i > 0) {
        volFG.patchValue({
          timeRange: sourceVol.timeRange
        }, {emitEvent: false});
        volFG.controls.timeRange.disable({emitEvent: false});
      }
    });
  }

  private setAllVolumeRowsAlt(sourceVol: IOpVolumeSubmissionFG) {
    this.volumeFgArray.forEach((volFG, i) => {
      if (i > 0) {
        volFG.patchValue({
          altitudeRange: sourceVol.altitudeRange,
        }, {emitEvent: false});
        volFG.controls.altitudeRange.disable({emitEvent: false});
      }
    });
  }

  private emitChange() {
    if (!this.fg.dirty && this.opModificationType === CreateOperationMode.none) {
      return;
    }

    if (this.onTouched) {
      this.onTouched();
    }

    let changes: IOpGeoSubmissionFG;
    if (this.fg.invalid || (this.leafletMap?.pm && (this.leafletMap.pm.globalEditModeEnabled() ||
      this.leafletMap.pm.globalDrawModeEnabled()))) {
      changes = null;
    } else {
      this.currentGeometry = {
        controllerLocation: this.fg.controls.controllerLocation.value || null,
        takeOffLocation: this.fg.controls.takeOffLocation.value ? {
          lat: this.fg.controls.takeOffLocation.value.lat,
          lng: this.fg.controls.takeOffLocation.value.lng,
          alt: this.volumeFgArray.length > 0 && this.volumeFgArray[0].getRawValue().altitudeRange ?
            this.volumeFgArray[0].getRawValue().altitudeRange.min_altitude : -1
        } : null,
        volumes: this.getVolumeValues(false)
      };
      changes = this.currentGeometry;
    }
    if (this.onChange) {
      this.onChange(changes);
    }
  }

  private emitAreaOfInterestChange() {
    if (!this.leafletMap) {
      return;
    }
    const bounds = this.leafletMap.getBounds();

    const volQuery: Volume3dQuery = {
      envelope: {
        north: bounds.getNorth(),
        west: bounds.getWest(),
        south: bounds.getSouth(),
        east: bounds.getEast(),
      }
    };

    this.spatialQuerySubject.next(volQuery);
  }

  private cleanUpEmptyVolumes() {
    let volumeRemoved = false;
    let i = 0;
    while (i < this.volumeArray.length) {
      if (!this.volumeArray.at(i).value.geography && !this.volumeArray.at(i).value.circle) {
        this.volumeArray.removeAt(i);
        volumeRemoved = true;
        continue;
      }
      ++i;
    }
    if (volumeRemoved) {
      this.handleSimpleVolDetailsChange(this.simpleVolDetails);
    }
  }

  private touched() {
    if (this.onTouched) {
      this.onTouched();
    }
    this.fg.controls.controllerLocation.markAsDirty();
    this.fg.controls.takeOffLocation.markAsDirty();
    this.fg.controls.volumes.markAsDirty();
    this.fg.markAsDirty();
    this.fg.markAllAsTouched();
  }

  private reset() {
    this.volumeArray.clear();
    this.fg.reset({
      controllerLocation: null,
      takeOffLocation: null,
      volumes: []
    });

    for (const control of [this.fg.controls.controllerLocation, this.fg.controls.takeOffLocation, this.fg.controls.volumes, this.fg]) {
      control.markAsPristine();
      control.markAsUntouched();
    }
  }

  private getVolumeValues(partial: boolean = false): IOpVolumeSubmissionFG[] {
    let defaultAltitudeRange: AltitudeRange = null;
    let defaultTimeRange: TimeRange | null = null;
    return this.volumeFgArray.map(fg => {
      const rawValues = _.cloneDeep(fg.getRawValue());
      let altRange = defaultAltitudeRange;
      if (!fg.controls.altitudeRange.disabled) {
        altRange = new AltitudeRange({
          min_altitude: partial ? rawValues.altitudeRange?.min_altitude : rawValues.altitudeRange.min_altitude,
          max_altitude: partial ? rawValues.altitudeRange?.max_altitude : rawValues.altitudeRange.max_altitude,
          altitude_units: this.selectedUnits,
          altitude_vertical_reference: this.selectedVertRef,
        });
      }

      const value: IOpVolumeSubmissionFG = {
        geography: rawValues.geography,
        circle: rawValues.circle,
        altitudeRange: altRange,
        timeRange: fg.controls.timeRange.disabled ? defaultTimeRange : rawValues.timeRange,
      };

      defaultAltitudeRange = value.altitudeRange;
      defaultTimeRange = value.timeRange;

      return value;
    });
  }

  mergeOperationVolumes(mergeMode: MergeMode) {
    const volumes = this.getVolumeValues();
    if (volumes.length < 1) {
      return;
    }
    const newVolumes = mergeOperationVolumes(mergeMode, volumes);
    newVolumes.forEach(vol => {
      if (!vol.geography) {
        return;
      }

      const poly = polygon(vol.geography.coordinates);
      vol.geography.coordinates = [coordReduce(poly, (acc, curr) => {
        if (acc.length === 0) {
          acc.push(curr);
          return acc;
        }
        const prev = acc[acc.length - 1];
        const distM = distance(
          point(prev),
          point(curr), {
            units: 'kilometers'
          }) * 1000;

        //If the distance between the two points is greater than 10 centimeters, add the point to the array
        if (distM * 100 > 10) {
          acc.push(curr);
        }

        return acc;
      }, [])];

    });


    this.addVolumes(newVolumes, true, {addToFormArray: false, newFormArray: true});
    this.fg.updateValueAndValidity();

    this.zoomToVolumes();


  }


  handleSimpleVolTimeRangeChange($event: boolean) {
    this.simpleVolDetails.simpleTimeRange = $event;
    this.handleSimpleVolDetailsChange(this.simpleVolDetails);
  }

  handleSimpleVolAltitudeRangeChange($event: boolean) {
    this.simpleVolDetails.simpleAltitudeRange = $event;
    this.handleSimpleVolDetailsChange(this.simpleVolDetails);
  }

  handleGeoCodeChange($event: LatLngBounds) {
    const viewer = this.previewViewer();
    if(!viewer){
      return;
    }

    const target = new Rectangle(
      CMath.toRadians($event.getWest()),
      CMath.toRadians($event.getSouth()),
      CMath.toRadians($event.getEast()),
      CMath.toRadians($event.getNorth()),
    )

    viewer.scene.camera.flyTo({
      destination: target
    });

  }
}

