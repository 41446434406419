import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UTMFlightPlan} from '../common';

import {LatLngPoint} from '../../../../../../model/WaypointParser';
import {
  FlightPlanComponent,
  FlightPlanComponentOptions,
  generateFlightPlanComponentVolumes, GeometricStuff
} from '../../../../../../utils/PointBufferer2';
import {OperationGeometry} from '../../../../../../model/operation.geometry';
import {OperationVolume, units_of_measure, vertical_reference} from '../../../../../../model/gen/utm';
import {AltitudeType, Convert, UGCSFlightPlan} from './UGCSParser';
import {Observable} from 'rxjs';
import {AltitudeService} from '../../../../../altitude.service';
import {map} from 'rxjs/operators';
import {radiansToDegrees} from '@turf/turf';
import buffer from '@turf/buffer';
import {polygon} from '@turf/turf';
import {from} from "rxjs";

const zeroValidator = Validators.min(0);


export class UGCSUTMFlightPlan implements UTMFlightPlan {

  constructor(private flightPlan: UGCSFlightPlan, private altitudeService: AltitudeService) {
    const estimatedHome = this.estimateHomePosition();
    this.options.controls.homePosition.setValue(estimatedHome);
  }

  readonly options = new FormGroup({
    homePosition: new FormControl<LatLngPoint>(null, [Validators.required]),
    horizontalBufferDistanceMeters: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_HORIZONTAL_BUFFER_DISTANCE_METERS, [
      Validators.required,
      Validators.min(1)
    ]),
    verticalBufferDistanceMeters: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_VERTICAL_BUFFER_DISTANCE_METERS, [
      Validators.required,
      Validators.min(5)
    ]),
    // bufferUnits: new FormControl<units_of_measure>(units_of_measure.M, [Validators.required]),
    steps: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_STEPS, [
      Validators.required,
      Validators.min(3)
    ])
  });

  static fromText(text: string, altitudeService: AltitudeService): UGCSUTMFlightPlan {
    const rawFlightPlan = Convert.toUGCSFlightPlan(text);

    return new UGCSUTMFlightPlan(rawFlightPlan, altitudeService);
  }

  private async getFlightPlanComponents(): Promise<[FlightPlanComponent[], vertical_reference]> {

    const route = this.flightPlan.route;

    const home = this.options.controls.homePosition.value;
    let lastPoint = home;
    const volumes: FlightPlanComponent[] = [];
    let point: LatLngPoint;
    let altitudeType: AltitudeType|null = null;
    if (!(route.takeoffHeight === undefined || route.takeoffHeight === null)){
      home.alt = route.takeoffHeight;
    }


    for (const [index, segment] of route.segments.entries()){
      if (segment.type === 'TakeOff' || segment.type === 'Waypoint' || segment.type === 'Landing'){
        const currentAltType = segment.point.altitudeType;
        const latitude = radiansToDegrees(segment.point.latitude);
        const longitude = radiansToDegrees(segment.point.longitude);
        let altitude = segment.point.altitude;
        if (altitudeType === null){
          altitudeType = currentAltType;
        } else if (altitudeType !== currentAltType){
          altitude = await this.convertAltitude({
            lat: latitude,
            lng: longitude,
            alt: altitude
          }, currentAltType, altitudeType);

        }

        point = {
          lat: latitude,
          lng: longitude,
          alt: altitude
        };
        volumes.push({
          kind: 'segment',
          index,
          a: lastPoint,
          b: point
        });
        lastPoint = point;
        continue;
      }
      if (segment.type === 'Circle'){

        const rawCenterPoint = segment.circle.center;
        const currentAltType = rawCenterPoint.altitudeType;
        const latitude = radiansToDegrees(rawCenterPoint.latitude);
        const longitude = radiansToDegrees(rawCenterPoint.longitude);
        let altitude = rawCenterPoint.altitude;
        if (altitudeType === null){
          altitudeType = currentAltType;
        } else if (altitudeType !== currentAltType){
          altitude = await this.convertAltitude({
            lat: latitude,
            lng: longitude,
            alt: altitude
          }, currentAltType, altitudeType);
        }

        const center = {
          lat: latitude,
          lng: longitude,
          alt: altitude
        };
        volumes.push({
          kind: 'segment',
          index,
          a: lastPoint,
          b: center
        });
        volumes.push({
          kind: 'loiter',
          index,
          center,
          radius: segment.circle.radius
        });
        lastPoint = center;
        continue;
      }
      if (segment.type === 'AreaScan'){
        const currentAltType = segment.parameters.altitudeType;
        let altitude = segment.parameters.height;
        const poly = polygon([segment.polygon.points.map((point) => {
          return [
            radiansToDegrees(point.longitude),
            radiansToDegrees(point.latitude)
          ];
        })]);
        const bufferDistance = Math.max(segment.parameters.sideDistance || 0, segment.parameters.overshoot || 0);
        const bufferedPolygon = buffer(poly, bufferDistance, {
          units: 'meters',
        });

        const point = {
          lat: radiansToDegrees(segment.polygon.points[0].latitude),
          lng: radiansToDegrees(segment.polygon.points[0].longitude),
          alt: altitude
        };

        if (altitudeType === null){
          altitudeType = currentAltType;
        } else if (altitudeType !== currentAltType){
          console.error('Altitude type mismatch');
          point.alt = altitude = await this.convertAltitude({
            lat: point.lat,
            lng: point.lng,
            alt: point.alt
          }, currentAltType, altitudeType);
        }

        volumes.push({
          kind: 'segment',
          index,
          a: lastPoint,
          b: point
        });

        volumes.push({
          kind: 'polygon',
          index,
          points: bufferedPolygon.geometry.coordinates[0].map((coord) => {
            return {
              lat: coord[1],
              lng: coord[0],
              alt: altitude
            };
          }),
          minAltitude: altitude,
          maxAltitude: altitude
        });
        const tmpPoint = segment.polygon.points[segment.polygon.points.length - 2];
        lastPoint = {
          lat: radiansToDegrees(tmpPoint.latitude),
          lng: radiansToDegrees(tmpPoint.longitude),
          alt: altitude

        };
        continue;
      }
      console.log('Unknown segment type');

    }
    return [volumes, altitudeType === AltitudeType.Agl || altitudeType === AltitudeType.SmartAgl ? vertical_reference.AGL : vertical_reference.MSL];

  }


  toOperationGeometry(): Observable<OperationGeometry> {

    return from(this.getFlightPlanComponents()).pipe(map(([volumes, reference]) => {
      const rawOptions = this.options.getRawValue();
      const options: FlightPlanComponentOptions = {
        steps: rawOptions.steps,
        bufferDistance: rawOptions.horizontalBufferDistanceMeters,
        bufferUnits: units_of_measure.M,
        endTime: undefined,
        startTime: undefined,
        verticalBufferMeters: rawOptions.verticalBufferDistanceMeters,
        verticalReference: reference,
        verticalUnits: units_of_measure.M
      };

      let results: OperationVolume[] = [];
      for (const segment of volumes) {
        results = results.concat(generateFlightPlanComponentVolumes(segment, options));
      }


      return new OperationGeometry(
        results,
        undefined,
        undefined,
        this.options.controls.homePosition.value
      );
    }));

  }


  private estimateHomePosition(): LatLngPoint | null {

    const route = this.flightPlan.route;
    const validSegmentTypes = new Set<string>(['TakeOff', 'Landing', 'Waypoint']);
    let home: LatLngPoint | null = null;
    for (const segment of route.segments){
      if (!validSegmentTypes.has(segment.type)){
        continue;
      }
      const point = {
        lat: radiansToDegrees(segment.point.latitude),
        lng: radiansToDegrees(segment.point.longitude),
        alt: segment.point.altitude
      };
      if (segment.type === 'TakeOff' || segment.type === 'Landing'){
        return point;
      }
      if (home === null){
        home = point;
      }
    }

    return home;
  }

  private async convertAltitude(point: LatLngPoint, currentAltitudeType: AltitudeType, targetAltitudeType: AltitudeType) {
    if (currentAltitudeType === targetAltitudeType){
      return point.alt;
    }
    const currentReference = this.altitudeTypeToVerticalReference(currentAltitudeType);
    const targetReference = this.altitudeTypeToVerticalReference(targetAltitudeType);

    if (currentReference === null || targetReference === null){
      return point.alt;
    }

    const response = await  this.altitudeService.convertAltitudeAsync( {
      lat: point.lat,
      lon: point.lng,
      altitude: point.alt,
      input_reference: currentReference,
      output_reference: targetReference
    });

    return response.altitude;
  }

  private altitudeTypeToVerticalReference(altitudeType: AltitudeType): vertical_reference{
    switch (altitudeType) {
      case AltitudeType.Agl:
      case AltitudeType.SmartAgl:
        return vertical_reference.AGL;
      case AltitudeType.Amsl:
        return vertical_reference.MSL;
      case AltitudeType.Wgs84:
        return vertical_reference.W84;
      default:
        console.error('Invalid altitude type');
        return null;
    }
  }
}

export type UGCSFlightPlanFormGroup = Pick<UGCSUTMFlightPlan, 'options'>['options'];
