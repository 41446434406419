import {inject, Injectable} from "@angular/core";
import {LeafletDrawerService} from "@ax/ax-angular-map-leaflet";
import {toSignal} from "@angular/core/rxjs-interop";
import {Observable, share} from "rxjs";
import {LeafletEvent} from "leaflet";
import {switchMap} from "rxjs/operators";

function eventToObservable(map:L.Map, event:string):Observable<any> {
  return new Observable(subscriber => {
    const cb = (e:LeafletEvent) => {
      subscriber.next(e);
    };
    map.on(event, cb);

    return () => {
      // console.log('unsubscribing from event', event);
      map.off(event, cb);
    }
  });
}

@Injectable()
export class LeafletExtService {

  private leafletService = inject(LeafletDrawerService);

  map$ = this.leafletService.watchViewerInit().pipe(share());
  mapSignal = toSignal(this.map$);


  /**
   * Returns an observable that emits the specified event from the map.
   * @param event The event to listen for.
   * @returns An observable that emits the event.
   */
  on(event:string):Observable<LeafletEvent> {
    return this.map$.pipe(switchMap(map => eventToObservable(map, event)));
  }

}
