import {BaseEnumDeserializer} from '../../common';
import {fail, Result, some} from '../../../utils';

export enum LaancFrRules {
  /**
   * Operation intersects with a UASFM where LAANC Ready flag is False.
   */
  UASFM_NOT_ENABLED = 'UASFM_NOT_ENABLED',

  /**
   * Operation Requires 107-FC in a  UASFM that is enabled but doesn't support 107-FC
   */
  UASFM_107_FC_UNAVAILABLE = 'UASFM_107_FC_UNAVAILABLE',

  /**
   * Operation Requires 44809-AA in a UASFM that is enabled but doesn't support 44809-AA
   */
  UASFM_44809_AA_UNAVAILABLE = 'UASFM_44809_AA_UNAVAILABLE',

  /**
   * Block an operator from submitting an operation within a surface airspace that has no UASFM
   */
  AIRSPACE_NO_UASFM = 'AIRSPACE_NO_UASFM',

  /**
   * (Block) Operations  exceeding 400 feet (107.51b)
   */
  EXCEEDING_400_FT = 'EXCEEDING_400_FT',

  /**
   * (Advise) Part 107 Operations at night (107.29)
   */
  AT_NIGHT_PART_107 = 'AT_NIGHT_PART_107',

  /**
   * (Advise) 44809 Operations at  night
   */
  AT_NIGHT_44809 = 'AT_NIGHT_44809',

  /**
   * (Block) Operations in an NSUFR or the DC FRZ (107.47)
   */
  IN_NSUFR = 'IN_NSUFR',

  /**
   * (Block) Operations in an NSUFR or the DC FRZ (107.47)
   */
  IN_DC_FRZ = 'IN_DC_FRZ',

  /**
   * (Block) Operations in a Prohibited or active Restricted SUA (107.45)
   */
  IN_PROHIBITED_OR_RESTRICT_SUA = 'IN_PROHIBITED_OR_RESTRICT_SUA',

  /**
   * (Advise) Operations at civil twilight (107.29)
   */
  AT_CIVIL_TWILIGHT = 'AT_CIVIL_TWILIGHT',

  /**
   * Operations in a TFR (107.47)
   */
  IN_TFR = 'IN_TFR',

  /**
   * (Advise) Operations in another type of  SUA (MOA, CFA, Warning,  Alert, etc.) (107.49)
   */
  IN_OTHER_SUA_TYPE = 'IN_OTHER_SUA_TYPE',

  /**
   * (Advise) Operations within 3NM of a stadium (107.47)
   */
  WITHIN_3NM_OF_STADIUM = 'WITHIN_3NM_OF_STADIUM',

  TOO_SOON_FOR_FC = 'TOO_SOON_FOR_FC',

  UNACKNOWLEDGED_AUTH = 'UNACKNOWLEDGED_AUTH',

  /**
   * [3.7c] Block any submissions associated with a new operation if there are already five (or
   * more) non-pending operations for the same operator (name and phone number) occurring
   * with an overlapping time period. A message should be generated to inform the user of the
   * reason for the block.
   */
  MAX_OPERATIONS = 'MAX_OPERATIONS',

  /**
   * [3.7b] Block submissions with an operation area larger, in maximum linear extent, than 10
   * nautical miles.
   */
  AREA_TOO_LARGE = 'AREA_TOO_LARGE',

  /**
   * [3.7d] Block submissions (automatic or Further Coordination) that are more than 100 nautical
   * miles from an existing non-pending operation for the same operator (name and phone
   * number) for an overlapping time period. Specifically, two operation volumes are more than
   * 100 nautical miles apart if the nearest points between the two volumes are a distance greater
   * than 100 nautical miles from each other. A message should be generated to inform the user of
   * the reason for the block.
   */
  OVER_100NM_FROM_EXISTING_OPERATION = 'OVER_100NM_FROM_EXISTING_OPERATION',

  /**
   * [3.7e] If a new submission is made when there is one (or more) non-pending operations for the
   * same operator (name and phone number) occurring with an overlapping time period, the USS
   * must display a message to user that there are one or more operations for this operator and time
   * period and unused operations should be cancelled before the operation start time.
   */
  EXISTING_OPERATION = 'EXISTING_OPERATION',

  /**
   * Short term unexpected LAANC downtime or connection issues to LAANC System. Temporary outage, try again later.
   */
  LAANC_DOWN_TEMP = 'LAANC_DOWN_TEMP',

  /**
   * Unexpected LAANC downtime or connection issues persisting over 4 hours.
   */
  LAANC_DOWN_PROTRACTED = 'LAANC_DOWN_PROTRACTED',

  /**
   * LAANC down for scheduled reasons.
   */
  LAANC_DOWN_SCHEDULED = 'LAANC_DOWN_SCHEDULED',

  CLASS_E_AIRSPACE = 'CLASS_E_AIRSPACE',
}

export class LaancFrRulesUtil implements BaseEnumDeserializer<LaancFrRules, LaancFrRules> {
  humanized: {[key in LaancFrRules]: string} = {
    [LaancFrRules.UASFM_NOT_ENABLED]: 'LAANC Not Enabled in This Area',
    [LaancFrRules.UASFM_107_FC_UNAVAILABLE]: 'Part 107 Further Coordination Operations Not Permitted in This Area',
    [LaancFrRules.UASFM_44809_AA_UNAVAILABLE]: 'Section 44809 Auto-Approval Operations Not Permitted in This Area',
    [LaancFrRules.AIRSPACE_NO_UASFM]: 'Airspace Does Not Have UAS Facility Map',
    [LaancFrRules.EXCEEDING_400_FT]: 'Exceeds 400FT',
    [LaancFrRules.AT_NIGHT_PART_107]: 'At Night (Part 107)',
    [LaancFrRules.AT_NIGHT_44809]: 'At Night (Section 44809)',
    [LaancFrRules.IN_NSUFR]: 'In National Security UAS Flight Restriction',
    [LaancFrRules.IN_DC_FRZ]: 'In DC Flight-Restricted Zone',
    [LaancFrRules.IN_PROHIBITED_OR_RESTRICT_SUA]: 'In Prohibited or Restricted Special Use Airspace',
    [LaancFrRules.AT_CIVIL_TWILIGHT]: 'At Civil Twilight',
    [LaancFrRules.IN_TFR]: 'In Temporary Flight Restriction',
    [LaancFrRules.IN_OTHER_SUA_TYPE]: 'In Other Special Use Airspace Type',
    [LaancFrRules.WITHIN_3NM_OF_STADIUM]: 'Within 3 NM of a Stadium',
    [LaancFrRules.TOO_SOON_FOR_FC]: 'Further Coordination Operations Must Start at Least 72 Hrs in Advance',
    [LaancFrRules.UNACKNOWLEDGED_AUTH]: 'Pilot Has Unacknowledged Authorization(s)',
    [LaancFrRules.MAX_OPERATIONS]: 'Maximum of 5 Simultaneous Non-Pending Operations Exceeded',
    [LaancFrRules.AREA_TOO_LARGE]: 'Operation Area Exceeds 10 NM',
    [LaancFrRules.OVER_100NM_FROM_EXISTING_OPERATION]: 'Over 100NM From an Existing Operation',
    [LaancFrRules.EXISTING_OPERATION]: 'An Existing Operation Is Occurring at the Same Time',
    [LaancFrRules.LAANC_DOWN_TEMP]: 'LAANC System Is Temporarily Unavailable. Please Try Again Later.',
    [LaancFrRules.LAANC_DOWN_PROTRACTED]: 'LAANC System Is Unavailable',
    [LaancFrRules.LAANC_DOWN_SCHEDULED]: 'LAANC System Is Down For Scheduled Maintenance. Please Try Again Later.',
    [LaancFrRules.CLASS_E_AIRSPACE]: 'Class E Surface Area Weather Ceiling Caveat'
  }

  deserialize(raw: unknown): Result<LaancFrRules> {
    if (typeof raw !== 'string') return fail('Invalid type for LAANC FR rules');

    switch (raw.toUpperCase()) {
      case 'UASFM_NOT_ENABLED':
        return some(LaancFrRules.UASFM_NOT_ENABLED);
      case 'UASFM_107_FC_UNAVAILABLE':
        return some(LaancFrRules.UASFM_107_FC_UNAVAILABLE);
      case 'UASFM_44809_AA_UNAVAILABLE':
        return some(LaancFrRules.UASFM_44809_AA_UNAVAILABLE);
      case 'AIRSPACE_NO_UASFM':
        return some(LaancFrRules.AIRSPACE_NO_UASFM);
      case 'EXCEEDING_400_FT':
        return some(LaancFrRules.EXCEEDING_400_FT);
      case 'AT_NIGHT_PART_107':
        return some(LaancFrRules.AT_NIGHT_PART_107);
      case 'AT_NIGHT_44809':
        return some(LaancFrRules.AT_NIGHT_44809);
      case 'IN_NSUFR':
        return some(LaancFrRules.IN_NSUFR);
      case 'IN_DC_FRZ':
        return some(LaancFrRules.IN_DC_FRZ);
      case 'IN_PROHIBITED_OR_RESTRICT_SUA':
        return some(LaancFrRules.IN_PROHIBITED_OR_RESTRICT_SUA);
      case 'AT_CIVIL_TWILIGHT':
        return some(LaancFrRules.AT_CIVIL_TWILIGHT);
      case 'IN_TFR':
        return some(LaancFrRules.IN_TFR);
      case 'IN_OTHER_SUA_TYPE':
        return some(LaancFrRules.IN_OTHER_SUA_TYPE);
      case 'WITHIN_3NM_OF_STADIUM':
        return some(LaancFrRules.WITHIN_3NM_OF_STADIUM);
      case 'TOO_SOON_FOR_FC':
        return some(LaancFrRules.TOO_SOON_FOR_FC);
      case 'UNACKNOWLEDGED_AUTH':
        return some(LaancFrRules.UNACKNOWLEDGED_AUTH);
      case 'MAX_OPERATIONS':
        return some(LaancFrRules.MAX_OPERATIONS);
      case 'AREA_TOO_LARGE':
        return some(LaancFrRules.AREA_TOO_LARGE);
      case 'OVER_100NM_FROM_EXISTING_OPERATION':
        return some(LaancFrRules.OVER_100NM_FROM_EXISTING_OPERATION);
      case 'EXISTING_OPERATION':
        return some(LaancFrRules.EXISTING_OPERATION);
      case 'LAANC_DOWN_TEMP':
        return some(LaancFrRules.LAANC_DOWN_TEMP);
      case 'LAANC_DOWN_PROTRACTED':
        return some(LaancFrRules.LAANC_DOWN_PROTRACTED);
      case 'LAANC_DOWN_SCHEDULED':
        return some(LaancFrRules.LAANC_DOWN_SCHEDULED);
      case 'CLASS_E_AIRSPACE':
        return some(LaancFrRules.CLASS_E_AIRSPACE);
      default:
        return fail(`Invalid LAANC FR rules: ${raw}`);
    }
  }

  deserializeArray(raw: unknown): Result<LaancFrRules[]> {
    if (!Array.isArray(raw)) return fail('Invalid type for LAANC FR rules array');
    const results: LaancFrRules[] = [];
    let error = '';
    raw.some((val: any) => {
      let result = this.deserialize(val);
      if (result.type === 'error') {
        error = result.message;
        return true;
      } else {
        results.push(result.value);
        return false;
      }
    });
    return error ? fail(error) : some(results);
  }
}

export const DEFAULT_LAANC_FR_RULES_UTIL = new LaancFrRulesUtil();
