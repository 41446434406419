import {fail, isEmpty, isObject, Result, some} from '../../utils';
import {BaseModel, BaseModelDeserializer} from '../common';
import {
  DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL,
  DEFAULT_OPERATION_LAANC_STATUS_UTIL,
  LaancSubmissionCategory,
  OperationLaancStatus
} from './enums';

export interface TOperationLaancInfo {
  readonly operationCode: string;
  readonly referenceCodes: string[];
  readonly category: LaancSubmissionCategory;
  readonly status: OperationLaancStatus;
  readonly hasAutoAuthorization?: boolean;
  readonly hasFurtherCoordination?: boolean;
}

export interface IOperationLaancInfo {
  readonly operationCode: string;
  readonly referenceCodes: string[];
  readonly category: LaancSubmissionCategory;
  readonly status: OperationLaancStatus;
  readonly hasAutoAuthorization?: boolean;
  readonly hasFurtherCoordination?: boolean;
}

export class OperationLaancInfo implements IOperationLaancInfo, BaseModel {
  readonly operationCode: string;
  readonly referenceCodes: string[];
  readonly category: LaancSubmissionCategory;
  readonly status: OperationLaancStatus;
  readonly hasAutoAuthorization?: boolean;
  readonly hasFurtherCoordination?: boolean;

  constructor(values: IOperationLaancInfo) {
    this.operationCode = values.operationCode;
    this.referenceCodes = values.referenceCodes;
    this.category = values.category;
    this.status = values.status;
    this.hasAutoAuthorization = values.hasAutoAuthorization;
    this.hasFurtherCoordination = values.hasFurtherCoordination;
  }
}

export class OperationLaancInfoUtil implements BaseModelDeserializer<TOperationLaancInfo, OperationLaancInfo> {
  deserialize(raw: unknown): Result<OperationLaancInfo> {
    if (isEmpty(raw)) return fail('No data supplied for operation LAANC info');
    if (!isObject(raw)) return fail('Invalid data supplied for operation LAANC info');

    if (!('operationCode' in raw) || isEmpty(raw.operationCode)) return fail('No operation code value');
    if (typeof raw.operationCode !== 'string') return fail('Invalid operation code value');

    if (!('referenceCodes' in raw) || isEmpty(raw.referenceCodes)) return fail('No reference codes value');
    if (!Array.isArray(raw.referenceCodes)) return fail('Invalid reference codes value');

    if (!('category' in raw) || isEmpty(raw.referenceCodes)) return fail('No category value');
    const category = DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL.deserialize(raw.category);
    if (category.type === 'error') return fail(category.message);

    if (!('status' in raw) || isEmpty(raw.status)) return fail('No status value');
    const status = DEFAULT_OPERATION_LAANC_STATUS_UTIL.deserialize(raw.status);
    if (status.type === 'error') return fail(status.message);

    let hasAutoAuthorization: boolean | undefined = undefined;
    if ('hasAutoAuthorization' in raw && !isEmpty(raw.hasAutoAuthorization)) {
      if (typeof raw.hasAutoAuthorization !== 'boolean') return fail('Invalid has auto authorization value');
      hasAutoAuthorization = raw.hasAutoAuthorization;
    }

    let hasFurtherCoordination: boolean | undefined = undefined;
    if ('hasFurtherCoordination' in raw && !isEmpty(raw.hasFurtherCoordination)) {
      if (typeof raw.hasFurtherCoordination !== 'boolean') return fail('Invalid has further coordination value');
      hasFurtherCoordination = raw.hasFurtherCoordination;
    }

    return some(new OperationLaancInfo({
      operationCode: raw.operationCode,
      referenceCodes: raw.referenceCodes,
      category: category.value,
      status: status.value,
      hasAutoAuthorization,
      hasFurtherCoordination
    }));
  }
}

export const DEFAULT_OPERATION_LAANC_INFO_UTIL = new OperationLaancInfoUtil();
