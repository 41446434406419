import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserMessageEntry} from '../../../model/UserMessage/UserMessageEntry';
import {UserMessageService} from '../../../services/user-message.service';
import {interval, Subject, Subscription} from 'rxjs';
import {ConformanceState, OperatorIntentType} from '../../../model/UserMessage/enums';
import {OperatorIntentResult} from '../../../model/UserMessage/OperatorIntentResult';
import {ConformanceAlertMessage} from '../../../model/UserMessage/ConformanceAlertMessage';
import {UserMessage} from '../../../model/UserMessage/UserMessage';
import {ConstraintAlertMessage} from '../../../model/UserMessage/ConstraintAlertMessage';
import {DateTime} from 'luxon';
import {OperationService} from '../../../services/operation.service';
import {UserMessageUtil} from '../../../model/UserMessage/UserMessageUtil';
import {OperationExt} from '../../../model/utm/OperationExt';
import {OperationConflictAlertMessage} from '../../../model/UserMessage/OperationConflictAlertMessage';
import {ApprovalStatus, state} from '../../../model/gen/utm';
import * as _ from 'lodash';
import {exhaustMap} from 'rxjs/operators';
import {CreateOperationMode} from '../../../../fuss/operations/new-operations/new-operations.component';
import {ApprovalAlertMessage} from '../../../model/UserMessage/ApprovalAlertMessage';
import {LaancSubmissionState} from '@ax-uss-ui/common';
import {LaancAlertMessage} from '../../../model/UserMessage/LaancAlertMessage';

@Component({
  selector: 'app-user-message-alert-bar',
  templateUrl: './user-message-alert-bar.component.html',
  styleUrls: ['./user-message-alert-bar.component.scss']
})
export class UserMessageAlertBarComponent implements OnInit, OnDestroy {
  currentMessage: UserMessageEntry;
  modalSize: string;
  operationCache: { [key: string]: OperationExt };
  filterMessageSubject: Subject<UserMessageEntry[]> = new Subject<UserMessageEntry[]>();
  messages: UserMessageEntry[] = [];
  errorMessages: UserMessageEntry[] = [];
  mode = CreateOperationMode;
  state = state;
  laancSubmissionState = LaancSubmissionState;

  private combinedMessages: UserMessageEntry[] = [];
  private msgSub: Subscription;
  private setUnreadMsgsSub: Subscription;
  private msgSummarySub: Subscription;
  private intervalSub: Subscription;
  private markMsgReadSub: Subscription;
  private cacheSubs: Subscription[] = [];
  private activeOperationCacheSubscriptions: { [key: string]: Subscription } = {};
  private filterMessageSub: Subscription;

  constructor(private userMessageService: UserMessageService,
              private operationService: OperationService) {
    this.operationCache = {};
    this.filterMessageSub = this.filterMessageSubject.pipe(exhaustMap(msgs => {
      const msgIds = msgs.map(m => m.id);
      this.combinedMessages = this.combinedMessages.filter(msg => !msgIds.includes(msg.id));
      this.updatePublicMessages();
      return this.userMessageService.markMessagesAsRead(msgs.filter(m => !m.read).map(msg => msg.id));
    })).subscribe();
  }

  appendMessages(msgs: UserMessageEntry[]): void{
    if (this.combinedMessages.length > 0) {
      // If the message is already present in this.messages, replace it; otherwise, append it.
      msgs.forEach(msg => {
        if (this.combinedMessages.find(message => message.id === msg.id)) {
          this.combinedMessages = this.combinedMessages.map(message => message.id === msg.id ? msg : message);
        } else {
          this.combinedMessages.push(msg);
        }
      });
    } else {
      this.combinedMessages.push(...msgs);
    }
    this.filterMessages();
    this.sortMessages();

    if (Object.keys(this.operationCache).length > 20) {
      this.operationCache = {};
    }
    this.cacheOperations(msgs);
    this.updatePublicMessages();
  }

  ngOnInit(): void {
    this.setUnreadMsgsSub = this.userMessageService.getMessages({
      read: false
    }, 100, 0, false).subscribe(result => {
      this.appendMessages(result.results);
    });

    // Filter and sort messages when new or updated message is received
    this.msgSub = this.userMessageService.watchMessages().subscribe((msgs) => {
      this.appendMessages(msgs);
    });

    // Filter  messages every second to check if they have expired
    this.intervalSub = interval(1000).subscribe(() => {
      if (this.combinedMessages.length > 0) {
        this.filterMessages();
        this.updatePublicMessages();
      }
    });
  }

  ngOnDestroy(): void {
    this.cacheSubs.forEach(sub => sub?.unsubscribe());
    this.msgSub?.unsubscribe();
    this.setUnreadMsgsSub?.unsubscribe();
    this.msgSummarySub?.unsubscribe();
    this.intervalSub?.unsubscribe();
    this.markMsgReadSub?.unsubscribe();
    this.filterMessageSub?.unsubscribe();
    Object.values(this.activeOperationCacheSubscriptions).forEach(sub => {
      sub.unsubscribe();
    });
  }

  getAlertType(message: UserMessageEntry): string {
    return UserMessageUtil.getAlertType(message);
  }

  getAlertClosable(message: UserMessageEntry): boolean {
    if (message.message.type === 'LAANC_ALERT') {
      const laancAlertMessage = this.getLaancAlertMessageData(message.message);
      return !laancAlertMessage.requiresAck;
    } else {
      return true;
    }
  }

  viewMessage(message: UserMessageEntry) {
    this.currentMessage = message;
    this.modalSize = UserMessageUtil.getModalSize(message.message);

    // Mark the message as read after clicking the view message button
    // Exception: LAANC alert messages that require acknowledgement
    if (!(message.message.type === 'LAANC_ALERT' && (message.message.data as LaancAlertMessage).requiresAck)) {
      this.markMessagesAsRead([message]);
    }
  }

  getOperatorIntentResultMessageData(message: UserMessage): OperatorIntentResult {
    return message.data as OperatorIntentResult;
  }

  getOperationConflictMessageData(message: UserMessage): OperationConflictAlertMessage {
    return message.data as OperationConflictAlertMessage;
  }

  getConformanceAlertMessageData(message: UserMessage): ConformanceAlertMessage {
    return message.data as ConformanceAlertMessage;
  }

  getConstraintAlertMessageData(message: UserMessage): ConstraintAlertMessage {
    return message.data as ConstraintAlertMessage;
  }

  getLaancAlertMessageData(message: UserMessage): LaancAlertMessage {
    return message.data as LaancAlertMessage;
  }

  getMessageSummary(message: UserMessageEntry): string {
    return UserMessageUtil.getSummaryText(message.message);
  }

  clearMessageAtIndex(index: number): void {
    if (index > this.messages.length) {
      return;
    }
    this.markMessagesAsRead([this.messages[index]]);
    this.messages.splice(index, 1);
  }

  markMessagesAsRead(messages: UserMessageEntry[]) {
    this.filterMessageSubject.next(messages);
  }

  clearMessageById(message: UserMessageEntry): void {
    if (!message) {
      return;
    }
    this.markMessagesAsRead([message]);
  }

  private filterMessages() {
    const now = DateTime.fromMillis(Date.now());
    const unfilteredMsgs = _.cloneDeep(this.combinedMessages);

    this.combinedMessages = this.combinedMessages.filter(m => {
      if (m.read) {
        return false;
      }
      // If this is an error message, check if the issue has been resolved in a future message or the
      // entity has been closed.
      let dismissMessage = null;
      if (this.getAlertPriority(m) < 0) {
        dismissMessage = this.conflictResolved(m);
      }
      if (dismissMessage) {
        return false;
      } else if (this.getAlertPriority(m) < 0 || m.message.type === 'USER_DIRECT') {
        return true;
      } else {
        return now.diff(m.timeCreated).as('seconds') < 15;
      }
    });
    const removedMsgs = unfilteredMsgs.filter(unfilteredMsg => !this.combinedMessages.find(msg => msg.id === unfilteredMsg.id));
    if (removedMsgs.length > 0) {
      this.markMessagesAsRead(removedMsgs);
    }
  }

  private conflictResolved(message: UserMessageEntry): boolean {
    let resolved = false;
    switch (message.message.type) {
      case 'OPERATION_CONFLICT_ALERT':
        // Search through the messages to see if:
        const opConflictMsg = message.message.data as OperationConflictAlertMessage;
        this.combinedMessages.forEach(comparisonMsg => {
          // A. The conflicting operation has been closed
          if (comparisonMsg.timeCreated.diff(message.timeCreated).toMillis() > 0) {
            if (comparisonMsg.message.type === 'OPERATION_CONFLICT_ALERT') {
              const comparisonMsgData = comparisonMsg.message.data as OperationConflictAlertMessage;
              if (comparisonMsgData.conflictingOperationId === opConflictMsg.conflictingOperationId &&
                comparisonMsgData.conflictingOperationState === state.CLOSED) {
                resolved = true;
              }
            } // B. The operation has been replanned or closed
            else if (comparisonMsg.message.type === 'OPERATOR_INTENT_RESULT') {
              const comparisonMsgData = comparisonMsg.message.data as OperatorIntentResult;
              if ((comparisonMsgData.previous_entity_id === opConflictMsg.operationId &&
                comparisonMsgData.intentType === OperatorIntentType.OPERATION_MODIFY &&
                comparisonMsgData.success) ||
                (comparisonMsgData.entityId === opConflictMsg.operationId && comparisonMsgData.intentType === 'OPERATION_CLOSE')) {
                resolved = true;
              }
            }
          }
        });
        break;
      case 'CONSTRAINT_ALERT':
        // Search through the messages to see if:
        const constraintMsg = message.message.data as ConstraintAlertMessage;
        this.combinedMessages.forEach(comparisonMsg => {
          // A. The conflicting constraint has been closed
          if (comparisonMsg.timeCreated.diff(message.timeCreated).toMillis() > 0) {
            if (comparisonMsg.message.type === 'CONSTRAINT_ALERT') {
              const comparisonMsgData = comparisonMsg.message.data as ConstraintAlertMessage;
              if (comparisonMsgData.constraintId === constraintMsg.constraintId &&
                comparisonMsgData.constraintClosed) {
                resolved = true;
              }
            } // B. The operation has been replanned or closed
            else if (comparisonMsg.message.type === 'OPERATOR_INTENT_RESULT') {
              const comparisonMsgData = comparisonMsg.message.data as OperatorIntentResult;
              if ((comparisonMsgData.previous_entity_id === constraintMsg.operationId &&
                comparisonMsgData.intentType === OperatorIntentType.OPERATION_MODIFY &&
                comparisonMsgData.success) ||
                (comparisonMsgData.entityId === constraintMsg.operationId && comparisonMsgData.intentType === 'OPERATION_CLOSE')) {
                resolved = true;
              }
            }
          }
        });
        break;
      case 'CONFORMANCE_ALERT':
        // Search through the messages to see if:
        const conformanceMsg = message.message.data as ConformanceAlertMessage;
        this.combinedMessages.forEach(comparisonMsg => {
          // A. The operation is now conforming or has been closed (for non-owned operations)
          if (comparisonMsg.timeCreated.diff(message.timeCreated).toMillis() > 0) {
            if (comparisonMsg.message.type === 'CONFORMANCE_ALERT') {
              const comparisonMsgData = comparisonMsg.message.data as ConformanceAlertMessage;
              if (comparisonMsgData.operationId === conformanceMsg.operationId &&
                (comparisonMsgData.conformanceState === ConformanceState.CONFORMING || comparisonMsgData.operationEnded)) {
                resolved = true;
              }
            } // B. The operation has been closed (for owned operations)
            else if (comparisonMsg.message.type === 'OPERATOR_INTENT_RESULT') {
              const comparisonMsgData = comparisonMsg.message.data as OperatorIntentResult;
              if (comparisonMsgData.entityId === conformanceMsg.operationId && comparisonMsgData.intentType === 'OPERATION_CLOSE') {
                resolved = true;
              }
            }
          }
        });
        break;
      case 'OPERATOR_INTENT_RESULT':
        // Search through the messages to see if:
        const opIntentMsg = message.message.data as OperatorIntentResult;
        this.combinedMessages.forEach(comparisonMsg => {
          // A. The intent has been re-attempted or closed
          if (comparisonMsg.timeCreated.diff(message.timeCreated).toMillis() > 0) {
            if (comparisonMsg.message.type === 'OPERATOR_INTENT_RESULT') {
              const comparisonMsgData = comparisonMsg.message.data as OperatorIntentResult;
              if (((comparisonMsgData.previous_intent_id === opIntentMsg.intentId ||
                    comparisonMsgData.previous_entity_id === opIntentMsg.entityId ||
                    comparisonMsgData.entityId === opIntentMsg.entityId) &&
                  comparisonMsgData.intentType === opIntentMsg.intentType) ||
                (comparisonMsgData.entityId === opIntentMsg.entityId && comparisonMsgData.intentType === 'OPERATION_CLOSE'
                  && comparisonMsgData.success)) {
                resolved = true;
              }
            }
          }
        });
        break;

      case 'LAANC_ALERT':
        // Search through the messages to see if:
        const laancAlertMsg = message.message.data as LaancAlertMessage;
        this.combinedMessages.forEach(comparisonMsg => {
          if (comparisonMsg.timeCreated.diff(message.timeCreated).toMillis() > 0) {
            // A. The operator has acknowledged the rescinded LAANC authorization or cancelled the invalidated submission
            if (comparisonMsg.message.type === 'LAANC_ALERT') {
              const comparisonMsgData = comparisonMsg.message.data as LaancAlertMessage;
              if (comparisonMsgData.laancReferenceCode === laancAlertMsg.laancReferenceCode &&
                ((laancAlertMsg.submissionState === LaancSubmissionState.RESCINDED_AWAITING &&
                    comparisonMsgData.submissionState === LaancSubmissionState.RESCINDED_ACKNOWLEDGED) ||
                  (laancAlertMsg.submissionState === LaancSubmissionState.INVALIDATED &&
                    comparisonMsgData.submissionState === LaancSubmissionState.OPERATOR_CANCELLED))) {
                resolved = true;
              }
            } // B. The operation has been closed
            else if (comparisonMsg.message.type === 'OPERATOR_INTENT_RESULT') {
              const comparisonMsgData = comparisonMsg.message.data as OperatorIntentResult;
              if (comparisonMsgData.entityId === laancAlertMsg.operationId &&
                comparisonMsgData.intentType === 'OPERATION_CLOSE') {
                resolved = true;
              }
            }
          }
        })
        break;
    }

    return resolved;
  }

  private getAlertPriority(message: UserMessageEntry): number {
    switch (message.message.type) {
      case 'OPERATOR_INTENT_RESULT':
        const opIntent = message.message.data as OperatorIntentResult;
        return opIntent.success ? 0 : -10;
      case 'OPERATION_CONFLICT_ALERT':
        const conflictAlert = message.message.data as OperationConflictAlertMessage;
        return conflictAlert.conflictingOperationState === state.CLOSED ? 0 : -20;
      case 'CONFORMANCE_ALERT':
        const conformanceAlert = message.message.data as ConformanceAlertMessage;
        return (conformanceAlert.conformanceState === ConformanceState.CONFORMING || conformanceAlert.operationEnded) ?
          0 : -20;
      case 'CONSTRAINT_ALERT':
        const constraintAlert = message.message.data as ConstraintAlertMessage;
        return (!constraintAlert.constraintTypePermitted && !constraintAlert.constraintClosed) ? -20 : 0;
      case 'USER_DIRECT':
        return 0;
      case 'APPROVAL_ALERT':
        const approvalAlert = message.message.data as ApprovalAlertMessage;
        return approvalAlert.approval.approvalStatus === ApprovalStatus.REJECTED ||
          approvalAlert.approval.approvalStatus === ApprovalStatus.EXPIRED ? -10 : 0;
      case "LAANC_ALERT":
        return UserMessageUtil.getAlertType(message) === 'danger' ? -10 : 0;
      // case 'OPERATION_DRAFT_MESSAGE':
      //   return 15;
    }
  }

  private sortMessages() {
    this.combinedMessages.sort((a, b) => {
      const msgAPriority = this.getAlertPriority(a);
      const msgBPriority = this.getAlertPriority(b);
      if (msgAPriority !== msgBPriority) {
        if (msgAPriority < msgBPriority) {
          return -1;
        } else {
          return 1;
        }
      }
      return b.timeCreated.diff(a.timeCreated).as('milliseconds');
    });
  }

  private cacheOperations(msgs: UserMessageEntry[]) {
    msgs.forEach(msg => {
      switch (msg.message.type) {
        case 'OPERATOR_INTENT_RESULT':
          const opIntentResultMessage = this.getOperatorIntentResultMessageData(msg.message);
          if (opIntentResultMessage.intentType.startsWith('OPERATION') && opIntentResultMessage.entityId) {
            this.cacheOperation(opIntentResultMessage.entityId);
          }
          break;
        case 'OPERATION_CONFLICT_ALERT':
          const opConflictMessage = this.getOperationConflictMessageData(msg.message);
          if (opConflictMessage.operationId) {
            this.cacheOperation(opConflictMessage.operationId);
          }
          break;
        case 'CONSTRAINT_ALERT':
          const constraintAlertMessage = this.getConstraintAlertMessageData(msg.message);
          if (constraintAlertMessage.operationId) {
            this.cacheOperation(constraintAlertMessage.operationId);
          }
          break;
        case "LAANC_ALERT":
          const laancAlertMessage = this.getLaancAlertMessageData(msg.message);
          if (laancAlertMessage.operationId) {
            this.cacheOperation(laancAlertMessage.operationId);
          }
          break;
      }
    });
  }

  private cacheOperation(operationId: string): void {
    if (operationId in this.activeOperationCacheSubscriptions) {
      return;
    }
    this.activeOperationCacheSubscriptions[operationId] = this.operationService.getOperation(operationId).subscribe(op => {
      this.operationCache[operationId] = op;
      delete this.activeOperationCacheSubscriptions[operationId];
    });
  }

  private updatePublicMessages() {
    this.messages = [];
    this.errorMessages = [];
    for (const msg of this.combinedMessages){
      if (this.getAlertPriority(msg) >= 0){
        this.messages.push(msg);
      }else{
        this.errorMessages.push(msg);
      }
    }
  }
}
