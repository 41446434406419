import {Component, effect, ElementRef, forwardRef, inject, input, OnDestroy, signal, TemplateRef, viewChild} from '@angular/core';
import {CommonModule, DOCUMENT} from '@angular/common';
import {CesiumToolbarButton} from "../toolbar-extra-button-container/toolbar-extra-button-container.component";
import {CdsIconModule} from "@cds/angular";


@Component({
  selector: 'lib-cesium-toolbar-dropdown',
  standalone: true,
  imports: [CommonModule, CdsIconModule],
  templateUrl: './cesium-toolbar-dropdown.component.html',
  styleUrl: './cesium-toolbar-dropdown.component.scss',
  providers: [{provide: CesiumToolbarButton, useExisting: forwardRef(() => CesiumToolbarDropdownComponent)}]
})
export class CesiumToolbarDropdownComponent extends CesiumToolbarButton implements OnDestroy {
  title = input.required<string>();
  cdsIconName = input.required<string>();

  showContent$ = signal(false);
  private buttonTemplate$ = viewChild<TemplateRef<any>>('buttonTemplate');
  private document = inject(DOCUMENT);
  private container$ = signal<HTMLElement | null>(null);

  get button(): TemplateRef<any> {
    const template = this.buttonTemplate$();
    if(!template) {
      throw new Error('Button template not found');
    }
    return template;
  }

  private closeDropDown = (event: Event) => {
    if (!this.showContent$()) {
      return;
    }
    const container = this.container$();
    if (!container) {
      return;
    }
    let target = event.target as HTMLElement;
    if(container.contains(target)){
      return;
    }
      this.showContent$.update(() => false);
  }

  constructor() {
    super();
    effect(() => {
      this.showContent$();
    });

    this.document?.addEventListener("pointerdown", this.closeDropDown, true);

  }

  ngOnDestroy(): void {
    this.document?.removeEventListener("pointerdown", this.closeDropDown, true);
  }

  toggleShow($event:Event) {
    let target:HTMLElement|null = $event.target as HTMLElement;
    while(target && target.nodeName !== 'BUTTON') {
      target = target.parentElement;
    }
    if(!target) {
      return;
    }

    this.showContent$.update((show) => {
      return !show;
    });

    //Root div containing everything
    this.container$.set(target.parentElement);
  }

}
