import {Component, inject, Input, OnChanges, SimpleChanges} from '@angular/core';
import {UserMessageEntry} from '../../../model/UserMessage/UserMessageEntry';
import {UserMessage} from '../../../model/UserMessage/UserMessage';
import {OperatorIntentResult} from '../../../model/UserMessage/OperatorIntentResult';
import {ConformanceAlertMessage} from '../../../model/UserMessage/ConformanceAlertMessage';
import {ConstraintAlertMessage} from '../../../model/UserMessage/ConstraintAlertMessage';
import {OperationDraftMessage} from '../../../model/UserMessage/OperationDraftMessage';
import {OperationConflictAlertMessage} from '../../../model/UserMessage/OperationConflictAlertMessage';
import {UserDirectMessage} from '../../../model/UserMessage/UserDirectMessage';
import {ConformanceState} from '../../../model/UserMessage/enums';
import {ApprovalStatus} from "../../../model/gen/utm";
import {
  DEFAULT_LAANC_SUBMISSION_STATE_UTIL,
  fail,
  ILaancAcknowledgement,
  LaancOperatorAcknowledgementResponse,
  LaancSubmissionState,
  Result
} from '@ax-uss-ui/common';
import {LaancAlertMessage} from '../../../model/UserMessage/LaancAlertMessage';
import {BehaviorSubject} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {filter, switchMap} from 'rxjs/operators';
import {LaancService} from '../../../services/laanc.service';
import {ApprovalAlertMessage} from "../../../model/UserMessage/ApprovalAlertMessage";
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-user-message-summary-v2',
  templateUrl: './user-message-summary-v2.component.html',
  styleUrls: ['./user-message-summary-v2.component.scss']
})
export class UserMessageSummaryV2Component implements OnChanges {
  @Input() message: UserMessageEntry;

  conformanceState = ConformanceState;
  laancSubmissionState = LaancSubmissionState;
  humanizedLaancSubmissionStates = DEFAULT_LAANC_SUBMISSION_STATE_UTIL.humanized;
  msg: UserMessage;
  opIntentMsg: OperatorIntentResult;
  opConflictMsg: OperationConflictAlertMessage;
  conformanceMsg: ConformanceAlertMessage;
  constraintAlertMsg: ConstraintAlertMessage;
  userDirectMsg: UserDirectMessage;
  approvalMsg: ApprovalAlertMessage;
  laancAlertMsg: LaancAlertMessage;
  opDraftMsg: OperationDraftMessage;
  REJECTED = ApprovalStatus.REJECTED;
  laancAcknowledgementSubject$ = new BehaviorSubject<ILaancAcknowledgement>(null);
  laancAcknowledgementLoading = false;
  laancAcknowledgementResult: Result<LaancOperatorAcknowledgementResponse>;

  private laancService = inject(LaancService);

  // Submit acknowledgements for rescinded authorizations and invalid cancellations
  private acknowledgementsSub = this.laancAcknowledgementSubject$.pipe(
    takeUntilDestroyed(),
    filter(acknowledgement => !!acknowledgement?.referenceCode && !!acknowledgement?.type),
    switchMap(acknowledgement => {
      this.laancAcknowledgementLoading = true;
      this.laancAcknowledgementResult = null;

      if (acknowledgement.type === 'Rescinded') {
        return this.laancService.acknowledgeRescinded(acknowledgement.referenceCode);
      } else {
        return this.laancService.acknowledgeInvalidCancel(acknowledgement.referenceCode);
      }
    })
  ).subscribe({
    next: (res) => {
      this.laancAcknowledgementLoading = false;
      this.laancAcknowledgementResult = res;
    },
    error: (err: HttpErrorResponse) => {
      this.laancAcknowledgementLoading = false;
      this.laancAcknowledgementResult = fail(err?.error?.message || 'Unknown error sending LAANC acknowledgment');
    }
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.message) {
      this.msg = this.message.message;
      this.opIntentMsg = this.msg.data as OperatorIntentResult;
      this.opConflictMsg = this.msg.data as OperationConflictAlertMessage;
      this.conformanceMsg = this.msg.data as ConformanceAlertMessage;
      this.constraintAlertMsg = this.msg.data as ConstraintAlertMessage;
      this.userDirectMsg = this.msg.data as UserDirectMessage;
      this.approvalMsg = this.msg.data as ApprovalAlertMessage;
      this.laancAlertMsg = this.msg.data as LaancAlertMessage;
      this.opDraftMsg = this.msg.data as OperationDraftMessage;
    }
  }
}
