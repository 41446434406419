
interface BaseErrorAble<T> {
  orElse(f: T | ((message: string) => T)): T;
}



export class Some<T> implements BaseErrorAble<T> {
  readonly type = 'some';
  readonly value: T;

  /**
   * Create a new Some instance
   * @param value - The value to wrap
   */
  constructor(value: T) {
    this.value = value;
  }

  /**
   * Return the value if it is present, otherwise return the fallback value
   * This implementation only returns the value as it is always present.
   *
   * @param f - The fallback value or a function that returns the fallback value
   */
  orElse(f: T | ((message: string) => T)): T {
    return this.value;
  }
}

export type ErrorCallback<T> = (message: string) => T;

export class Error<T> implements BaseErrorAble<T> {
  readonly type = 'error';
  readonly message: string;

  /**
   * Create a new Error instance
   * @param message - The error message
   */
  constructor(message: string) {
    this.message = message;
  }

  /**
   * Return the fallback value if the value is not present
   * This implementation always returns the fallback value or the result of the callback function as the value is never present
   * @param f - The fallback value or a function that returns the fallback value
   */
  orElse(f: T | ErrorCallback<T>): T {
    if (f instanceof Function) {
      return f(this.message);
    }
    return f;
  }
}

/**
 * Represents a value that may or may not be present
 */
export type Result<T, E extends Error<T> = Error<T>> = Some<T> | E;


/**
 * Create a new Error instance
 * @param message - The error message
 */
export function fail<T>(message: string): Error<T> {
  return new Error<T>(message);
}

/**
 * Create a new Some instance
 * @param value - The value to wrap
 */
export function some<T>(value: T): Some<T> {
  return new Some(value);
}


/**
 * Check if a value is null or undefined
 * @param value - The value to check
 * @returns true if the value is null or undefined
 * @category Utils
 * @public
 */
export function isNullish(value: unknown): value is null | undefined {
  return value === null || value === undefined;
}


/**
 * Check if a value is empty
 * @param value - The value to check
 * @returns true if the value is null, undefined, an empty string, an empty array, or an empty object
 * @category Utils
 * @public
 */
export function isEmpty(value: unknown): value is null | undefined | '' | [] | {} {
  if (isNullish(value)) {
    return true;
  }
  if(typeof value === 'string') {
    return value === '';
  }
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  if(value instanceof Object) {
    return Object.keys(value).length === 0;
  }
  return false;

}

/**
 * Check if a value is an object
 * @param value - The value to check
 * @returns true if the value is an object
 * @category Utils
 * @public
 */
export function isObject(value: unknown): value is Object {
  return !isNullish(value) && value instanceof Object;
}
