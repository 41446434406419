import {computed, Injectable, signal, TemplateRef} from '@angular/core';
import {uuidv4} from "../../../utils/uuid";


export class InfoboxRef {
  title$ = signal<string>('');
  content$ = signal<TemplateRef<any>|undefined>(undefined);
  constructor(
    title: string,
    content: TemplateRef<any>,
    private handleClose: () => void
  ) {
    this.title$.set(title);
    this.content$.set(content);

  }

  close(): void {
    this.handleClose();
  }

}

@Injectable()
export class InfoboxService {
  private infoboxes = signal<{ [key: string]: InfoboxRef }>({});

  infoboxes$ = computed(() => {
    return Object.values(this.infoboxes());
  });

  public open(title: string, content: TemplateRef<any>, closeCallback?: () => void): InfoboxRef {
    const id = uuidv4();
    const ref = new InfoboxRef(title, content, () => {
      this.infoboxes.update(infoboxes => {
        delete infoboxes[id];
        return {
          ...infoboxes
        };
      });
      closeCallback?.();
    });

    this.infoboxes.update(infoboxes => {
      infoboxes[id] = ref;
      return {
        ...infoboxes
      };
    });
    return ref;
  }


}
