import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ILaancCheckRequest} from '../model/laanc/LaancCheckRequest';
import {LaancCheckResponse} from '../model/laanc/LaancCheckResponse';
import {
  AirspaceDataType,
  DataSyncListResponse,
  LaancDataSetsResponse,
  LaancOperatorAcknowledgementResponse,
  Result
} from '@ax-uss-ui/common';
import {LaancSubmissionEntry, LaancSubmissionsSearchResponse} from '../model/laanc/LaancSubmissionEntry';

@Injectable({
  providedIn: 'root'
})
export abstract class LaancService {

  abstract submitLaancCheck(request: ILaancCheckRequest): Observable<Result<LaancCheckResponse>> ;

  abstract getLaancSubmissions(operationId?: string): Observable<Result<LaancSubmissionsSearchResponse>>;

  abstract getLaancSubmission(referenceCode: string): Observable<Result<LaancSubmissionEntry>>;

  abstract acknowledgeInvalidCancel(referenceCode: string): Observable<Result<LaancOperatorAcknowledgementResponse>>;

  abstract acknowledgeRescinded(referenceCode: string): Observable<Result<LaancOperatorAcknowledgementResponse>>;

  abstract cancelAllSubmissionsForOperation(operationId: string): Observable<Result<LaancOperatorAcknowledgementResponse>>;

  abstract deleteSubmission(referenceCode: string): Observable<Result<LaancOperatorAcknowledgementResponse>>;

  abstract getDataSync(availableOnly?: boolean): Observable<Result<DataSyncListResponse>>;

  abstract updateActiveDataSet(dataType: AirspaceDataType, dataSyncId: string): Observable<boolean>;

  abstract getActiveDataSets(): Observable<Result<DataSyncListResponse>>;

  abstract getAvailableDataSets(): Observable<Result<LaancDataSetsResponse>>;

}
