<h1 cds-text="title">{{modificationModeText$()}} Operation</h1>
<app-progress-bar *ngIf="displayOpSubmissionProgressBar"
                  [percent]="opSubmissionStatusDetails?.percent"
                  [label]="opSubmissionStatusDetails?.summary"
                  [success]="opSubmissionStatusDetails?.success"
></app-progress-bar>

<form clrForm clrLayout="vertical" [formGroup]="formGroup">
  <div class="clr-row create-root-row clr-flex-md-nowrap">
    <!-- Operation details form -->
    <div class="clr-col-md-auto">
<!--  Uncomment this code to enable draft functionality 1/2
      <clr-control-container>
        <label>Load From Drafts</label>
        <app-draft-selector #draftSelectorComponent clrControl formControlName="loadDrafts" name="loadDrafts" [draftId]="draftId"
          (draftDeleted)="handleDeletedDraft($event)" (draftLoaded)="loadDraftOperation($event)"></app-draft-selector>
      </clr-control-container>
      <button class="btn btn-info btn-sm" id="clear-loaded-draft" name="clearLoadedDraft" (click)="clearLoadedDraft()"
        *ngIf="draftId">Clear Loaded Draft</button>-->

      <clr-input-container>
        <label class="required-custom">Operation Name</label>
        <input clrInput formControlName="operationName" autocomplete="off"/>
        <clr-control-helper>{{formGroup.controls.operationName?.value?.length || 0}}/100</clr-control-helper>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s):
          {{formGroup.controls.operationName.errors.invalidCharacters.characters}}</clr-control-error>
        <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.operationName.value.length || 0}}
          /{{formGroup.controls.operationName.errors.maxlength.requiredLength}})</clr-control-error>
      </clr-input-container>

      <clr-textarea-container>
        <label class="required-custom">Operation Description</label>
        <textarea autocomplete="off" clrTextarea placeholder="Enter operational intent details."
                  formControlName="operationDescription"></textarea>
        <clr-control-helper>{{formGroup.controls.operationDescription?.value?.length || 0}}/1000</clr-control-helper>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s):
          {{formGroup.controls.operationDescription.errors.invalidCharacters.characters}}</clr-control-error>
        <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.operationDescription.value.length || 0}}
          /{{formGroup.controls.operationDescription.errors.maxlength.requiredLength}})</clr-control-error>
      </clr-textarea-container>

      <clr-select-container>
        <label class="required-custom">Priority</label>
        <select autocomplete="off" clrSelect formControlName="priority">
          <option *ngFor="let priority of priorityIter" [value]="priority">{{priority}}</option>
        </select>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      </clr-select-container>

      @if (!ussSettings$()?.laanc?.enabled) {
        <clr-select-container class="field-pilot">
          <label class="required-custom">Pilot</label>
          <select autocomplete="off" clrSelect formControlName="pilot">
            <option *ngFor="let contact of availableContacts" [value]="contact.id">{{contact.getName()}}</option>
          </select>
          <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        </clr-select-container>
      }

      <div class="clr-row clr-flex-nowrap clr-align-items-end clr-justify-content-start combobox-wrapper">
        <clr-combobox-container class="clr-col-auto">
          <label class="required-custom">Platform</label>
          <clr-combobox formControlName="platform">
            <clr-options>
              <clr-option *clrOptionItems="let platform of availablePlatformsSubject | async; field: 'prettyName'" [clrValue]="platform">
                {{platform.prettyName}}</clr-option>
            </clr-options>
          </clr-combobox>
          <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        </clr-combobox-container>
        <cds-icon-button *ngIf="formGroup.controls.platform.value"[action]="'flat'" [status]="'neutral'"
                         (click)="clearSelectedPlatform()" class="clr-col-auto btn-clear-field"
                         title="Clear selected platform">
          <cds-icon [shape]="'times'"></cds-icon>
        </cds-icon-button>
      </div>

      <clr-select-container>
        <label class="required-custom">Operation Type</label>
        <select autocomplete="off" clrSelect formControlName="permittedConstraintType">
          <option *ngFor="let constraintType of availableConstraintTypes"
                  [value]="constraintType.value">{{constraintType.name}}</option>
        </select>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      </clr-select-container>

      <clr-select-container>
        <label class="required-custom">Vertical Reference</label>
        <select clrSelect formControlName="altitude_vertical_reference">
          <option *ngFor="let option of verticalReferenceIter" [value]="option.value">{{option.label}}
          </option>
        </select>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      </clr-select-container>

      <clr-select-container>
        <label class="required-custom">Units of Measure</label>
        <select clrSelect formControlName="altitude_units">
          <option *ngFor="let option of unitsOfMeasureIter" [value]="option.value">{{option.label}}
          </option>
        </select>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      </clr-select-container>

      <clr-select-container>
        <label>Activation</label>
        <select autocomplete="off" clrSelect formControlName="activation">
          <option *ngFor="let option of activationOptions" [value]="option.value">{{option.name}}</option>
        </select>
      </clr-select-container>

      @if (ussSettings$()?.laanc?.enabled) {
        <clr-select-container>
          <label class="required-custom">LAANC Category</label>
          <select autocomplete="off" clrSelect formControlName="laancCategory">
            <option [value]="laancSubmissionCategoryEnum.SECTION_44809">Section 44809 (Recreational)</option>
            <option [value]="laancSubmissionCategoryEnum.PART_107" [disabled]="!laancContact?.hasPart107">Part 107</option>
          </select>
          <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        </clr-select-container>
      }

<!--  Uncomment this code to enable the flight type control
      <clr-radio-container>
        <label>Flight Type</label>
        <clr-radio-wrapper>
          <input autocomplete="off" type="radio" clrRadio name="visibility" required value="bvlos" formControlName="visibility"/>
          <label>Beyond Visual Line of Sight</label>
        </clr-radio-wrapper>
        <clr-radio-wrapper>
          <input autocomplete="off" type="radio" clrRadio name="visibility" required value="vlos" formControlName="visibility"/>
          <label>Visual Line of Sight</label>
        </clr-radio-wrapper>
        <clr-control-helper>This field indicates whether the platform will be operated within visual line of sight.
        </clr-control-helper>
        <clr-control-error>This field is required!</clr-control-error>
      </clr-radio-container>-->
      <ng-template #publicAccessTemplate>
        <clr-checkbox-container>
          <clr-checkbox-wrapper>
            <label>Public Access</label>
            <input autocomplete="off" type="checkbox" clrCheckbox formControlName="public_access"/>
          </clr-checkbox-wrapper>
          <clr-control-helper>If checked, this operation will be shared with the DSS.</clr-control-helper>
        </clr-checkbox-container>
      </ng-template>

      <ng-container *ngIf="!this.ussSettings$()?.experimentalSettings?.enableFederationSupport" [ngTemplateOutlet]="publicAccessTemplate"></ng-container>
      <ng-container *ngIf="this.ussSettings$()?.experimentalSettings?.enableFederationSupport">
        <cds-accordion>
          <cds-accordion-panel [expanded]="federationPolicyAccordionExpanded">
            <cds-accordion-header (click)="federationPolicyAccordionExpanded = !federationPolicyAccordionExpanded">
              Federation Policy
            </cds-accordion-header>
            <cds-accordion-content>
              <ng-container [ngTemplateOutlet]="publicAccessTemplate"></ng-container>
  <!--      Uncomment the code below to enable the federation policy controls 2/2 -->
              <h4>Cross Domain Federation</h4>
              <edit-federation-policy [domains]="this.ussSettings$()?.knownFederationDomains || []"
                                      formControlName="federationPolicy"></edit-federation-policy>
            </cds-accordion-content>
          </cds-accordion-panel>
        </cds-accordion>
      </ng-container>

      <!-- Submission buttons -->
      @if (deviceSize$() !== 'sm') {
        <ng-container *ngTemplateOutlet="submitBtn"></ng-container>
      }
    </div>

    <!-- Geometry editor -->
    <div  class="clr-col-md-auto clr-flex-md-grow-1">
      <div class="flex-auto-position-fix-outer">
        <div class="flex-auto-position-fix-inner">
          <app-operation-geometry-editor formControlName="operationGeometry"
                                         [selectedVertRef]="formGroup.controls.altitude_vertical_reference.value"
                                         [selectedUnits]="formGroup.controls.altitude_units.value"
                                         [opModificationType]="submissionMode$()"
                                         [colorConfig]="colorConfig"
                                         [userSettings]="userSettings"
                                         [sourceOperation]="operation"
                                         (altRefChange)="this.updateAltRef($event)"
                                         (altUnitsChange)="this.updateAltUnits($event)">
          </app-operation-geometry-editor>
        </div>
      </div>
      <!-- Submission buttons -->
      @if (deviceSize$() === 'sm') {
        <ng-container *ngTemplateOutlet="submitBtn"></ng-container>
      }
    </div>
  </div>
</form>

<ng-template #submitBtn>
  <div class="btn-group btn-submit">
    @if (ussSettings$()?.laanc?.enabled) {
      <!-- Submit operations for LAANC check -->
      <button class="btn btn-primary" (click)="submitLaancCheck()" [disabled]="formGroup.invalid">
        Check LAANC Requirements</button>
    } @else {
      @if (submissionMode$() === mode.rerouteAccepted || submissionMode$() === mode.rerouteActive) {
        <!-- Submit modified operations -->
        <button class="btn btn-primary" (click)="modifyOperation()" [disabled]="formGroup.invalid">
          <!--|| timeRangeFormGroup.invalid">-->
          Submit Replan</button>
      } @else {
        <!-- Submit new & replanned operations -->
        <button class="btn btn-primary" (click)="submitOperation()" [disabled]="formGroup.invalid">
          Submit {{submissionMode$() === mode.replan ? 'Replan' : 'Operation'}}</button>
      }
    }

    <!--        Uncomment this code to enable draft functionality 2/2
    <button class="btn btn-info" name="saveDraftOperation" (click)="saveDraftOperation(true)"
            *ngIf="!operationId" [disabled]="!formGroup.controls.operationName.value">
            {{this.draftId ? "Update" : "Save"}} Draft</button>
    <div [ngClass]="overflowClass">
      <button class="btn dropdown-toggle" (click)="toggleOverflowState()" [disabled]="!formGroup.controls.operationName.value">
        <cds-icon shape="ellipsis-horizontal"></cds-icon>
      </button>
      <div class="dropdown-menu">
        <button class="btn btn-info" name="saveDraftOpNew" (click)="saveDraftOperation(false)"
                *ngIf="!operationId" [disabled]="!formGroup.controls.operationName.value">
                {{this.draftId ? "Update" : "Save"}} Draft & Start New</button>
        <button class="btn btn-info" name="saveDraftOpContinue" (click)="saveDraftOperation(true)"
                *ngIf="!operationId" [disabled]="!formGroup.controls.operationName.value">
                {{this.draftId ? "Update" : "Save"}} Draft & Continue (default)</button>
      </div>
    </div>-->
  </div>
</ng-template>

<clr-modal *ngIf="displayOpSubmissionProgressModal" [(clrModalOpen)]="displayOpSubmissionProgressModal" [clrModalStaticBackdrop]="false">
  <h3 class="modal-title">{{this.opSubmissionStatusDetails?.heading}}</h3>
  <div class="modal-body">
    <app-progress-bar [percent]="opSubmissionStatusDetails?.percent"
                      [label]="opSubmissionStatusDetails?.summary"
                      [success]="opSubmissionStatusDetails?.success"
                      [displayDetails]="this.opSubmissionStatusDetails?.intent?.intentResult?.success === false"
                      [details]="opSubmissionStatusDetails?.intent?.intentResult?.failureReason"
                      [displayButton]="opSubmissionStatusDetails?.intent?.intentResult?.failureReason === 'CONFLICT'"
                      [buttonText]="'Replan Operation'"
                      (actionBtnClick)="replanOperation()">
    </app-progress-bar>
    <p *ngIf="opSubmissionStatusDetails?.success && this.opSubmissionStatusDetails?.intent?.intentResult?.resultEntityId" class="op-id-wrapper">
      Operation ID: {{this.opSubmissionStatusDetails.intent.intentResult.resultEntityId}}
      <app-copy-to-clipboard [text]="this.opSubmissionStatusDetails.intent.intentResult.resultEntityId"></app-copy-to-clipboard>
    </p>
  </div>
</clr-modal>

<clr-side-panel *ngIf="displayLaancCheckPanel"
                [(clrSidePanelOpen)]="displayLaancCheckPanel"
                [clrSidePanelSize]="'xl'">
  <h3 class="side-panel-title">LAANC Check Results</h3>
  <div class="side-panel-body">
    @if (!this.laancCheckResponse) {
      <clr-spinner clrInline>Checking LAANC Requirements</clr-spinner>
      <span>Checking LAANC requirements</span>
    } @else {
      @if (this.laancCheckResponse.value) {
        @if (displayLaancSubmissionProgressBar) {
          <h3 cds-text="subsection">{{opSubmissionStatusDetails?.heading}}</h3>
          <app-progress-bar [percent]="opSubmissionStatusDetails?.percent"
                            [label]="opSubmissionStatusDetails?.summary"
                            [success]="opSubmissionStatusDetails?.success"
          ></app-progress-bar>
          @if ((opSubmissionStatusDetails?.intent?.intentStatus === intentStatus.FINISHED ||
                opSubmissionStatusDetails?.intent?.intentStatus === intentStatus.LAANC_PENDING_APPROVAL) &&
                laancCheckResponse.value.requirement !== laancRequirementEnum.NOT_APPLICABLE) {
            <button class="btn btn-link laanc-submissions-link" [routerLink]="['/', 'laanc', 'submissions']"
                    [routerLinkActive]="'active'">View All LAANC Submissions</button>
          }
          @if (opSubmissionStatusDetails?.intent?.intentStatus === intentStatus.LAANC_PENDING_APPROVAL &&
            laancCheckResponse.value.requirement === laancRequirement.REQUIRE_FURTHER_COORDINATION) {
            <clr-alert [clrAlertType]="'info'" [clrAlertClosable]="false">
              <clr-alert-item>
              <span class="alert-text">This further coordination request is pending. Please do not follow-up with Air
                Traffic by phone as it may result in the request being denied by the FAA.<br/><br/>
                Further coordination is a manual process with commensurate timelines. The FAA’s goal is to respond to
                Further coordination requests in less than 30 days, but it may take up to 90 days to either approve or
                deny and, in some cases, requests may expire without resolution.</span>
              </clr-alert-item>
            </clr-alert>
          }
        } @else {
          <laanc-check-response [laancResponse$]="laancCheckResponse.value"
                                [laancCategory$]="laancCategory"
                                (submitOperation$)="submitOperation()">
          </laanc-check-response>
        }
      } @else {
        {{this.laancCheckResponse.message}}
      }
    }
  </div>
</clr-side-panel>
