import {OperationExt, SwaggerOperationExt} from '../OperationExt';
import {
  Altitude,
  ApprovalStatus,
  cause,
  contingency_cause,
  contingency_location_description,
  contingency_response,
  ContingencyPlan,
  EntityVolume4d,
  EventMetadata,
  faa_rule,
  GeoCircle,
  IAltitude,
  IRadius,
  negotiation_agreement,
  NegotiationAgreement,
  OperationActivationPolicy,
  OperationVolume,
  PendingReason,
  permitted_uas,
  PersonOrOrganization,
  Point,
  point_type,
  Polygon,
  polygon_type,
  priority_status,
  PriorityElements,
  required_support,
  setting,
  severity,
  source,
  state,
  TelemetryStatusEnum,
  test_type,
  UasRegistration,
  UASVolumeReservation,
  uasvolumereservation_type,
  units_of_measure,
  vertical_reference,
  volume_type
} from '../../gen/utm';
import {DateTime} from 'luxon';
import {
  IAltitude as TransportIAltitude,
  IEntityVolume4d as TransportIEntityVolume4d,
  IEventMetadata as TransportEventMetadata,
  INegotiationAgreement as TransportNegotiationAgreement,
  IOperation,
  IOperation as TransportOperation,
  IOperationVolume as TransportOperationVolume,
  IPersonOrOrganization as TransportPersonOrOrganization,
  IPoint as TransportPoint,
  IPolygon as TransportPolygon,
  IPriorityElements as TransportPriorityElements,
  IUasRegistration as TransportUasRegistration,
  IUASVolumeReservation as TransportUASVolumeReservation,
  TransportUnitsOfMeasure
} from '../../gen/transport/utm';
import {AdditionalOperationData, SwaggerAdditionalOperationData} from '../AdditionalOperationData';
import {Operation} from '../Operation';
import {TransportUasRegistrationInfo, UasRegistrationInfo} from '../../UasRegistrationInfo';
import {IOwnerContact, OwnerContact} from '../../OwnerContact';
import {AircraftSpecs, TransportAircraftSpecs} from '../../AircraftSpecs';
import {AdditionalRegistration, IAdditionalRegistration} from '../../AdditionalRegistration';
import {IUasStatistics, UasStatistics} from '../../UasStatistics';
import {
  TransportAffectedOperation,
  TransportApprovalAlertMessage,
  TransportConformanceAlertMessage,
  TransportConstraintAlertMessage,
  TransportFromUser,
  TransportOperationConflictAlertMessage,
  TransportOperatorIntentResult,
  TransportUserDirectMessage,
  TransportUserMessage,
  TransportUserMessageDataType,
  TransportUserMessageEntry
} from '../../UserMessage/TransportUserMessage';
import {UserMessageEntry} from '../../UserMessage/UserMessageEntry';
import {IUserMessage, IUserMessageDataType} from '../../UserMessage/UserMessage';
import {
  ConformanceState,
  OperatorIntentFailureReason,
  OperatorIntentType,
  TransportConformanceState,
  TransportOperatorIntentFailureReasonEnum,
  TransportOperatorIntentTypeEnum,
  TransportVolume4dViolation,
  Volume4dViolation
} from '../../UserMessage/enums';
import {ConstraintState, SwaggerUvrExt, UvrExt} from '../UvrExt';
import {AdditionalConstraintData, SwaggerAdditionalConstraintData} from '../AdditionalConstraintData';
import {Contact, IContact} from '../../Contact';
import {
  IOperatorIntentEntry,
  IOperatorIntentResultResponse,
  OperatorIntentStatus,
  Result,
  TransportOperatorIntentEntry,
  TransportOperatorIntentResultResponse,
  TransportOperatorIntentStatus,
  TransportResult
} from '../../OperatorIntentEntry';
import {OperationResponse} from '../../../services/rest/rest-operation.service';
import {
  OperationReplanEntry,
  OperationReplanInfo,
  TransportOperationReplanEntry,
  TransportOperationReplanInfo
} from '../../OperationReplanInfo';
import {OperationConflictAlertMessage} from '../../UserMessage/OperationConflictAlertMessage';
import {OperatorIntentResult} from '../../UserMessage/OperatorIntentResult';
import {ConformanceAlertMessage, IAffectedOperation} from '../../UserMessage/ConformanceAlertMessage';
import {ConstraintAlertMessage} from '../../UserMessage/ConstraintAlertMessage';
import {OperationScope} from '../../../services/operation.service';
import {
  IDirectMessageSubmissionResponse,
  ITransportDirectMessageSubmissionResponse
} from '../../DirectMessageSubmission';
import {IFromUser, UserDirectMessage} from '../../UserMessage/UserDirectMessage';
import {ConstraintResponse} from '../../../services/rest/rest-constraint.service';
import {ApprovalAlertMessage} from '../../UserMessage/ApprovalAlertMessage';
import {TransportApprovalInfo} from '../../gen/transport/utm/model/approvalInfo';
import {ApprovalInfo} from '../../gen/utm/approval-info';
import {TransportOperationApprovalInfo} from '../../gen/transport/utm/model/operationApprovalInfo';
import {OperationApprovalInfo} from '../../gen/utm/operation-approval-info';
import {TransportGeoCircle, TransportRadius} from "../../gen/transport/utm/model/circle";
import {TransportTelemetryStatus} from '../../gen/transport/utm/model/telemetryStatus';
import {ITelemetryStatus} from "../../telemetry-status";
import {
  TelemetryIntegrationsEntry,
  TransportTelemetryIntegrationsEntry
} from '../../TelemetryIntegrations/TelemetryIntegrationsEntry';
import {TelemetryIntegrations, TransportTelemetryIntegrations} from '../../TelemetryIntegrations/TelemetryIntegrations';
import {
  CotTelemetryIntegration,
  TransportCotTelemetryIntegration
} from '../../TelemetryIntegrations/CotTelemetryIntegration';
import {
  MavlinkTelemetryIntegration,
  Protocol,
  TransportMavlinkTelemetryIntegration
} from '../../TelemetryIntegrations/MavlinkTelemetryIntegration';
import {
  RigitechTelemetryIntegration,
  TransportRigitechTelemetryIntegration
} from '../../TelemetryIntegrations/RigitechTelemetryIntegration';
import {
  SupportedTelemetryIntegrationsResponse,
  TelemetryIntegrationType,
  TransportSupportedTelemetryIntegrationsResponse
} from '../../TelemetryIntegrations/SupportedTelemetryIntegrationsResponse';
import {DEFAULT_OPERATION_LAANC_INFO_UTIL, isEmpty, OperationLaancInfo} from '@ax-uss-ui/common';
import {DEFAULT_LAANC_ALERT_MESSAGE_UTIL} from '../../UserMessage/LaancAlertMessage';
import TransportAltitudeSourceEnum = TransportIAltitude.SourceEnum;
import StateEnum = IOperation.StateEnum;
import PendingReasonEnum = IOperation.PendingReasonEnum;
import ApprovalStatusEnum = TransportApprovalInfo.ApprovalStatusEnum;

export class Parser {

  static parseDateTime(input: string): DateTime | null {
    const tmp = DateTime.fromISO(input);
    if (tmp.isValid) {
      return tmp;
    }
    return null;
  }

  static parseUasRegistration(raw: TransportUasRegistration): UasRegistration {
    return new UasRegistration(raw);
  }

  static parsePersonOrOrganization(raw: TransportPersonOrOrganization): PersonOrOrganization {
    return new PersonOrOrganization(raw);
  }

  static parsePoint(raw: TransportPoint): Point {
    raw = raw || {};
    return new Point({
      type: raw.type ? point_type[raw.type] : undefined,
      coordinates: raw.coordinates
    });
  }

  static parsePolygon(raw: TransportPolygon): Polygon {
    return new Polygon({
      type: raw.type ? polygon_type[raw.type] : undefined,
      coordinates: raw.coordinates
    });
  }

  static parseCircle(raw: TransportGeoCircle): GeoCircle {
    return new GeoCircle({
      latitude: raw.latitude,
      longitude: raw.longitude,
      radius: raw.radius,
      units: Parser.ParseLengthUnits(raw.units_of_measure)
    });
  }

  static parseRadius(raw: TransportRadius): IRadius {
    return {
      radius: raw.radius,
      units_of_measure: Parser.ParseLengthUnits(raw.units_of_measure)
    }
  }

  static parseAltitude(altitude: TransportIAltitude): IAltitude {
    return new Altitude({
      /* eslint-disable @typescript-eslint/naming-convention */
      altitude_value: altitude.altitude_value,
      vertical_reference: vertical_reference[altitude.vertical_reference],
      units_of_measure: Parser.ParseLengthUnits(altitude.units_of_measure),
      source: altitude.source ? Parser.parseAltitudeSourceEnum(altitude.source) : null
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  static parseContingencyPlan(raw: any): ContingencyPlan {
    return new ContingencyPlan({

      /* eslint-disable @typescript-eslint/naming-convention */
      contingency_id: raw.cid,
      name: raw.name,
      contingency_cause: raw.contingency_cause.map((c) => contingency_cause[c]),
      contingency_response: contingency_response[raw.contingency_response as string],
      contingency_polygon: raw.contingency_polygon ? Parser.parsePolygon(raw.contingency_polygon) : null,
      loiter_altitude: raw.loiter_altitude ? Parser.parseAltitude(raw.loiter_altitude) : undefined,
      relative_preference: raw.relative_preference,
      contingency_location_description: raw.contingency_location_description ?
        contingency_location_description[raw.contingency_location_description as string] : undefined,
      relevant_operation_volumes: raw.relevant_operation_volumes,
      valid_time_begin: Parser.parseDateTime(raw.valid_time_begin),
      valid_time_end: Parser.parseDateTime(raw.valid_time_end),
      free_text: raw.free_text
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  static parseNegotiationAgreements(raw: TransportNegotiationAgreement): NegotiationAgreement {
    return new NegotiationAgreement({
      /* eslint-disable @typescript-eslint/naming-convention */
      message_id: raw.message_id,
      negotiation_id: raw.negotiation_id,
      uss_name: raw.uss_name,
      uss_name_of_originator: raw.uss_name_of_originator,
      uss_name_of_receiver: raw.uss_name_of_receiver,
      gufi_originator: raw.gufi_originator,
      gufi_receiver: raw.gufi_receiver,
      free_text: raw.free_text,
      discovery_reference: raw.discovery_reference,
      type: negotiation_agreement[raw.type]
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }


  static parsePriorityElements(raw: TransportPriorityElements): PriorityElements {
    return new PriorityElements({
      /* eslint-disable @typescript-eslint/naming-convention */
      priority_level: severity[raw.priority_level],
      priority_status: priority_status[raw.priority_status]
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  static parseOperationVolume(raw: TransportOperationVolume): OperationVolume {
    return new OperationVolume({
      /* eslint-disable @typescript-eslint/naming-convention */
      volume_type: volume_type[raw.volume_type],
      near_structure: raw.near_structure,
      effective_time_begin: Parser.parseDateTime(raw.effective_time_begin),
      effective_time_end: Parser.parseDateTime(raw.effective_time_end),
      min_altitude: Parser.parseAltitude(raw.min_altitude),
      max_altitude: Parser.parseAltitude(raw.max_altitude),
      submitted_min_altitude: raw.submitted_min_altitude ? Parser.parseAltitude(raw.submitted_min_altitude) : undefined,
      submitted_max_altitude: raw.submitted_max_altitude ? Parser.parseAltitude(raw.submitted_max_altitude) : undefined,
      geography: Parser.parsePolygon(raw.geography),
      circle: raw.circle ? Parser.parseCircle(raw.circle) : undefined,
      submitted_radius: raw.submitted_radius ? Parser.parseRadius(raw.submitted_radius) : undefined,
      beyond_visual_line_of_sight: raw.beyond_visual_line_of_sight,
      off_nominal: raw.off_nominal
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  static parseConstraintVolume(raw: TransportIEntityVolume4d): EntityVolume4d {
    return new EntityVolume4d({
      /* eslint-disable @typescript-eslint/naming-convention */
      effective_time_begin: Parser.parseDateTime(raw.effective_time_begin),
      effective_time_end: Parser.parseDateTime(raw.effective_time_end),
      min_altitude: Parser.parseAltitude(raw.min_altitude),
      max_altitude: Parser.parseAltitude(raw.max_altitude),
      submitted_min_altitude: raw.submitted_min_altitude ? Parser.parseAltitude(raw.submitted_min_altitude) : undefined,
      submitted_max_altitude: raw.submitted_max_altitude ? Parser.parseAltitude(raw.submitted_max_altitude) : undefined,
      geography: Parser.parsePolygon(raw.geography),
      circle: raw.circle ? Parser.parseCircle(raw.circle) : undefined,
      submitted_radius: raw.submitted_radius ? Parser.parseRadius(raw.submitted_radius) : undefined
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  static parseEventMetadata(raw: TransportEventMetadata): EventMetadata {
    return new EventMetadata({
      /* eslint-disable @typescript-eslint/naming-convention */
      data_collection: raw.data_collection,
      event_id: raw.event_id,
      scenario: raw.scenario,
      test_card: raw.test_card,
      test_run: raw.test_run,
      call_sign: raw.call_sign,
      test_type: raw.test_type ? test_type[raw.test_type] : undefined,
      source: raw.source ? source[raw.source] : undefined,
      location: raw.location,
      setting: raw.setting ? setting[raw.setting] : undefined,
      free_text: raw.free_text,
      data_quality_engineer: raw.data_quality_engineer,
      modified: raw.modified
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  static parseAdditionalOperationData(raw: SwaggerAdditionalOperationData): AdditionalOperationData {
    return new AdditionalOperationData({
      /* eslint-disable @typescript-eslint/naming-convention */
      airspace: raw.airspace,
      category: raw.category,
      division: raw.division,
      operation_type: permitted_uas[raw.operation_type],
      organization: raw.organization,
      public_access: raw.public_access,
      security_tags: raw.security_tags,
      user: raw.user,
      user_id: raw.user_id,
      permitted_constraint_types: raw.permitted_constraint_types || []
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  public static parseOperation(raw: TransportOperation): Operation {
    return new Operation({
      /* eslint-disable @typescript-eslint/naming-convention */
      operationId: raw.gufi,
      uss_name: raw.uss_name,
      discovery_reference: raw.discovery_reference,
      submit_time: Parser.parseDateTime(raw.submit_time),
      update_time: Parser.parseDateTime(raw.update_time),
      aircraft_comments: raw.aircraft_comments,
      flight_comments: raw.flight_comments,
      volumes_description: raw.volumes_description,
      uas_registrations: (raw.uas_registrations || []).map(Parser.parseUasRegistration),
      airspace_authorization: raw.airspace_authorization,
      flight_number: raw.flight_number,
      contact: Parser.parsePersonOrOrganization(raw.contact),
      state: state[raw.state],
      controller_location: Parser.parsePoint(raw.controller_location),
      takeoff_location: Parser.parsePoint(raw.takeoff_location),
      gcs_location: raw.gcs_location ? Parser.parsePoint(raw.gcs_location) : undefined,
      contingency_plans: raw.contingency_plans ? raw.contingency_plans.map(Parser.parseContingencyPlan) : undefined,
      negotiation_agreements: raw.negotiation_agreements ? raw.negotiation_agreements.map(Parser.parseNegotiationAgreements) : undefined,
      faa_rule: raw.faa_rule ? faa_rule[raw.faa_rule] : undefined,
      priority_elements: raw.priority_elements ? Parser.parsePriorityElements(raw.priority_elements) : undefined,
      operation_volumes: raw.volumes.map(Parser.parseOperationVolume),
      metadata: Parser.parseEventMetadata(raw.metadata),
      contact_id: raw.contact_id,
      priority: raw.priority || undefined
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  public static parseOperationResponse(raw: OperationResponse): OperationResponse {
    return {
      intentId: raw.intentId,
      operationId: raw.operationId,
      message: raw.message
    };
  }

  public static parseOperationExt(raw: SwaggerOperationExt): OperationExt {
    // TODO: Move this into the OperationExt deserializer when it is refactored to use the lib BaseModel
    let laanc: OperationLaancInfo | undefined = undefined
    if (('laanc' in raw) && !isEmpty(raw.laanc)) {
      const laancResult = DEFAULT_OPERATION_LAANC_INFO_UTIL.deserialize(raw.laanc);
      if (laancResult.type === 'error') {
        console.error(`Failed to parse operation LAANC info: ${laancResult.message}`);
      } else {
        laanc = laancResult.value;
      }
    }

    return new OperationExt({
      /* eslint-disable @typescript-eslint/naming-convention */
      operationId: raw.gufi,
      operator_intent_id: raw.operator_intent_id,
      uss_name: raw.uss_name,
      discovery_reference: raw.discovery_reference,
      submit_time: Parser.parseDateTime(raw.submit_time),
      update_time: Parser.parseDateTime(raw.update_time),
      effective_time_begin: Parser.parseDateTime(raw.effective_time_begin),
      effective_time_end: Parser.parseDateTime(raw.effective_time_end),
      version: raw.version,
      aircraft_comments: raw.aircraft_comments,
      flight_comments: raw.flight_comments,
      volumes_description: raw.volumes_description,
      uas_registrations: (raw.uas_registrations || []).map(Parser.parseUasRegistration),
      airspace_authorization: raw.airspace_authorization,
      flight_number: raw.flight_number,
      contact: Parser.parsePersonOrOrganization(raw.contact),
      state: Parser.parseState(raw.state),
      controller_location: raw.controller_location ? Parser.parsePoint(raw.controller_location) : undefined,
      takeoff_location: raw.takeoff_location ? Parser.parsePoint(raw.takeoff_location) : undefined,
      gcs_location: raw.gcs_location ? Parser.parsePoint(raw.gcs_location) : undefined,
      contingency_plans: raw.contingency_plans ? raw.contingency_plans.map(Parser.parseContingencyPlan) : undefined,
      negotiation_agreements: raw.negotiation_agreements ? raw.negotiation_agreements.map(Parser.parseNegotiationAgreements) : undefined,
      faa_rule: raw.faa_rule ? faa_rule[raw.faa_rule] : undefined,
      priority_elements: raw.priority_elements ? Parser.parsePriorityElements(raw.priority_elements) : undefined,
      operation_volumes: raw.volumes.map(Parser.parseOperationVolume),
      metadata: raw.metadata ? Parser.parseEventMetadata(raw.metadata) : new EventMetadata(),
      additional_data: raw.additional_data ? Parser.parseAdditionalOperationData(raw.additional_data) : undefined,
      contact_id: raw.contact_id || undefined,
      priority: raw.priority,
      managed: raw.managed,
      replan_required: raw.replan_required,
      pendingReason: raw.pendingReason ? Parser.parsePendingReason(raw.pendingReason) : undefined,
      approval: raw.approval ? Parser.parseOperationApprovalInfo(raw.approval) : undefined,
      activation: raw.activation ? Parser.parseOperationActivationPolicy(raw.activation) : undefined,
      laanc
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  static parseUASVolumeReservation(raw: TransportUASVolumeReservation): UASVolumeReservation {
    return new UASVolumeReservation({
      /* eslint-disable @typescript-eslint/naming-convention */
      message_id: raw.message_id,
      uss_name: raw.uss_name,
      type: uasvolumereservation_type[raw.type],
      permitted_uas: raw.permitted_uas.map(puas => permitted_uas[puas]),
      required_support: (raw.required_support || []).map(rs => required_support[rs]),
      permitted_gufis: raw.permitted_gufis,
      cause: cause[raw.cause],
      // geography: Parser.parsePolygon(raw.geography),
      volumes: raw.volumes.map(Parser.parseConstraintVolume),
      effective_time_begin: Parser.parseDateTime(raw.effective_time_begin),
      effective_time_end: Parser.parseDateTime(raw.effective_time_end),
      actual_time_end: raw.actual_time_end ? Parser.parseDateTime(raw.actual_time_end) : undefined,
      // min_altitude: Parser.parseAltitude(raw.min_altitude),
      // max_altitude: Parser.parseAltitude(raw.max_altitude),
      reason: raw.reason
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  static parseUasRegistrationInfo(raw: TransportUasRegistrationInfo): UasRegistrationInfo {
    return new UasRegistrationInfo({
      uvin: raw.uvin,
      archived: raw.archived === undefined ? false : raw.archived,
      name: raw.name || '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      registration_location: raw.registration_location,
      userId: raw.userId,
      organization: raw.organization,
      securityTags: raw.securityTags || [],
      tailNumber: raw.tailNumber,
      faaRegistrationNumber: raw.faaRegistrationNumber,
      ownerContact: raw.ownerContact ? Parser.parseContact(raw.ownerContact) : undefined,
      ownerIsOrganization: raw.ownerIsOrganization,
      aircraftSpecs: raw.aircraftSpecs ? Parser.parseAircraftSpecs(raw.aircraftSpecs) : undefined,
      additionalRegistrations: (raw.additionalRegistrations || []).map(Parser.parseAdditionalRegistration),
      statistics: raw.statistics ? Parser.parseUasStatistics(raw.statistics) : undefined,
      integrations: raw.integrations ? Parser.parseTelemetryIntegrations(raw.integrations) : undefined
    });
  }

  static parseUserMessageEntry(raw: TransportUserMessageEntry): UserMessageEntry {
    return new UserMessageEntry({
      id: raw.id,
      timeCreated: Parser.parseDateTime(raw.timeCreated),
      userId: raw.userId,
      priority: raw.priority,
      read: raw.read,
      message: Parser.parseUserMessage(raw.message)
    });
  }

  static parseUserDirectMessageResponse(raw: ITransportDirectMessageSubmissionResponse): IDirectMessageSubmissionResponse {
    return {
      httpStatusCode: raw.http_status_code,
      message: raw.message,
    };
  }

  public static parseConstraintResponse(raw: ConstraintResponse): ConstraintResponse {
    return {
      intentId: raw.intentId,
      constraintId: raw.constraintId,
      message: raw.message
    };
  }

  static parseUASVolumeReservationExt(raw: SwaggerUvrExt): UvrExt {
    return new UvrExt({
      /* eslint-disable @typescript-eslint/naming-convention */
      managed: raw.managed,
      message_id: raw.message_id,
      uss_name: raw.uss_name,
      type: uasvolumereservation_type[raw.type],
      permitted_uas: (raw.permitted_uas || ['NOT_SET']).map(puas => permitted_uas[puas]),
      required_support: (raw.required_support || []).map(rs => required_support[rs]),
      permitted_gufis: raw.permitted_gufis,
      cause: cause[raw.cause],
      volumes: raw.volumes.map(Parser.parseConstraintVolume),
      effective_time_begin: Parser.parseDateTime(raw.effective_time_begin),
      effective_time_end: Parser.parseDateTime(raw.effective_time_end),
      actual_time_end: raw.actual_time_end ? Parser.parseDateTime(raw.actual_time_end) : undefined,
      update_time: raw.update_time ? Parser.parseDateTime(raw.update_time) : undefined,
      reason: raw.reason,
      additional_data: raw.additional_data ? Parser.parseAdditionalConstraintData(raw.additional_data) : undefined
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  static parseContact(raw: IContact): Contact {
    return new Contact(raw);
  }

  static parseState(s: StateEnum): state {
    switch (s) {
      case 'PROPOSED':
        return state.PROPOSED;
      case 'ACCEPTED':
        return state.ACCEPTED;
      case 'ACTIVATED':
        return state.ACTIVATED;
      case 'CLOSED':
        return state.CLOSED;
      case 'NONCONFORMING':
        return state.NONCONFORMING;
      case 'ROGUE':
        return state.ROGUE;
      case 'REJECTED':
        return state.REJECTED;
      default:
        console.error(`Invalid state: ${s}`);
        return;
    }
  }

  static parseScope(s: string): OperationScope {
    switch (s) {
      case 'personal':
        return OperationScope.personal;
      case 'organization':
        return OperationScope.organization;
      case 'currentUSS':
        return OperationScope.currentUSS;
      case 'global':
        return OperationScope.global;
      default:
        console.error(`Invalid scope: ${s}`);
        return;
    }
  }

  static parsePendingReason(reason: PendingReasonEnum): PendingReason {
    switch (reason) {
      case 'APPROVAL_REQUIRED':
        return PendingReason.APPROVAL_REQUIRED;
      case 'NOT_PROCESSED':
        return PendingReason.NOT_PROCESSED;
      case 'LAANC_APPROVAL_REQUIRED':
        return PendingReason.LAANC_APPROVAL_REQUIRED;
      default:
        console.error(`Invalid pending reason: ${reason}`);
        return;
    }
  }

  static parseApprovalStatus(status: ApprovalStatusEnum): ApprovalStatus {
    switch (status) {
      case 'PENDING':
        return ApprovalStatus.PENDING;
      case 'APPROVED':
        return ApprovalStatus.APPROVED;
      case 'REJECTED':
        return ApprovalStatus.REJECTED;
      case 'EXPIRED':
        return ApprovalStatus.EXPIRED;
      case 'CANCELLED':
        return ApprovalStatus.CANCELLED;
      default:
        console.error(`Invalid approval status: ${status}`);
        return;
    }
  }

  static parseOperationActivationPolicy(policy: IOperation.OperationActivationPolicy): OperationActivationPolicy {
    switch (policy) {
      case 'MANUAL':
        return OperationActivationPolicy.MANUAL;
      case 'ON_OPERATION_START':
        return OperationActivationPolicy.ON_OPERATION_START;
      case 'ON_TELEMETRY_START':
        return OperationActivationPolicy.ON_TELEMETRY_START;
      default:
        console.error(`Invalid operation activation policy: ${policy}`);
        return;
    }
  }

  static parseConstraintState(s: ConstraintState): ConstraintState {
    switch (s) {
      case ConstraintState.PROPOSED:
        return ConstraintState.PROPOSED;
      case ConstraintState.ACCEPTED:
        return ConstraintState.ACCEPTED;
      case ConstraintState.ACTIVE:
        return ConstraintState.ACTIVE;
      case ConstraintState.ENDED:
        return ConstraintState.ENDED;
      default:
        console.error(`Invalid state: ${s}`);
        return;
    }
  }

  static ParseLengthUnits(unit: TransportUnitsOfMeasure.LengthUnits): units_of_measure {
    switch (unit) {
      case 'FT':
        return units_of_measure.FT;
      case 'M':
        return units_of_measure.M;
      case 'MILE':
        return units_of_measure.MI;
      case 'NM':
        return units_of_measure.NM;
      default:
        console.error(`Invalid unit of measure: ${unit}`);
        return;
    }
  }

  static parseOperatorIntentEntry(raw: TransportOperatorIntentEntry): IOperatorIntentEntry {
    return {
      intentId: raw.intentId,
      userId: raw.userId,
      timeCreated: Parser.parseDateTime(raw.timeCreated),
      intentType: Parser.parseOperatorIntentTypeEnum(raw.intentType),
      intentStatus: Parser.parseOperatorIntentStatusEnum(raw.intentStatus),
      entityId: raw.entityId,
      entitySubmission: raw.entitySubmission,
      securityFilters: raw.securityFilters,
      intentResult: raw.intentResult ? Parser.parseOperatorIntentResult(raw.intentResult) : undefined
    };
  }

  static parseOperatorIntentResultResponse(raw: TransportOperatorIntentResultResponse): IOperatorIntentResultResponse {
    return {
      intentStatus: Parser.parseOperatorIntentStatusEnum(raw.intentStatus),
      intentResult: raw.intentResult ? Parser.parseOperatorIntentResult(raw.intentResult) : undefined
    };
  }

  static parseApprovalInfo(raw: TransportApprovalInfo): ApprovalInfo {
    return new ApprovalInfo({
      approvalRequestId: raw.approvalRequestId,
      entityId: raw.entityId,
      operatorIntentId: raw.operatorIntentId,
      timeOfRequest: Parser.parseDateTime(raw.timeOfRequest),
      expiresAt: Parser.parseDateTime(raw.expiresAt),
      operation: Parser.parseOperationExt(raw.operation),
      constraintIds: raw.constraintIds || [],
      approvalStatus: Parser.parseApprovalStatus(raw.approvalStatus),
      timeOfApproval: raw.timeOfApproval ? Parser.parseDateTime(raw.timeOfApproval) : undefined,
      approvedByUserId: raw.approvedByUserId,
      approvedByUserName: raw.approvedByUserName,
      approvalMessage: raw.approvalMessage
    });
  }

  static parseOperationApprovalInfo(raw: TransportOperationApprovalInfo): OperationApprovalInfo {
    return new OperationApprovalInfo({
      approved: raw.approved,
      approvedByUserId: raw.approvedByUserId,
      approvedByUserName: raw.approvedByUserName,
      constraintIds: raw.constraintIds || []
    });
  }

  static parseOperationReplanInfo(raw: TransportOperationReplanInfo): OperationReplanInfo {
    return {
      operationId: raw.gufi,
      replannedFrom: raw.replannedFrom ? raw.replannedFrom.map(Parser.parseOperationReplanEntry) : undefined,
      replannedTo: raw.replannedTo ? raw.replannedTo.map(Parser.parseOperationReplanEntry) : undefined
    };
  }

  static parseTelemetryIntegrationsEntry(raw: TransportTelemetryIntegrationsEntry): TelemetryIntegrationsEntry {
    return new TelemetryIntegrationsEntry({
      registrationId: raw.registrationId,
      updated: Parser.parseDateTime(raw.updated),
      integrations: Parser.parseTelemetryIntegrations(raw.integrations)
    });
  }

  static parseSupportedTelemetryIntegrationsResponse(raw: TransportSupportedTelemetryIntegrationsResponse): SupportedTelemetryIntegrationsResponse {
    return new SupportedTelemetryIntegrationsResponse({
      supportedIntegrations: (raw.supportedIntegrations || []).map(Parser.parseSupportedTelemetryIntegrationType)
    });
  }

  private static parseOwnerContact(raw: IOwnerContact): OwnerContact {
    return new OwnerContact(raw);
  }

  private static parseAdditionalRegistration(raw: IAdditionalRegistration): AdditionalRegistration {
    return new AdditionalRegistration(raw);
  }

  private static parseAircraftSpecs(raw: TransportAircraftSpecs): AircraftSpecs {
    return new AircraftSpecs({...raw, countryOfOrigin: (raw.countriesOfOrigin || [])[0] || ''});
  }

  private static parseUasStatistics(raw: IUasStatistics): UasStatistics {
    return new UasStatistics(raw);
  }

  private static parseUserMessage(raw: TransportUserMessage): IUserMessage {
    return {
      title: raw.title,
      type: raw.type,
      body: raw.body,
      data: raw.data ? Parser.parseUserMessageData(raw.type, raw.data) : undefined
    };
  }

  private static parseUserMessageData(type: string, raw: TransportUserMessageDataType): IUserMessageDataType {
    switch (type) {
      case 'OPERATOR_INTENT_RESULT':
        const opIntent = raw as TransportOperatorIntentResult;
        return new OperatorIntentResult({
          intentId: opIntent.intentId,
          intentResultTime: Parser.parseDateTime(opIntent.intentResultTime),
          intentType: Parser.parseOperatorIntentTypeEnum(opIntent.intentType),
          success: opIntent.success,
          failureReason: opIntent.failureReason ? Parser.parseOperatorIntentFailureReasonEnum(opIntent.failureReason) : undefined,
          entityId: opIntent.entityId,
          conflictingOperations: opIntent.conflictingOperations || [],
          conflictingConstraints: opIntent.conflictingConstraints || [],
          entityName: opIntent.entityName,
          errorMessage: opIntent.errorMessage,
          previous_intent_id: opIntent.previous_intent_id,
          previous_entity_id: opIntent.previous_entity_id
        });

      case 'OPERATION_CONFLICT_ALERT':
        const conflictAlert = raw as TransportOperationConflictAlertMessage;
        return new OperationConflictAlertMessage({
          operationId: conflictAlert.operationId,
          conflictingOperationId: conflictAlert.conflictingOperationId,
          conflictingOperationState: conflictAlert.conflictingOperationState ? Parser.parseState(conflictAlert.conflictingOperationState) : undefined,
          conflictingOperationPriority: conflictAlert.conflictingOperationPriority,
          conflictingOperationPrioritized: conflictAlert.conflictingOperationPrioritized,
          replanRequired: conflictAlert.replanRequired,
          replanForbidden: conflictAlert.replanForbidden
        });

      case 'CONFORMANCE_ALERT':
        const conformanceAlert = raw as TransportConformanceAlertMessage;
        return new ConformanceAlertMessage({
          operationId: conformanceAlert.operationId,
          conformanceState: conformanceAlert.conformanceState ? Parser.parseConformanceState(conformanceAlert.conformanceState) : undefined,
          volumeViolations: conformanceAlert.volumeViolations?.length ? conformanceAlert.volumeViolations.map(Parser.parseVolume4dViolation) : [],
          entityName: conformanceAlert.entityName,
          ownedByUser: conformanceAlert.ownedByUser,
          affectedOperations: conformanceAlert.affectedOperations?.length ? conformanceAlert.affectedOperations.map(Parser.parseAffectedOperation) : [],
          operationEnded: conformanceAlert.operationEnded
        });

      case 'CONSTRAINT_ALERT':
        const constraintAlert = raw as TransportConstraintAlertMessage;
        return new ConstraintAlertMessage({
          constraintId: constraintAlert.constraintId,
          operationId: constraintAlert.operationId,
          constraintType: constraintAlert.constraintType,
          constraintTypePermitted: constraintAlert.constraintTypePermitted,
          advisoryConstraint: constraintAlert.advisoryConstraint,
          constraintClosed: constraintAlert.constraintClosed,
          replanRequired: constraintAlert.replanRequired,
          replanForbidden: constraintAlert.replanForbidden,
          permittedConstraintTypes: constraintAlert.permittedConstraintTypes
        });

      case 'USER_DIRECT':
        const userDirectMessage = raw as TransportUserDirectMessage;
        return new UserDirectMessage({
          sentByUserId: userDirectMessage.sentByUserId,
          text: userDirectMessage.text,
          entityId: userDirectMessage.entityId,
          from: Parser.parseFromUser(userDirectMessage.from)
        });

      case 'APPROVAL_ALERT':
        const approvalAlert = raw as TransportApprovalAlertMessage;
        return new ApprovalAlertMessage({
          approval: Parser.parseApprovalInfo(approvalAlert.approval),
        });

      case 'LAANC_ALERT':
        const laancAlert = DEFAULT_LAANC_ALERT_MESSAGE_UTIL.deserialize(raw);
        if (laancAlert.type === 'error') {
          console.error(`Failed to parse LAANC alert message: ${laancAlert.message}`);
          return undefined;
        } else {
          return laancAlert.value;
        }

      default:
        return raw;
    }
  }

  private static parseFromUser(raw: TransportFromUser): IFromUser {
    return {
      userId: raw.userId,
      name: raw.name
    };
  }

  private static parseConformanceState(raw: TransportConformanceState): ConformanceState {
    switch (raw) {
      case TransportConformanceState.CONFORMING:
        return ConformanceState.CONFORMING;
      case TransportConformanceState.NONCONFORMING:
        return ConformanceState.NONCONFORMING;
      case TransportConformanceState.CONTINGENT:
        return ConformanceState.CONTINGENT;
      default:
        console.error(`"Unknown enum[TransportConformanceState(${raw})`);
        return undefined;
    }
  }

  private static parseAffectedOperation(raw: TransportAffectedOperation): IAffectedOperation {
    return {
      operationId: raw.operationId,
      entityName: raw.entityName
    };
  }

  private static parseVolume4dViolation(raw: TransportVolume4dViolation): Volume4dViolation {
    switch (raw) {
      case TransportVolume4dViolation.TIME_LOW:
        return Volume4dViolation.TIME_LOW;
      case TransportVolume4dViolation.TIME_HIGH:
        return Volume4dViolation.TIME_HIGH;
      case TransportVolume4dViolation.ALTITUDE_LOW:
        return Volume4dViolation.ALTITUDE_LOW;
      case TransportVolume4dViolation.ALTITUDE_HIGH:
        return Volume4dViolation.ALTITUDE_HIGH;
      case TransportVolume4dViolation.HORIZONTAL:
        return Volume4dViolation.HORIZONTAL;
      default:
        console.error(`"Unknown enum[TransportVolume4dViolation(${raw})`);
        return undefined;
    }
  }

  private static parseOperatorIntentFailureReasonEnum(raw: TransportOperatorIntentFailureReasonEnum): OperatorIntentFailureReason {
    switch (raw) {
      case TransportOperatorIntentFailureReasonEnum.OTHER:
        return OperatorIntentFailureReason.OTHER;
      case TransportOperatorIntentFailureReasonEnum.ENDED:
        return OperatorIntentFailureReason.ENDED;
      case TransportOperatorIntentFailureReasonEnum.EXPIRED:
        return OperatorIntentFailureReason.EXPIRED;
      case TransportOperatorIntentFailureReasonEnum.UNSUPPORTED_STATE:
        return OperatorIntentFailureReason.UNSUPPORTED_STATE;
      case TransportOperatorIntentFailureReasonEnum.ACCESS_DENIED:
        return OperatorIntentFailureReason.ACCESS_DENIED;
      case TransportOperatorIntentFailureReasonEnum.NOT_FOUND:
        return OperatorIntentFailureReason.NOT_FOUND;
      case TransportOperatorIntentFailureReasonEnum.CONFLICT:
        return OperatorIntentFailureReason.CONFLICT;
      case TransportOperatorIntentFailureReasonEnum.INTERNAL:
        return OperatorIntentFailureReason.INTERNAL;
      case TransportOperatorIntentFailureReasonEnum.USS_COMMUNICATION:
        return OperatorIntentFailureReason.USS_COMMUNICATION;
      case TransportOperatorIntentFailureReasonEnum.DSS_COMMUNICATION:
        return OperatorIntentFailureReason.DSS_COMMUNICATION;
      case TransportOperatorIntentFailureReasonEnum.VOLUME_CONVERSION:
        return OperatorIntentFailureReason.VOLUME_CONVERSION;
      case TransportOperatorIntentFailureReasonEnum.UTM_TOKEN_REQUEST_FAILED:
        return OperatorIntentFailureReason.UTM_TOKEN_REQUEST_FAILED;
      case TransportOperatorIntentFailureReasonEnum.PENDING_APPROVAL:
        return OperatorIntentFailureReason.PENDING_APPROVAL;
      case TransportOperatorIntentFailureReasonEnum.APPROVAL_FAILED:
        return OperatorIntentFailureReason.APPROVAL_FAILED;
      case TransportOperatorIntentFailureReasonEnum.LAANC_PENDING_APPROVAL:
        return OperatorIntentFailureReason.LAANC_PENDING_APPROVAL;
      case TransportOperatorIntentFailureReasonEnum.LAANC_APPROVAL_FAILED:
        return OperatorIntentFailureReason.LAANC_APPROVAL_FAILED;

      default:
        console.error(`"Unknown enum[TransportOperatorIntentFailureReasonEnum(${raw})`);
        return undefined;
    }
  }

  private static parseOperatorIntentTypeEnum(raw: TransportOperatorIntentTypeEnum): OperatorIntentType {
    switch (raw) {
      case TransportOperatorIntentTypeEnum.OPERATION_CREATE:
        return OperatorIntentType.OPERATION_CREATE;
      case TransportOperatorIntentTypeEnum.OPERATION_MODIFY:
        return OperatorIntentType.OPERATION_MODIFY;
      case TransportOperatorIntentTypeEnum.OPERATION_ACTIVATE:
        return OperatorIntentType.OPERATION_ACTIVATE;
      case TransportOperatorIntentTypeEnum.OPERATION_CLOSE:
        return OperatorIntentType.OPERATION_CLOSE;
      case TransportOperatorIntentTypeEnum.CONSTRAINT_CREATE:
        return OperatorIntentType.CONSTRAINT_CREATE;
      case TransportOperatorIntentTypeEnum.CONSTRAINT_MODIFY:
        return OperatorIntentType.CONSTRAINT_MODIFY;
      case TransportOperatorIntentTypeEnum.CONSTRAINT_ACTIVATE:
        return OperatorIntentType.CONSTRAINT_ACTIVATE;
      case TransportOperatorIntentTypeEnum.CONSTRAINT_CLOSE:
        return OperatorIntentType.CONSTRAINT_CLOSE;
      // case TransportOperatorIntentTypeEnum.OPERATION_CREATE_DRAFT:
      //   return OperatorIntentType.OPERATION_CREATE_DRAFT;
      default:
        console.error(`"Unknown enum[TransportOperatorIntentTypeEnum(${raw})`);
        return undefined;
    }
  }

  private static parseOperatorIntentStatusEnum(raw: TransportOperatorIntentStatus): OperatorIntentStatus {
    switch (raw) {
      case TransportOperatorIntentStatus.RECEIVED:
        return OperatorIntentStatus.RECEIVED;
      case TransportOperatorIntentStatus.PROCESSING:
        return OperatorIntentStatus.PROCESSING;
      case TransportOperatorIntentFailureReasonEnum.PENDING_APPROVAL:
        return OperatorIntentStatus.PENDING_APPROVAL;
      case TransportOperatorIntentStatus.LAANC_PENDING_APPROVAL:
        return OperatorIntentStatus.LAANC_PENDING_APPROVAL;
      case TransportOperatorIntentStatus.FINISHED:
        return OperatorIntentStatus.FINISHED;
      default:
        console.error(`Unknown enum[TransportOperatorIntentStatus(${raw})`);
        return undefined;
    }
  }

  private static parseOperatorIntentResult(raw: TransportResult): Result {
    return {
      resultTime: Parser.parseDateTime(raw.resultTime),
      success: raw.success,
      failureReason: raw.failureReason ? Parser.parseOperatorIntentFailureReasonEnum(raw.failureReason) : undefined,
      errorMessage: raw.errorMessage,
      resultEntityId: raw.resultEntityId,
      conflictingOperations: raw.conflictingOperations,
      conflictingConstraints: raw.conflictingConstraints
    };
  }

  private static parseOperationReplanEntry(raw: TransportOperationReplanEntry): OperationReplanEntry {
    return {
      operationId: raw.gufi,
      intentId: raw.intentid,
      state: Parser.parseState(raw.state),
      submit_time: Parser.parseDateTime(raw.submit_time),
      effective_time_begin: Parser.parseDateTime(raw.effective_time_begin),
      effective_time_end: Parser.parseDateTime(raw.effective_time_end)
    };
  }

  private static parseAdditionalConstraintData(raw: SwaggerAdditionalConstraintData): AdditionalConstraintData {
    return new AdditionalConstraintData(raw);
  }

  private static parseAltitudeSourceEnum(raw: TransportAltitudeSourceEnum): source {
    switch (raw) {
      case TransportAltitudeSourceEnum.ONBOARDSENSOR:
        return source.ONBOARD_SENSOR;
      case TransportAltitudeSourceEnum.OTHER:
        return source.OTHER;
      default:
        console.error(`"Unknown enum[TransportAltitudeSourceEnum(${raw})`);
        return undefined;
    }
  }

  static parseTelemetryStatus(raw: TransportTelemetryStatus): ITelemetryStatus {
    return {
      registrationId: raw.registrationId,
      registrationName: raw.registrationName,
      lastSeen: Parser.parseDateTime(raw.lastSeen),
      status: Parser.parseTelemetryStatusEnum(raw.status),
      telemetrySource: raw.telemetrySource,
      error: raw.error
    };
  }

  private static parseTelemetryStatusEnum(status: TransportTelemetryStatus.StatusEnum): TelemetryStatusEnum {
    switch (status) {
      case 'ACTIVE':
        return TelemetryStatusEnum.ACTIVE;
      case 'INACTIVE':
        return TelemetryStatusEnum.INACTIVE;
      case 'ERROR':
        return TelemetryStatusEnum.ERROR;
      default:
        console.error(`Invalid telemetry status: ${status}`);
        return TelemetryStatusEnum.INVALID;
    }
  }

  private static parseTelemetryIntegrations(raw: TransportTelemetryIntegrations): TelemetryIntegrations {
    return new TelemetryIntegrations({
      cot: (raw.cot || []).map(Parser.parseCotTelemetryIntegration),
      rigitech: (raw.rigitech || []).map(Parser.parseRigitechTelemetryIntegration),
      mavlink: (raw.mavlink || []).map(Parser.parseMavlinkTelemetryIntegration)
    });
  }

  private static parseCotTelemetryIntegration(raw: TransportCotTelemetryIntegration): CotTelemetryIntegration {
    return new CotTelemetryIntegration({
      cotCallsign: raw.cotCallsign,
      cotUid: raw.cotUid,
      cotAltitudeUsesMsl: raw.cotAltitudeUsesMsl
    })
  }

  private static parseMavlinkTelemetryIntegration(raw: TransportMavlinkTelemetryIntegration): MavlinkTelemetryIntegration {
    return new MavlinkTelemetryIntegration({
      protocol: Parser.parseTelemetryProtocolEnum(raw.protocol),
      port: raw.port
    });
  }

  private static parseRigitechTelemetryIntegration(raw: TransportRigitechTelemetryIntegration): RigitechTelemetryIntegration {
    return new RigitechTelemetryIntegration({
      rigitechDroneId: raw.rigitechDroneId,
      rigitechSerialNumber: raw.rigitechSerialNumber
    });
  }

  private static parseTelemetryProtocolEnum(protocol: TransportMavlinkTelemetryIntegration.Protocol): Protocol {
    switch(protocol) {
      case "UDP":
        return Protocol.UDP;
      case "TCP":
        return Protocol.TCP;
      default:
        console.error(`Invalid protocol: ${protocol}`);
        return Protocol.INVALID;
    }
  }

  private static parseSupportedTelemetryIntegrationType(raw: TransportSupportedTelemetryIntegrationsResponse.TelemetryIntegrationType): TelemetryIntegrationType {
    switch(raw) {
      case "COT":
        return TelemetryIntegrationType.COT;
      case "MAVLINK":
        return TelemetryIntegrationType.MAVLINK;
      case "RIGITECH":
        return TelemetryIntegrationType.RIGITECH;
      default:
        console.error(`Invalid telemetry integration type: ${raw}`);
        return TelemetryIntegrationType.INVALID;
    }
  }
}
