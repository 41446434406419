<ng-template #toolbarTemplate>
    @for (button of buttons; track $index) {
      <ng-container [ngTemplateOutlet]="button.button"></ng-container>
    }
  
</ng-template>

<ng-template #generalTemplate>
  @for (button of buttons; track $index) {
    <ng-container [ngTemplateOutlet]="button.generalWidget"></ng-container>
  }
</ng-template>
