import {Component, computed, effect, inject, input, OnDestroy} from '@angular/core';
import {Entity4DType, EntityVolume4d} from '../../model/gen/utm';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {ColorId, ColorService} from '../../services/color.service';
import {combineLatest, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {CustomDataSource, Entity, Event} from '@cesium/engine';
import {CesiumDrawerUtilService} from '../common/cesium-drawer-util.service';
import {toObservable, toSignal} from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-entity-volume4d-drawer',
  template: ''
})
export class EntityVolume4dDrawerComponent implements OnDestroy {
  volumes$ = input.required<EntityVolume4d[]>();
  entityType$ = input.required<Entity4DType>();

  private drawerService = inject(CesiumService);
  private colorService = inject(ColorService);
  private cesiumDrawerUtilService = inject(CesiumDrawerUtilService);

  private viewer$ = toSignal(this.drawerService.watchViewerInit());
  private color$ = computed(() => {
    this.entityType$();
    return this.colorService.getColorForIdImmediate(this.getColorIdForEntity(), true);
  });
  private entityData$ = combineLatest([
    toObservable(this.volumes$),
    toObservable(this.entityType$),
    toObservable(this.color$)
  ]);

  private entities$ = toSignal(this.entityData$.pipe(
    switchMap(([volumes, entityType, color]) => {
      const ret: Observable<Entity>[] = [];

      for (const vol of volumes) {
        let label = '';
        switch (entityType) {
          case 'laanc-submission':
            label = 'LAANC Submission Volume';
            break;
          case 'laanc-exclusion':
            label = 'LAANC Excluded Volume';
            break;
          case 'constraint':
          default:
            label = 'Constraint Volume';
        }
        ret.push(this.cesiumDrawerUtilService.getConvertedEntityVolume4DCesiumEntity(vol, color, label));
      }

      return combineLatest(ret);
    }
  )
  ));

  private dataSource: CustomDataSource = new CustomDataSource('constraint-volume-drawer');
  private terrainTileListenerRemove: Event.RemoveCallback;

  constructor() {
    effect(() => {
      if (this.viewer$() && this.entities$()?.length) {
        const viewer = this.viewer$();
        viewer.dataSources.remove(this.dataSource);
        this.dataSource.entities.removeAll();
        this.entities$().forEach(entity => this.dataSource.entities.add(entity));
        viewer.dataSources.add(this.dataSource).then(() => {
          if (viewer.scene.globe.tilesLoaded) {
            viewer.zoomTo(this.dataSource).then(() => {
            });
          } else if (!this.terrainTileListenerRemove) {
            this.terrainTileListenerRemove = viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
              if (viewer.scene.globe.tilesLoaded) {
                viewer.zoomTo(this.dataSource).then(() => {
                });
                this.terrainTileListenerRemove();
              }
            });
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    const viewer = this.viewer$();
    viewer?.dataSources.remove(this.dataSource);
  }

  private getColorIdForEntity(): ColorId {
    switch (this.entityType$()) {
      case 'laanc-submission':
        return 'operation';
      case 'laanc-exclusion':
        return 'operation-closed';
      case 'constraint':
      default:
        return 'constraint';
    }
  }
}
