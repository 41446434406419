
import Provider, { EndpointArgument, ParseArgument, ProviderOptions, ProviderParams, SearchArgument, SearchResult } from "leaflet-geosearch/src/providers/provider";
import {CesiumIonLayerConfig} from "@ax/ax-angular-map-common";
import {IonGeocoderService, Rectangle, Scene} from "@cesium/engine";
import {Math as CMath} from "@cesium/engine";
const FAKE_SCENE = {};



export class CesiumProvider implements Provider {
  private ionCoder: IonGeocoderService;
  constructor(mapConfig: CesiumIonLayerConfig) {

    this.ionCoder = new IonGeocoderService({scene:FAKE_SCENE as Scene, accessToken: mapConfig.access_token});


  }
    options: ProviderOptions ={
    };
    endpoint(options: EndpointArgument): string {
        throw new Error("Method not implemented.");
    }
    parse(response: ParseArgument<any>): SearchResult<any>[] {
        throw new Error("Method not implemented.");
    }
    getParamString(params?: ProviderParams): string {
        throw new Error("Method not implemented.");
    }
    getUrl(url: string, params?: ProviderParams | undefined): string {
        throw new Error("Method not implemented.");
    }

    search(options: SearchArgument): Promise<SearchResult<any>[]> {
      return this.ionCoder.geocode(options.query).then((results) => {
        return results.map((result) => {
          const dest = result.destination;
          if(!(dest instanceof Rectangle)){
            return null;
          }
          const center = Rectangle.center(dest);
          return {
            x: CMath.toDegrees(center.longitude),
            y: CMath.toDegrees(center.latitude),
            label: result.displayName,
            bounds: [
              [
                CMath.toDegrees(dest.south),
                CMath.toDegrees(dest.west),
                ],[
                CMath.toDegrees(dest.north),
                CMath.toDegrees(dest.east)
                ]
            ],
          };
        }).filter((r) => r !== null) as SearchResult[];
      });
    }

}
