import {Component, ElementRef, inject, OnDestroy, signal, ViewChild} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';

import {OperationGeometry} from '../../../../../../model/operation.geometry';
import {MeasurementSystemType} from '../../../../../../model/MeasurementSystem';
import {FormControl, Validators} from '@angular/forms';
import {ClrTimelineLayout, ClrTimelineStepState} from '@clr/angular';
import {reviewableFlightPlanFactory} from '../factory';

import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UTMFlightPlanCustomizer} from '../common';
import {AltitudeService} from '../../../../../altitude.service';
import {ConfigComponent} from "../../../../../../import-export/config.component";
import {ImportService} from "../../../../../../import-export/import-export.service";


type TimelineState = {
  REVIEW: ClrTimelineStepState;
  UPLOAD_STEP: ClrTimelineStepState;
};

type TimelineStep = 'UPLOAD' | 'REVIEW';

@Component({
  selector: 'app-flightplan-operation-volume-import-config',
  templateUrl: './flightplan-operation-volume-import-config.component.html',
  styleUrl: './flightplan-operation-volume-import-config.component.scss'
})
export class FlightplanOperationVolumeImportConfigComponent implements ConfigComponent, OnDestroy {
  @ViewChild('importFileControl') importFileControl: ElementRef;
  extensions = '.waypoints,.fp,.mission,.json,.plan';
  measurementSystemType = MeasurementSystemType;


  private configSubject = new ReplaySubject<UTMFlightPlanCustomizer | null>(1);
  private service: ImportService<OperationGeometry>;
  private fgSub: Subscription;
  timelineState: TimelineState = {
    UPLOAD_STEP: ClrTimelineStepState.CURRENT,
    REVIEW: ClrTimelineStepState.NOT_STARTED
  };
  layout = ClrTimelineLayout.HORIZONTAL;
  CURRENT_STEP = signal<TimelineStep>('UPLOAD');
  flightPlan: UTMFlightPlanCustomizer;
  flightPlanInput = new FormControl<File>(null, Validators.required);
  altitudeService = inject(AltitudeService);

  get configComponent() {
    return this.flightPlan.getConfigurationComponent();
  }

  constructor() {


    this.flightPlanInput.valueChanges.pipe(takeUntilDestroyed()).subscribe(async (f) => {
      if (!f) {
        return Promise.resolve();
      }
      await this.parseFlightPlan();
    });
  }


  getConfig(): Observable<UTMFlightPlanCustomizer> {
    return this.configSubject;
  }

  setConfig(config: UTMFlightPlanCustomizer) {

    if (!config && this.importFileControl) {
      this.flightPlanInput.setValue(null);
    }
    this.configSubject.next(config);
  }

  setService(service: ImportService<OperationGeometry>) {
    this.service = service;
    this.extensions = (service.getFileExtensions() || []).join(',');
  }

  ngOnDestroy(): void {
    this.fgSub?.unsubscribe();
  }


  async parseFlightPlan() {
    const f = this.importFileControl.nativeElement.files[0];

    if (!f) {
      return;
    }
    this.timelineState.UPLOAD_STEP = ClrTimelineStepState.SUCCESS;

    const flightPlan = await reviewableFlightPlanFactory.parseFile(f, this.altitudeService);
    if (!flightPlan) {
      this.timelineState.UPLOAD_STEP = ClrTimelineStepState.ERROR;
      return;
    }

    this.timelineState.REVIEW = ClrTimelineStepState.CURRENT;
    this.CURRENT_STEP.set('REVIEW');
    this.fgSub?.unsubscribe();

    this.fgSub = flightPlan.status$.subscribe(status => {
      this.configSubject.next(status ? flightPlan : null);
    });
    this.flightPlan = flightPlan;
  }

  reset() {
    this.flightPlan = null;
    this.configSubject.next(null);
    this.fgSub?.unsubscribe();
    this.CURRENT_STEP.set('UPLOAD');
    this.timelineState.UPLOAD_STEP = ClrTimelineStepState.CURRENT;
    this.timelineState.REVIEW = ClrTimelineStepState.NOT_STARTED;
    this.flightPlanInput.setValue(null);


  }
}
