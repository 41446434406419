<clr-datagrid class="datagrid-compact">
  <clr-dg-column>Volume Number</clr-dg-column>
  <clr-dg-column>Type</clr-dg-column>
  <clr-dg-column>Maximum Altitude</clr-dg-column>
  <clr-dg-column>Start Time</clr-dg-column>
  <clr-dg-column>End Time</clr-dg-column>
  <clr-dg-column>Facility Code</clr-dg-column>
  <clr-dg-column>Airspace Classes</clr-dg-column>

  @for (volume of volumes$(); track $index) {
    <clr-dg-row [clrDgItem]="volume">
      <clr-dg-cell>{{$index + 1}}</clr-dg-cell>
      <clr-dg-cell>{{humanizedLaancSubmissionTypes[volume.type]}}</clr-dg-cell>
      <clr-dg-cell>{{volume.maximumAltitudeAgl}} FT AGL</clr-dg-cell>
      <clr-dg-cell>{{volume.volume?.effective_time_begin}}</clr-dg-cell>
      <clr-dg-cell>{{volume.volume?.effective_time_end}}</clr-dg-cell>
      <clr-dg-cell>{{volume.faaFacilityCode}}</clr-dg-cell>
      <clr-dg-cell>{{volume.airspaceClasses.join(', ')}}</clr-dg-cell>
    </clr-dg-row>
  }

  <clr-dg-detail *clrIfDetail="let volume">
    <clr-dg-detail-header>LAANC Submission Volume</clr-dg-detail-header>
    <clr-dg-detail-body>
      <ax-cesium-container [timeline]="false"
                           [navigationInstructionsInitiallyVisible]="false"
                           [toolbarZIndex]="'999'"
                           [sceneModePicker]="false">
        <lib-infobox-container/>
        <laanc-volume-preview-drawer [volumes$]="[volume]"></laanc-volume-preview-drawer>
      </ax-cesium-container>

      <table class="table">
        <tbody>
          <tr>
            <th scope="row">Type</th>
            <td>{{humanizedLaancSubmissionTypes[volume.type]}}</td>
          </tr>
          <tr>
            <th scope="row">Maximum Altitude</th>
            <td>{{volume.maximumAltitudeAgl}} FT AGL</td>
          </tr>
          <tr>
            <th scope="row">Start Time</th>
            <td>{{volume.volume.effective_time_begin}}</td>
          </tr>
          <tr>
            <th scope="row">FAA Facility Code</th>
            <td>{{volume.faaFacilityCode}}</td>
          </tr>
          <tr>
            <th scope="row">Airspace Classes</th>
            <td>{{volume.airspaceClasses.join(', ')}}</td>
          </tr>
        </tbody>
      </table>
    </clr-dg-detail-body>
  </clr-dg-detail>
</clr-datagrid>
