<ng-template #buttonTemplate>
  <div class="root">
    <button type="button" class="cesium-button cesium-toolbar-button cesium-home-button north-button"
            [title]="title()" (click)="toggleShow($event)">

      <cds-icon class="btn-cds-icon" [shape]="cdsIconName()" [solid]="showContent$()"></cds-icon>

    </button>

    <div class="toolbar-dropdown"
         [class.toolbar-dropdown-visible]="showContent$()">
      <ng-content></ng-content>
    </div>

  </div>
</ng-template>
