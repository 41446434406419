import {Component, computed, effect, inject, input, OnDestroy, signal} from '@angular/core';
import {CzmlDrawerComponent} from '../../../cesium/czml-drawer/czml-drawer.component';
import {CesiumComponentsModule} from '@ax/ax-angular-map-cesium';
import {CesiumModule} from '../../../cesium/cesium.module';
import {DatePipe, NgIf} from '@angular/common';
import {
  DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL,
  DEFAULT_LAANC_SUBMISSION_STATE_UTIL,
  DEFAULT_LAANC_SUBMISSION_TYPE_UTIL,
  InfoboxContainerComponent,
  LaancSubmissionCategory,
  LaancSubmissionState,
  LaancSubmissionType,
  Result
} from '@ax-uss-ui/common';
import {ClrAlertModule} from '@clr/angular';
import {LaancSubmissionEntry} from '../../../model/laanc/LaancSubmissionEntry';
import {LaancService} from '../../../services/laanc.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';

interface ILaancAcknowledgement {
  type: 'Rescinded' | 'InvalidCancel';
  referenceCode: string;
}

@Component({
  selector: 'laanc-submission-summary',
  standalone: true,
  imports: [
    CzmlDrawerComponent,
    CesiumComponentsModule,
    CesiumModule,
    NgIf,
    InfoboxContainerComponent,
    ClrAlertModule,
    DatePipe
  ],
  templateUrl: './laanc-submission-summary.component.html',
  styleUrl: './laanc-submission-summary.component.scss',
})

export class LaancSubmissionSummaryComponent implements OnDestroy {
  submission$ = input<LaancSubmissionEntry>(null);
  referenceCode$ = input<string>(null);

  error: string;
  humanizedLaancSubmissionCategories = DEFAULT_LAANC_SUBMISSION_CATEGORY_UTIL.humanized;
  humanizedLaancSubmissionStates = DEFAULT_LAANC_SUBMISSION_STATE_UTIL.humanized;
  humanizedLaancSubmissionTypes = DEFAULT_LAANC_SUBMISSION_TYPE_UTIL.humanized;
  laancSubmissionCategory = LaancSubmissionCategory;
  laancSubmissionState = LaancSubmissionState;
  laancSubmissionType = LaancSubmissionType;

  displayedSubmission$ = signal<LaancSubmissionEntry>(null);
  denialReason$ = computed<string>(() => {
    if (!this.displayedSubmission$()) {
      return;
    }
    return this.displayedSubmission$().denialReasonCodeText || this.displayedSubmission$().denialReason || null;
  })

  private laancService = inject(LaancService);
  private submissionSub: Subscription;

  constructor() {
    // Populate the LAANC submission data either from a LAANC submission object or a reference code
    effect(() => {
      if (this.submission$()) {
        this.error = '';
        this.displayedSubmission$.set(this.submission$());
      } else if (this.referenceCode$())  {
        this.submissionSub?.unsubscribe();
        this.submissionSub = this.laancService.getLaancSubmission(this.referenceCode$())
          .subscribe({ next: (result: Result<LaancSubmissionEntry>) => {
            if (result.type === 'some') {
              this.error = '';
              this.displayedSubmission$.set(result.value);
            } else {
              this.error = 'Error: ' + result.message;
            }
          }, error: ((error: HttpErrorResponse) => {
            this.error =  'Error: ' + (error?.error?.message || 'Unknown error retrieving LAANC submission');
          })});
      } else {
        this.error = 'Error: No LAANC submission or reference code provided';
      }
    }, {allowSignalWrites: true});
  }

  ngOnDestroy(): void {
    this.submissionSub?.unsubscribe();
  }
}
