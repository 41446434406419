import {Component, effect, inject, input, OnDestroy, output} from '@angular/core';
import {CommonModule} from '@angular/common';

import Provider from "leaflet-geosearch/src/providers/provider";
import {LeafletDrawerService} from "@ax/ax-angular-map-leaflet";
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {GeoSearchControl} from "leaflet-geosearch";
import {Control, LeafletEvent} from "leaflet";
import {LeafletExtService} from "../../../services";
import {BehaviorSubject, first, repeat, share, shareReplay, skipUntil, Subject, take, tap} from "rxjs";
import {switchMap} from "rxjs/operators";


@Component({
  selector: 'lib-geocoder',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './geocoder.component.html',
  styleUrl: './geocoder.component.scss',
  providers: [LeafletExtService]
})
export class GeocoderComponent implements OnDestroy {
  leafletExtDrawerService = inject(LeafletExtService);
  geocoder = input<Provider>();
  map = this.leafletExtDrawerService.mapSignal;

  bounds = output<L.LatLngBounds>();

  private geosubject = new Subject<LeafletEvent>();
  private boundsubject = new Subject<LeafletEvent>();


  private boundsSub = this.boundsubject
    // .pipe(takeUntilDestroyed())
    .pipe(shareReplay())
    .pipe(skipUntil(this.geosubject), first(), repeat())
    .subscribe((event) => {

      const map = this.map();
      if (!map) {
        return;
      }
      this.bounds.emit(map.getBounds());

    });

  private geocodeSub = this.leafletExtDrawerService.on('geosearch/showlocation').pipe(takeUntilDestroyed()).subscribe((event) => {
    this.geosubject.next(event);
  });

  private tmpSub = this.leafletExtDrawerService.on('moveend').pipe(takeUntilDestroyed()).subscribe((event) => {
    this.boundsubject.next(event);
  });


  private control: Control | undefined;

  ngOnDestroy() {
    this.boundsSub?.unsubscribe();
    this.geocodeSub?.unsubscribe();
    this.tmpSub?.unsubscribe();
  }

  constructor() {
    effect(() => {

      this.control?.remove?.();
      this.control = undefined;
      const coder = this.geocoder();
      const map = this.map();

      if (!coder || !map) {
        return;
      }

      const searchControl = GeoSearchControl({
        provider: coder,
        style: 'button',
        showMarker: false,
        position: 'topright',
        autoClose: true,
        retainZoomLevel: true
      });

      map.addControl(searchControl);
      this.control = searchControl;

    });
  }

}
