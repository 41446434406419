<clr-alerts>
  <clr-alert *ngFor="let message of messages; let i = index" [clrAlertType]="getAlertType(message)"
             [clrAlertAppLevel]="true" (clrAlertClosedChange)="markMessagesAsRead([message])">
    <clr-alert-item>
      <ng-container
        *ngTemplateOutlet="alertMessageItemContent; context: {message: message}"></ng-container>
    </clr-alert-item>
  </clr-alert>
</clr-alerts>

<clr-alerts>
  @for (message of errorMessages; track $index) {
    <clr-alert [clrAlertType]="getAlertType(message)"
               [clrAlertAppLevel]="true"
               [clrAlertClosable]="getAlertClosable(message)"
               (clrAlertClosedChange)="markMessagesAsRead([message])">
      <clr-alert-item>
        <ng-container
          *ngTemplateOutlet="alertMessageItemContent; context: {message: message}"></ng-container>
      </clr-alert-item>
    </clr-alert>
  }
</clr-alerts>

<ng-template #alertMessageItemContent let-message='message'>
  <div class="alert-text">
    {{getMessageSummary(message)}}
  </div>

  <div class="alert-actions">
    <!-- View Message -->
    <button type="button" class="btn alert-action" (click)="viewMessage(message)">
      {{message.message.type === 'LAANC_ALERT' && !!getLaancAlertMessageData(message.message).requiresAck ?
      'View & Acknowledge' : 'View Message'}}</button>

    <!-- View Operation - Non-conforming or contingent -->
    <a *ngIf="message.message.type === 'CONFORMANCE_ALERT'" [routerLink]="'/fuss/operations/view-operation'"
       [queryParams]="{operationId: getConformanceAlertMessageData(message.message).operationId}"
       (click)="markMessagesAsRead([message])">
      <button type="button" class="btn alert-action">Go to operation</button>
    </a>

    <!-- Reroute - Failed activation -->
    <ng-container *ngIf="message.message.type === 'OPERATOR_INTENT_RESULT' &&
        getOperatorIntentResultMessageData(message.message)?.intentType === 'OPERATION_ACTIVATE' &&
        getOperatorIntentResultMessageData(message.message)?.success === false &&
        getOperatorIntentResultMessageData(message.message)?.failureReason === 'CONFLICT'">
      <a
        *hasPermission="{action: 'modify', resourceType:'operation', resource: operationCache[getOperatorIntentResultMessageData(message.message).entityId]}"
        [queryParams]="{operationId: getOperatorIntentResultMessageData(message.message).entityId, mode: mode.rerouteAccepted}"
        [routerLink]="'/fuss/operations/newop'" (click)="clearMessageById(message)">
        <button type="button" class="btn alert-action">Replan operation</button>
      </a>
    </ng-container>

    <!-- Reroute - Higher priority operation overlap-->
    <ng-container *ngIf="message.message.type === 'OPERATION_CONFLICT_ALERT' &&
        !getOperationConflictMessageData(message.message)?.replanForbidden &&
        getOperationConflictMessageData(message.message)?.conflictingOperationPrioritized &&
        getOperationConflictMessageData(message.message)?.conflictingOperationState !== 'CLOSED' &&
        getOperationConflictMessageData(message.message)?.replanRequired">
      <a
        *hasPermission="{action: 'modify', resourceType:'operation', resource: operationCache[getOperationConflictMessageData(message.message).operationId]}"
        [queryParams]="{operationId: getOperationConflictMessageData(message.message).operationId,
        mode: operationCache[getOperationConflictMessageData(message.message).operationId].state === state.ACCEPTED ? mode.rerouteAccepted : mode.rerouteActive}"
        [routerLink]="'/fuss/operations/newop'" (click)="clearMessageById(message)">
        <button type="button" class="btn alert-action">Replan operation</button>
      </a>
    </ng-container>

    <!-- Reroute - Constraint overlap-->
    <ng-container *ngIf="message.message.type === 'CONSTRAINT_ALERT' &&
        !getConstraintAlertMessageData(message.message)?.replanForbidden &&
        !getConstraintAlertMessageData(message.message)?.constraintTypePermitted &&
        !getConstraintAlertMessageData(message.message)?.constraintClosed &&
        getConstraintAlertMessageData(message.message)?.replanRequired">
      <a
        *hasPermission="{action: 'modify', resourceType:'operation', resource: operationCache[getConstraintAlertMessageData(message.message).operationId]}"
        [queryParams]="{operationId: getConstraintAlertMessageData(message.message).operationId,
        mode: operationCache[getConstraintAlertMessageData(message.message).operationId].state === state.ACCEPTED ? mode.rerouteAccepted : mode.rerouteActive}"
        [routerLink]="'/fuss/operations/newop'" (click)="clearMessageById(message)">
        <button type="button" class="btn alert-action">Replan operation</button>
      </a>
    </ng-container>

    <!-- Replan - Rejected -->
    <ng-container *ngIf="message.message.type === 'OPERATOR_INTENT_RESULT' &&
        getOperatorIntentResultMessageData(message.message)?.intentType === 'OPERATION_CREATE' &&
        getOperatorIntentResultMessageData(message.message)?.success === false &&
        getOperatorIntentResultMessageData(message.message)?.failureReason === 'CONFLICT'">
      <a
        *hasPermission="{action: 'modify', resourceType:'operation', resource: operationCache[getOperatorIntentResultMessageData(message.message).entityId]}"
        [queryParams]="{operationId: getOperatorIntentResultMessageData(message.message).entityId, mode: mode.replan}"
        [routerLink]="'/fuss/operations/newop'" (click)="clearMessageById(message)">
        <button type="button" class="btn alert-action">Replan operation</button>
      </a>
    </ng-container>
  </div>

</ng-template>

<clr-modal [clrModalSize]="modalSize"
           [clrModalOpen]="!!currentMessage"
           (clrModalOpenChange)="currentMessage = $event ? currentMessage : undefined"
           [clrModalStaticBackdrop]="false"
           *ngIf="!!currentMessage">
  <h3 class="modal-title">{{getMessageSummary(currentMessage)}}</h3>
  <div class="modal-body">
    <app-user-message-summary-v2 [message]="currentMessage"></app-user-message-summary-v2>
  </div>
</clr-modal>
