import {AXCesiumWidget, CesiumService} from '@ax/ax-angular-map-cesium';
import {Subscription} from 'rxjs';
import {AfterViewInit, Component, OnDestroy} from '@angular/core';
import {Viewer} from '@cesium/engine';

@Component({template: ''})
export abstract class CesiumComponent extends AXCesiumWidget implements AfterViewInit, OnDestroy {


  private viewerSub: Subscription | undefined;

  protected constructor(protected cesiumService: CesiumService) {
    super();
  }

  ngAfterViewInit(): void {
    if (!this.cesiumService) {
      return;
    }
    this.viewerSub = this.cesiumService.watchViewerInit().subscribe((viewer) => {
      this.onViewerInit(viewer);
    });
  }

  abstract onViewerInit(viewer: Viewer): void;

  ngOnDestroy(): void {
    this.viewerSub?.unsubscribe();
  }
}
