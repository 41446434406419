import {Injectable, Type} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ExportService, ImportExportType} from '../../../../../import-export/import-export.service';
import {ConfigComponent} from '../../../../../import-export/config.component';
import {Feature, FeatureCollection, Point, Position} from '@ax/ax-angular-map-common';
import {IOpGeoSubmissionFG} from '../../../../../components/operation/create-operation/create-operation.component';
import {DateTime} from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class GeojsonOperationVolumeExportService extends ExportService<Blob | null> {
  constructor() {
    super();
  }

  doExport(geometry: IOpGeoSubmissionFG): Observable<Blob | null> {
    const features: Feature[] = geometry.volumes.map(vol => {
      const startTime = vol.timeRange.start || DateTime.now();
      if (vol.circle) {
        return {
          type: 'Feature',
          geometry: {
            type: 'GeometryCollection',
            geometries: [{
              type: 'Point',
              coordinates: [vol.circle.longitude, vol.circle.latitude]
            } as Point]
          },
          properties: {
            id: 'operation_volumes',
            beginTime: startTime.toISO(),
            endTime: vol.timeRange.end.toISO(),
            min_altitude: vol.altitudeRange.min_altitude,
            max_altitude: vol.altitudeRange.max_altitude,
            altitude_vertical_reference: vol.altitudeRange.altitude_vertical_reference.toString(),
            altitude_units: vol.altitudeRange.altitude_units.toString(),
            radius: vol.circle.radius,
            radiusUnits: vol.circle.units
          }
        };
      } else if (vol.geography) {
        return {
          type: 'Feature',
          geometry: {
            type: 'GeometryCollection',
            geometries: [{
              type: 'Polygon',
              coordinates: vol.geography.coordinates as Position[][],
            }]
          },
          properties: {
            id: 'operation_volumes',
            beginTime: startTime.toISO(),
            endTime: vol.timeRange.end.toISO(),
            min_altitude: vol.altitudeRange.min_altitude,
            max_altitude: vol.altitudeRange.max_altitude,
            altitude_vertical_reference: vol.altitudeRange.altitude_vertical_reference.toString(),
            altitude_units: vol.altitudeRange.altitude_units.toString()
          }
        };
      }
    });

    if (geometry.takeOffLocation) {
      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [geometry.takeOffLocation.lng, geometry.takeOffLocation.lat, geometry.takeOffLocation.alt]
        } as Point,
        properties: {
          id: 'takeoff_location'
        }
      });
    }

    if (geometry.controllerLocation) {
      features.push( {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [geometry.controllerLocation.lng, geometry.controllerLocation.lat, geometry.controllerLocation.alt]
        } as Point,
        properties: {
          id: 'controller_location'
        }
      });
    }
    if (geometry.points){
      features.push({
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: (geometry.points || []).map(p => [p.lng, p.lat])
        },
        properties: {
          id: 'waypoints'
        }
      });
    }


    const ret: FeatureCollection = {
      type: 'FeatureCollection',
      features
    };

    return of(new Blob([JSON.stringify(ret, null, 2)], {type: 'application/geo+json'}));
  }

  getFormatName(): string {
    return 'GeoJSON';
  }

  getType(): ImportExportType {
    return ImportExportType.OPERATIONVOLUME;
  }

  getFileExtensions(): string[] {
    return ['.json', '.geojson'];
  }

  getConfigComponent(): Type<ConfigComponent> {
    return null;
  }

  getDefaultConfig(): any {
    return {};
  }
}
