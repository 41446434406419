import {Component, inject, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {ColorConfig} from "../../services/color.service";
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, ReplaySubject} from "rxjs";
import {CustomDataSource, Entity, Event} from "@cesium/engine";
import {CesiumService} from "@ax/ax-angular-map-cesium";
import {CesiumDrawerUtilService} from "../common/cesium-drawer-util.service";
import {AltitudeUtilService} from "../../model/utm/altitude-util.service";
import {filter, map, switchMap} from "rxjs/operators";
import {isNil} from "lodash";
import {EntityVolume4d, units_of_measure, vertical_reference} from "../../model/gen/utm";
import {Converter} from "../../utils/convert-units";
import {AltitudeRange} from "../../model/gen/utm/altitude-range-model";
import {IUvrGeoSubmissionFG} from "../../components/constraint/edit-constraint/edit-constraint.component";
import {
  IUvrVolumeSubmissionFG
} from "../../components/constraint/constraint-geometry-editor/constraint-geometry-editor.component";
import {IOpGeoSubmissionFG} from "../../components/operation/create-operation/create-operation.component";
import {Viewer} from "@cesium/widgets";
import {LatLng} from "leaflet";

@Component({
  selector: 'app-constraint-geometry-drawer',
  template: ''
})
export class ConstraintGeometryDrawerComponent implements OnChanges, OnDestroy {
  @Input() geometry: IUvrGeoSubmissionFG;
  @Input() colorConfig: ColorConfig;

  private drawerService = inject(CesiumService);
  private cesiumDrawerUtilService = inject(CesiumDrawerUtilService);
  private altitudeUtilService = inject(AltitudeUtilService);

  private rawGeometrySubject: ReplaySubject<IOpGeoSubmissionFG> = new ReplaySubject<IOpGeoSubmissionFG>(1);
  private colorConfigSubject = new BehaviorSubject<ColorConfig>(null);
  private dataSource: CustomDataSource = new CustomDataSource('constraint-geometry-drawer');

  geometry$ = this.rawGeometrySubject.pipe(
    switchMap(geometry => this.cleanupGeometry(geometry))
  )

  entityObservable$ = combineLatest([
    this.geometry$,
    this.colorConfigSubject.pipe(filter(config => !!config))
  ]).pipe(map(([geometry, colorConfig]) => {
    const ret: Entity[] = [];

    for (const vol of geometry.volumes) {
      if ((vol.geography?.coordinates?.length || vol.circle) &&
        !isNil(vol.altitudeRange?.min_altitude) && !isNil(vol.altitudeRange?.max_altitude)) {
        const entity = this.cesiumDrawerUtilService.getEntityVolume4DCesiumEntity(this.convertVolFGtoVol(vol), colorConfig);
        ret.push(entity);
      }
    }
    return ret;
  }));

  drawSub = combineLatest([
    this.drawerService.watchViewerInit(),
    this.entityObservable$
  ]).subscribe(([viewer, entities]) => {
    this.viewer = viewer;
    viewer.dataSources.remove(this.dataSource);
    this.dataSource.entities.removeAll();
    entities.forEach(entity => this.dataSource.entities.add(entity));
    viewer.dataSources.add(this.dataSource).then(() => {
      if (viewer.scene.globe.tilesLoaded) {
        viewer.zoomTo(this.dataSource).then(() => {});
      } else if (!this.terrainTileListenerRemove){
        this.terrainTileListenerRemove = viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
          if (viewer.scene.globe.tilesLoaded){
            viewer.zoomTo(this.dataSource).then(() => {});
            this.terrainTileListenerRemove();
          }
        });
      }
    });
  });

  private viewer: Viewer;
  private terrainTileListenerRemove: Event.RemoveCallback;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.geometry) {
      this.rawGeometrySubject.next(this.geometry);
    }
    if (changes.colorConfig) {
      this.colorConfigSubject.next(this.colorConfig);
    }
  }

  ngOnDestroy(): void {
    this.drawSub?.unsubscribe();
    this.viewer?.dataSources.remove(this.dataSource);
    this.terrainTileListenerRemove?.();
  }

  private convertVolFGtoVol(volFG: IUvrVolumeSubmissionFG): EntityVolume4d {
    return new EntityVolume4d({
      min_altitude: volFG.altitudeRange.getMinAlt() || null,
      max_altitude: volFG.altitudeRange.getMaxAlt() || null,
      geography: volFG.geography || null,
      circle: volFG.circle || null
    });
  }

  private cleanupGeometry(geometry: IUvrGeoSubmissionFG): Observable<IUvrGeoSubmissionFG> {
    if (geometry.volumes.length) {
      return forkJoin(geometry.volumes.map(vol => this.cleanUpVolume4d(vol))).pipe(map(volumes => ({
        volumes,
        points: geometry.points
      })));
    } else {
      return of({
        volumes: geometry.volumes,
        points: geometry.points
      });
    }
  }

  private cleanUpVolume4d(volume: IUvrVolumeSubmissionFG): Observable<IUvrVolumeSubmissionFG> {
    let coordinates: LatLng;
    if (volume.circle) {
      coordinates = new LatLng(volume.circle.latitude, volume.circle.longitude);
    } else if (volume.geography?.coordinates?.length) {
      coordinates =  new LatLng(volume.geography.coordinates[0][0][1], volume.geography.coordinates[0][0][0]);
    }

    return this.altitudeUtilService.convertToWGS84Meters(volume.altitudeRange.getMinAlt(), coordinates).pipe(map(minAlt => {
      const maxOffset = volume.altitudeRange.max_altitude - volume.altitudeRange.min_altitude;
      const convertFromUnits = this.altitudeUtilService.parseUnitForConversion(volume.altitudeRange.altitude_units);
      const maxOffsetMeters = convertFromUnits === 'm' ? maxOffset : new Converter(maxOffset).from(convertFromUnits).to('m');

      return {
        geography: volume.geography,
        circle: volume.circle,
        altitudeRange: new AltitudeRange({
          min_altitude: minAlt.altitude_value,
          max_altitude: minAlt.altitude_value + maxOffsetMeters,
          altitude_units: units_of_measure.M,
          altitude_vertical_reference: vertical_reference.W84
        }),
        timeRange: volume.timeRange,
      };
    }));
  }
}
