/// <reference types="@types/resize-observer-browser" />
import {Component, inject, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {UvrExt} from '../../../model/utm/UvrExt';
import {ConstraintService} from '../../../services/constraint.service';
import {SelectOption} from '../../../select-option';
import {
  Altitude,
  cause,
  enumValidator,
  GeoCircle,
  permitted_uas,
  units_of_measure,
  UvrVolumeSubmission,
  vertical_reference
} from '../../../model/gen/utm';
import {ConstraintSubmission} from '../../../model/ConstraintSubmission';
import {Subscription, timer} from 'rxjs';
import {ConstraintTypeService} from '../../../services/constraint-type.service';
import {FeatureUtil, Position} from '@ax/ax-angular-map-common';
import * as L from 'leaflet';
import {LatLng} from 'leaflet';
import {DateTime} from 'luxon';
import {UserSettings, UserSettingsService} from '../../../services/user-settings.service';
import {IUvrVolumeSubmissionFG} from '../constraint-geometry-editor/constraint-geometry-editor.component';

import {FormControlify} from '../../../utils/forms';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MeasurementSystemType} from '../../../model/MeasurementSystem';
import {LatLngPoint} from '../../../model/WaypointParser';
import {TimeRange} from '../../../model/TimeRange';
import {forbiddenPatternRegexes, getPrettyDuration, invalidCharactersValidator} from '../../../utils/Validators';
import {exhaustMap} from 'rxjs/operators';
import {IOperatorIntentEntry, OperatorIntentStatus} from '../../../model/OperatorIntentEntry';
import {OperatorIntentService} from '../../../services/operator-intent.service';
import {HttpErrorResponse} from '@angular/common/http';
import {isNil} from "lodash";
import {ResponsiveScreenService} from '../../../services/responsive-screen.service';
import {AltitudeRange} from "../../../model/gen/utm/altitude-range-model";

interface EditConstraintFormValues {
  /* eslint-disable @typescript-eslint/naming-convention */
  message_id: string;
  type: string;
  cause: cause;
  geometry: IUvrGeoSubmissionFG;
  actual_time_end: DateTime;
  reason: string;
  constraint_type: permitted_uas;
  altitude_vertical_reference: vertical_reference;
  altitude_units: units_of_measure;
  /* eslint-enable @typescript-eslint/naming-convention */
}
interface ConstraintSubmissionStatusDetails {
  modalHeading: string;
  summary: string;
  percent: number;
  success: boolean;
  intent: IOperatorIntentEntry;
}

export interface IUvrGeoSubmissionFG {
  volumes: IUvrVolumeSubmissionFG[];
  points?: LatLngPoint[];
}

@Component({
  selector: 'app-edit-constraint',
  templateUrl: './edit-constraint.component.html',
  styleUrls: ['./edit-constraint.component.scss']
})
export class EditConstraintComponent implements OnChanges, OnDestroy {
  @Input() constraint: UvrExt;

  formGroup: FormGroup<FormControlify<EditConstraintFormValues>>;
  availableConstraintTypes: { name: string; value: string }[] = [];
  updatingConstraint: boolean;
  displaySubmissionProgressModal = false;
  mapHeight = 300;
  causes: SelectOption[];
  loading = false;
  constraintSubmissionStatusDetails: ConstraintSubmissionStatusDetails;
  loadingPermittedConstraintTypes: boolean;
  userSettings: UserSettings;
  prettyStartTime: string;

  verticalReferenceIter: SelectOption[] = (() => {
    const ret = [];
    for (const option of Object.keys(vertical_reference)) {
      ret.push({label: option, value: vertical_reference[option]});
    }
    return ret;
  })();
  unitsOfMeasureIter: SelectOption[] = (() => {
    const ret = [];
    for (const option of Object.keys(units_of_measure)) {
      if (option === "M" || option === "FT") {
        ret.push({label: option, value: units_of_measure[option]});
      }
    }
    return ret;
  })();

  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;

  private clearForm = false;
  private intentSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(private constraintService: ConstraintService,
              private userSettingsService: UserSettingsService,
              private constraintTypeService: ConstraintTypeService,
              private operatorIntentService: OperatorIntentService) {
    this.formGroup = new FormGroup<FormControlify<EditConstraintFormValues>>({
      message_id: new FormControl<string>(null, []),
      type: new FormControl<string>('STATIC_ADVISORY', [Validators.required]),
      cause: new FormControl<cause>(cause.OTHER, [Validators.required]),
      geometry: new FormControl<IUvrGeoSubmissionFG>(null, [Validators.required]),
      // uvrVolumeEditorValidator(), uvrVolumeContinuityValidator(true)]),
      actual_time_end: new FormControl<DateTime>(null, []),
      reason: new FormControl<string>(null, [Validators.required, Validators.maxLength(1000),
        invalidCharactersValidator(forbiddenPatternRegexes.alphanumericWithSpecialCharsSpaces)]),
      constraint_type: new FormControl<permitted_uas>(permitted_uas.RESTRICTED, [Validators.required]),
      altitude_vertical_reference: new FormControl<vertical_reference>(vertical_reference.W84, [
        Validators.required, enumValidator(vertical_reference)]),
      altitude_units: new FormControl<units_of_measure>(units_of_measure.FT, [Validators.required,
        enumValidator(units_of_measure)])
    });

    // Get user settings
    this.subscriptions.push(this.userSettingsService.getRawSettings().subscribe((rawSettings) => {
      this.userSettings = rawSettings;
      // Get clear form after submission setting
      this.clearForm = rawSettings.clearFormAfterSubmission.constraintSubmission;

      if (!this.constraint) {
        // Get measurement system setting
        switch (rawSettings.measurementSystemType) {
          case MeasurementSystemType.METRIC:
            this.formGroup.controls.altitude_units.setValue(units_of_measure.M);
            break;
          case MeasurementSystemType.IMPERIAL:
            this.formGroup.controls.altitude_units.setValue(units_of_measure.FT);
            break;
        }

        // Get vertical reference setting
        this.formGroup.controls.altitude_vertical_reference.setValue(rawSettings.defaultVerticalReference);
      }
    }));

    this.fetchPermittedConstraintTypes();
  }

  static extractPolygonFromLayer(polyLayer: L.Polygon): Position[] {
    return (polyLayer.getLatLngs()[0] as LatLng[]).map(EditConstraintComponent.extractRawGeoPointFromLatLng);
  }

  private static extractRawGeoPointFromLatLng(ll: LatLng): Position {
    return FeatureUtil.initRawGeoPoint(ll.lng, ll.lat);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.constraint && changes.constraint.currentValue) {
      this.setValues(changes.constraint.currentValue as UvrExt);
    }
  }

  ngOnDestroy(): void {
    this.intentSub?.unsubscribe();
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  submitConstraint() {
    const rawValues = this.formGroup.getRawValue();

    const constraint = new ConstraintSubmission({
      /* eslint-disable @typescript-eslint/naming-convention */
      type: rawValues.type,
      permitted_uas: [permitted_uas.NOT_SET],
      cause: rawValues.cause,
      altitude_vertical_reference: rawValues.altitude_vertical_reference,
      altitude_units_of_measure: rawValues.altitude_units,
      volumes: rawValues.geometry.volumes.map(vol => new UvrVolumeSubmission({
        effective_time_begin: vol.timeRange.start,
        effective_time_end: vol.timeRange.end,
        min_altitude: vol.altitudeRange.min_altitude,
        max_altitude: vol.altitudeRange.max_altitude,
        geography: vol.geography,
        circle: vol.circle
      })),
      reason: rawValues.reason,
      constraint_type: permitted_uas[rawValues.constraint_type]
      /* eslint-enable @typescript-eslint/naming-convention */
    });

    this.constraintSubmissionStatusDetails = {
      modalHeading: 'Submitting Constraint...',
      summary: 'Submitting Constraint...',
      percent: 0,
      success: undefined,
      intent: undefined
    };
    this.displaySubmissionProgressModal = true;
    this.subscriptions.push(this.constraintService.submitConstraint(constraint).subscribe(res => {
      const getEarliestVolStart = (vols: UvrVolumeSubmission[]): DateTime | null => {
        const now = DateTime.now();
        let earliestTime = vols[0].effective_time_begin || now;
        vols.forEach(vol => {
          const volumeTime = vol.effective_time_begin || now;
          if (volumeTime < earliestTime) {
            earliestTime = volumeTime;
          }
        });
        return earliestTime === now ? null : earliestTime;
      };
      const submissionStartTime = getEarliestVolStart(constraint.volumes);
      this.prettyStartTime = submissionStartTime ? getPrettyDuration(submissionStartTime.diffNow()) : "ASAP";
      this.watchIntentId(res.intentId);
      if (this.clearForm) {
        this.resetForm();
      } else {
        this.setPristineUntouchedState();
      }
    }, (error: HttpErrorResponse) => {
      this.constraintSubmissionStatusDetails = {
        modalHeading: 'Submission Error',
        summary: error?.error?.message || 'Unknown Error Submitting Constraint',
        percent: 100,
        success: false,
        intent: undefined
      };
    }));
  }

  updateConstraint() {
    this.updatingConstraint = true;
    this.subscriptions.push(this.constraintService.updateConstraint(this.constraint.message_id, this.constraint).subscribe(() => {
      this.updatingConstraint = false;
    }));
  }

  updateAltRef($event: vertical_reference) {
    this.formGroup.controls.altitude_vertical_reference.setValue($event);
  }

  updateAltUnits($event: units_of_measure) {
    this.formGroup.controls.altitude_units.setValue($event);
  }

  private fetchPermittedConstraintTypes($event?: string) {
    this.loadingPermittedConstraintTypes = true;
    this.subscriptions.push(this.constraintTypeService.fetchConstraintTypes($event).subscribe(types => {
      const knownNames = new Set<string>();
      const constraintTypes = new Set<{ name: string; value: string }>();
      if ($event) {
        constraintTypes.add({name: $event, value: $event});
        knownNames.add($event);
      }
      for (const t of types) {
        if (knownNames.has(t.name) || ($event && !t.name.includes($event))) {
          continue;
        }
        knownNames.add(t.name);
        constraintTypes.add(t);
      }
      this.availableConstraintTypes = [...constraintTypes];
      this.loadingPermittedConstraintTypes = false;
    }));
  }

  private setValues(values: UvrExt) {
    const isAltitudeComplete = (alt: Altitude): boolean => ((!!alt.altitude_value || alt.altitude_value === 0) &&
      !!alt.units_of_measure && !!alt.vertical_reference);

    const geometry: IUvrGeoSubmissionFG = {
      volumes: values.volumes.map(vol => {
        const useSubmittedRadius = !isNil(!!vol.submitted_radius?.radius) && !!vol.submitted_radius?.units_of_measure;
        return {
          circle: vol.circle ? new GeoCircle({
            latitude: vol.circle.latitude,
            longitude: vol.circle.longitude,
            radius: useSubmittedRadius ? vol.submitted_radius.radius : vol.circle.radius,
            units: useSubmittedRadius ? vol.submitted_radius.units_of_measure : vol.circle.units
          }) : null,
          geography: !vol.circle ? vol.geography : null,
          altitudeRange: (isAltitudeComplete(vol.submitted_min_altitude) && isAltitudeComplete(vol.submitted_max_altitude)) ?
            new AltitudeRange({
              min_altitude: vol.submitted_min_altitude.altitude_value,
              max_altitude: vol.submitted_max_altitude.altitude_value,
              altitude_vertical_reference: vol.submitted_min_altitude.vertical_reference,
              altitude_units: vol.submitted_min_altitude.units_of_measure
            }) :
            new AltitudeRange({
              min_altitude: vol.min_altitude.altitude_value,
              max_altitude: vol.max_altitude.altitude_value,
              altitude_vertical_reference: vol.min_altitude.vertical_reference,
              altitude_units: vol.min_altitude.units_of_measure
            }),
          timeRange: new TimeRange(vol.effective_time_begin, vol.effective_time_end),
        } as IUvrVolumeSubmissionFG;
      }),
      points: []
    };
    this.formGroup.controls.message_id.setValue(values.message_id);
    this.formGroup.controls.reason.setValue(values.reason);
    this.formGroup.controls.constraint_type.setValue(permitted_uas[values.additional_data.constraint_type]);
    this.formGroup.controls.altitude_vertical_reference.setValue(geometry.volumes[0].altitudeRange?.altitude_vertical_reference);
    this.formGroup.controls.altitude_units.setValue(geometry.volumes[0].altitudeRange?.altitude_units);
    this.formGroup.controls.geometry.setValue(geometry);
    this.formGroup.controls.type.setValue(values.type.toString());
    this.formGroup.controls.cause.setValue(values.cause);
    this.formGroup.controls.actual_time_end.setValue(values.actual_time_end);

    this.markFormAsTouched(this.formGroup);
  }

  private setPristineUntouchedState(): void {
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
  }

  private setConstraintSubmissionStatus(intent: IOperatorIntentEntry) {
    switch (intent.intentStatus) {
      case OperatorIntentStatus.RECEIVED:
        this.constraintSubmissionStatusDetails = {
          modalHeading: 'Submitting Constraint...',
          summary: 'Constraint Received',
          percent: 33,
          success: undefined,
          intent
        };
        break;
      case OperatorIntentStatus.PROCESSING:
        this.constraintSubmissionStatusDetails = {
          modalHeading: 'Submitting Constraint...',
          summary: 'Processing Constraint...',
          percent: 66,
          success: undefined,
          intent
        };
        break;
      case OperatorIntentStatus.FINISHED:
        this.intentSub?.unsubscribe();
        if (intent.intentResult.success) {
          this.constraintSubmissionStatusDetails = {
            modalHeading: 'Constraint Submitted',
            summary: `Constraint accepted and will begin ${this.prettyStartTime === "ASAP" ? "ASAP" : "in " + this.prettyStartTime}`,
            percent: 100,
            success: true,
            intent
          };
        } else {
          this.constraintSubmissionStatusDetails = {
            modalHeading: 'Constraint Submission Failed',
            summary: 'Constraint Submission Failed',
            percent: 100,
            success: false,
            intent
          };
        }
        break;
      default:
        this.constraintSubmissionStatusDetails = {
          modalHeading: 'Constraint Submission Status Unknown',
          summary: 'Unknown',
          percent: 0,
          success: undefined,
          intent: undefined
        };
    }
  }

  private markFormAsTouched(formGroup: FormGroup): void {
    formGroup.updateValueAndValidity();
    formGroup.markAllAsTouched();
    for (const controlKey of Object.keys(formGroup.controls)) {
      if (formGroup.controls[controlKey].markAsTouched) {
        formGroup.controls[controlKey].markAsTouched();
        formGroup.controls[controlKey].updateValueAndValidity();
      }
    }
  }

  private watchIntentId(intentId: string) {
    this.intentSub?.unsubscribe();

    this.intentSub = timer(100, 1000).pipe(exhaustMap(() => this.operatorIntentService.getOperatorIntent(intentId))).subscribe(res => {
      if (res.intentStatus !== this.constraintSubmissionStatusDetails?.intent?.intentStatus) {
        this.setConstraintSubmissionStatus(res);
      }
    });
  }

  private resetForm() {
    this.formGroup.reset({
      /* eslint-disable @typescript-eslint/naming-convention */
      message_id: null,
      type: 'STATIC_ADVISORY',
      cause: cause.OTHER,
      geometry: null,
      actual_time_end: null,
      reason: null,
      constraint_type: permitted_uas.RESTRICTED,
      altitude_vertical_reference: this.userSettings?.defaultVerticalReference || vertical_reference.W84,
      altitude_units: this.userSettings?.measurementSystemType === MeasurementSystemType.METRIC ? units_of_measure.M : units_of_measure.FT
      /* eslint-disable @typescript-eslint/naming-convention */
    });
    this.setPristineUntouchedState();
  }

  // private extractPolygon(polygon: GeomanLayer): Polygon {
  //   return Parser.parsePolygon({
  //     type: 'Polygon',
  //     coordinates: [EditConstraintComponent.extractPolygonFromLayer(polygon as unknown as L.Polygon)]
  //   });
  // }
}
