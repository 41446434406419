import {Component, computed, effect, inject, input, OnDestroy} from '@angular/core';
import {CzmlDataSource} from '@cesium/engine';
import {Viewer} from '@cesium/widgets';
import {concat, of} from 'rxjs';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {AirspaceService} from '../../services/airspace.service';
import {takeUntilDestroyed, toObservable, toSignal} from '@angular/core/rxjs-interop';
import {catchError, filter, map, retry, scan, switchMap} from 'rxjs/operators';
import {toObservable as toObservableFromEvent} from '../../utils/cesium-utils';
import {getBoundingBox} from "../utilities";
import {CZMLPacket} from '@ax-uss-ui/common';


export enum LaancCZMLEntityType {
  FACILITY_MAP = 'FACILITY_MAP',
  CLASS_AIRSPACE = 'CLASS_AIRSPACE',
  SPECIAL_USE_AIRSPACE = 'SPECIAL_USE_AIRSPACE',
  AIRPORTS = 'AIRPORTS',
  STADIUMS = 'STADIUMS',
  NSUFRS = 'NSUFRS',
  DC_FRZ = 'DC_FRZ',
  TFRS = 'TFRS'
}

@Component({
  selector: 'app-laanc-drawer',
  template: ''
})
export class LaancDrawerComponent implements OnDestroy {

  type = input.required<LaancCZMLEntityType>()

  private drawerService = inject(CesiumService);

  private datasource = (() => {
    const ds = new CzmlDataSource();
    ds.process({
      id: "document",
      name: "UAS Facility Map",
      version: "1.0"
    }).then(() => {
    });
    return ds;
  })();

  private airspaceService = inject(AirspaceService);

  private viewer$ = toSignal(this.drawerService.watchViewerInit());

  private boxBounds$ = toSignal(
    toObservable(this.viewer$).pipe(
      filter((viewer: Viewer) => !!viewer),
      switchMap((viewer: Viewer) => concat(
        of(viewer.scene.camera),
        toObservableFromEvent(viewer.scene.camera.moveEnd)
      ).pipe(map(() => {
        const camera = viewer.scene.camera;
        if (!camera) {
          return null;
        }
        return getBoundingBox(camera);
      })))),
    {initialValue: null}
  );

  private czmlEntitiesObs$ = computed(() => {
    const type = this.type();
    const bounds = this.boxBounds$();
    if (!bounds || !type) {
      return of([] as CZMLPacket[]);
    }
    switch (type) {
      case LaancCZMLEntityType.FACILITY_MAP:
        return this.airspaceService.getUasFacilityMap(bounds);
      case LaancCZMLEntityType.CLASS_AIRSPACE:
        return this.airspaceService.getClassAirspace(bounds);
      case LaancCZMLEntityType.SPECIAL_USE_AIRSPACE:
        return this.airspaceService.getSpecialUseAirspace(bounds);
      case LaancCZMLEntityType.AIRPORTS:
        return this.airspaceService.getAirports(bounds);
      case LaancCZMLEntityType.STADIUMS:
        return this.airspaceService.getStadiums(bounds);
      case LaancCZMLEntityType.NSUFRS:
        return this.airspaceService.getNsufrs(bounds);
      case LaancCZMLEntityType.DC_FRZ:
        return this.airspaceService.getWashingtonDcFrz(bounds);
      case LaancCZMLEntityType.TFRS:
        return this.airspaceService.getTfrs(bounds);
      default:
        return of([] as CZMLPacket[]);
    }
  });

  private czmlSub = toObservable(this.czmlEntitiesObs$).pipe(
    switchMap((obs) => obs),
    retry({
      delay: 2000,
      resetOnSuccess: true,
    }),
    catchError((err) => {
      console.error(err);
      return [];
    }),
    scan((acc, curr) => {
        const toProcess: CZMLPacket[] = [];
        for (const packet of curr) {
          if (!packet.id) {
            console.debug('packet without id', packet);
            continue;
          }
          if (!acc.processedIDs.has(packet.id)){
            toProcess.push(packet);
            acc.processedIDs.add(packet.id);
          }
        }
        return {
          processedIDs: acc.processedIDs,
          toProcess
        };
      }
      , {processedIDs: new Set<string>(), toProcess: [] as CZMLPacket[]})
  ).pipe(takeUntilDestroyed()).subscribe(acc => {
    const entities = acc.toProcess;
    if (!entities) {
      return;
    }
    this.datasource.process(entities).then(() => {});
  });

  constructor() {
    effect(() => {
      const viewer = this.viewer$();
      if (!viewer) {
        return;
      }
      viewer.infoBox.frame.removeAttribute('sandbox');
      viewer.infoBox.frame.src = 'about:blank';
      viewer.dataSources.add(this.datasource).then(() => {
      });
    });
  }

  ngOnDestroy(): void {
    const viewer = this.viewer$();
    viewer?.dataSources?.remove(this.datasource);
  }

}
