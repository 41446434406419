import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OperationVolumeDrawerComponent} from './operation-volume-drawer/operation-volume-drawer.component';
import {OperationGeometryDrawerComponent} from './operation-geometry-drawer/operation-geometry-drawer.component';
import {OperationSitrepDrawerComponent} from './operation-sitrep-drawer/operation-sitrep-drawer.component';
import {ConstraintSitrepDrawerComponent} from './constraint-sitrep-drawer/constraint-sitrep-drawer.component';
import {EntityVolume4dDrawerComponent} from './entity-volume4d-drawer/entity-volume4d-drawer.component';
import {ConstraintGeometryDrawerComponent} from './constraint-geometry-drawer/constraint-geometry-drawer.component';
import {LaancDrawerComponent} from './laanc-drawer/laanc-drawer.component';


@NgModule({
  declarations: [
    OperationVolumeDrawerComponent,
    EntityVolume4dDrawerComponent,
    OperationGeometryDrawerComponent,
    OperationSitrepDrawerComponent,
    ConstraintSitrepDrawerComponent,
    ConstraintGeometryDrawerComponent,
    LaancDrawerComponent
  ],
  imports: [
    CommonModule
  ],
    exports: [
      OperationVolumeDrawerComponent,
      EntityVolume4dDrawerComponent,
      OperationGeometryDrawerComponent,
      OperationSitrepDrawerComponent,
      ConstraintSitrepDrawerComponent,
      ConstraintGeometryDrawerComponent,
      LaancDrawerComponent
    ]
})
export class CesiumModule { }
