import {Observable} from 'rxjs';
import {OperationGeometry} from '../../../../../model/operation.geometry';
import {map, shareReplay, startWith} from 'rxjs/operators';
import {Type} from '@angular/core';
import {UTMFlightPlanCustomizer} from './common';
import {PiccoloFlightPlan} from './formats/PiccoloFlightPlan';
import {BufferStepCustomizerComponent} from './BufferStepCustomizer';


export class PiccoloFlightPlanCustomizer extends UTMFlightPlanCustomizer {
  status$: Observable<boolean>;
  constructor(private flightPlan: PiccoloFlightPlan) {
    super();
    this.status$ = this.flightPlan.options.statusChanges.pipe(
      map(() => this.flightPlan.options.valid),
      startWith(this.flightPlan.options.valid),
      shareReplay(1)
    );
  }




  static fromText(text: string): PiccoloFlightPlanCustomizer {
    return new PiccoloFlightPlanCustomizer(PiccoloFlightPlan.fromText(text));
  }

  getConfigurationComponent(): { component: Type<any>; inputs: any } {
    return {
      component: BufferStepCustomizerComponent as Type<any>,
      inputs: {
        formGroup: this.flightPlan.options
      }
    };
  }

  toOperationGeometry(): Observable<OperationGeometry> {
    return this.flightPlan.toOperationGeometry();
  }


}
