import {Component, inject, output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CesiumService} from "@ax/ax-angular-map-cesium";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {Viewer} from "@cesium/engine";

@Component({
  selector: 'lib-expose-viewer',
  standalone: true,
  imports: [CommonModule],
  template: ''
})
export class ExposeViewerComponent {
  private cesiumService = inject(CesiumService);
  viewer = output<Viewer>()
  private emitSub = this.cesiumService.watchViewerInit()
    .pipe(takeUntilDestroyed())
    .subscribe(viewer => {
      this.viewer.emit(viewer);
    })

}
