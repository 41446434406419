<form [formGroup]="formGroup">
  <h3>Part 107 Information</h3>
  <clr-checkbox-container>
    <label>Part 107 Certified?</label>
    <clr-checkbox-wrapper>
      <input type="checkbox" formControlName="hasPart107" clrCheckbox/>
    </clr-checkbox-wrapper>
  </clr-checkbox-container>

  <h3>Contact Information</h3>
  <clr-input-container>
    <label>Prefix</label>
    <input autocomplete="off" clrInput type="text" formControlName="prefixName"/>
    <clr-control-helper>{{formGroup.controls.prefixName?.value?.length || 0}}/60</clr-control-helper>
    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.prefixName.errors.invalidCharacters.characters}}</clr-control-error>
    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.prefixName.value.length || 0}}
      /{{formGroup.controls.prefixName.errors.maxlength.requiredLength}})</clr-control-error>
  </clr-input-container>

  <clr-input-container>
    <label class="required-custom">First Name</label>
    <input autocomplete="off" clrInput type="text" formControlName="firstName"/>
    <clr-control-helper>{{formGroup.controls.firstName?.value?.length || 0}}/60</clr-control-helper>
    <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.firstName.errors.invalidCharacters.characters}}</clr-control-error>
    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.firstName.value.length || 0}}
      /{{formGroup.controls.firstName.errors.maxlength.requiredLength}})</clr-control-error>
  </clr-input-container>

  <clr-input-container>
    <label>Middle Name</label>
    <input autocomplete="off" clrInput type="text" formControlName="middleName"/>
    <clr-control-helper>{{formGroup.controls.middleName?.value?.length || 0}}/60</clr-control-helper>
    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.middleName.errors.invalidCharacters.characters}}</clr-control-error>
    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.middleName.value.length || 0}}
      /{{formGroup.controls.middleName.errors.maxlength.requiredLength}})</clr-control-error>
  </clr-input-container>

  <clr-input-container>
    <label class="required-custom">Last Name</label>
    <input autocomplete="off" clrInput type="text" formControlName="lastName"/>
    <clr-control-helper>{{formGroup.controls.lastName?.value?.length || 0}}/60</clr-control-helper>
    <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.lastName.errors.invalidCharacters.characters}}</clr-control-error>
    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.lastName.value.length || 0}}
      /{{formGroup.controls.lastName.errors.maxlength.requiredLength}})</clr-control-error>
  </clr-input-container>

  <clr-input-container>
    <label>Suffix</label>
    <input autocomplete="off" clrInput type="text" formControlName="suffixName"/>
    <clr-control-helper>{{formGroup.controls.suffixName?.value?.length || 0}}/60</clr-control-helper>
    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.suffixName.errors.invalidCharacters.characters}}</clr-control-error>
    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.suffixName.value.length || 0}}
      /{{formGroup.controls.suffixName.errors.maxlength.requiredLength}})</clr-control-error>
  </clr-input-container>

  <clr-input-container>
    <label class="required-custom">Title</label>
    <input autocomplete="off" clrInput type="text" formControlName="title"/>
    <clr-control-helper>{{formGroup.controls.title?.value?.length || 0}}/128</clr-control-helper>
    <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.title.errors.invalidCharacters.characters}}</clr-control-error>
    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.title.value.length || 0}}
      /{{formGroup.controls.title.errors.maxlength.requiredLength}})</clr-control-error>
  </clr-input-container>

  <clr-input-container>
    <label>Division/Unit</label>
    <input autocomplete="off" clrInput type="text" formControlName="division"/>
    <clr-control-helper>{{formGroup.controls.division?.value?.length || 0}}/128</clr-control-helper>
    <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.division.errors.invalidCharacters.characters}}</clr-control-error>
    <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formGroup.controls.division.value.length || 0}}
      /{{formGroup.controls.division.errors.maxlength.requiredLength}})</clr-control-error>
  </clr-input-container>

  <div class="clr-row">
    <div class="clr-col-md-9">
      <app-string-array-input formControlName="phones"
                              [inputName]="'Phone Number'"
                              [inputType]="inputTypeEnum.PHONE"
                              [required]="laancEnabled$()">
      </app-string-array-input>
      <error-container [control]="formGroup.controls.phones">
        <error-message [has]="'required'">At least one phone number must be provided</error-message>
        <error-message [has]="'maxlength'">Maximum phone numbers allowed is {{formGroup.controls.phones.errors?.maxlength?.requiredLength}}
        </error-message>
      </error-container>

      <app-string-array-input formControlName="emails" [inputName]="(altEmails ? 'Alternative ' : '') + 'Email'"
                              [inputType]="inputTypeEnum.EMAIL"></app-string-array-input>
      <error-container [control]="formGroup.controls.emails">
        <error-message [has]="'maxlength'">Maximum emails allowed is {{formGroup.controls.emails.errors?.maxlength?.requiredLength}}
        </error-message>
      </error-container>
    </div>
  </div>
</form>
